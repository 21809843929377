import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'app-loader',
	template: `
		<ng-container *ngIf="!loading; else not_loaded">
			<div @fadeInOut>
				<ng-content></ng-content>
			</div>
		</ng-container>
		<ng-template #not_loaded>
			<div class="app-loader loading-theme_blue-gray spinner ds-flex-center-center">
				<mat-spinner [diameter]="50" [color]="'primary'"></mat-spinner>
			</div>
		</ng-template>
	`,
	styles: [
		`
			.app-loader {
				margin: 24px;
			}
		`,
	],
	animations: [
		trigger('fadeInOut', [
			state(
				'void',
				style({
					opacity: 0,
				})
			),
			transition('void <=> *', animate(150)), // 150ms for fadeIn and fadeOut
		]),
	],
})
export class LoaderComponent {
	@Input()
	loading: boolean;
}
