import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from './http-service';

export interface IDCard {
	userName?: string;
	schoolName?: string;
	userRole?: string;
	profilePicture?: string;
	idNumberData?: { idNumber: string; barcodeURL: any };
	greadLevel?: string;
	backgroundColor?: string;
	logoURL?: string;
	backsideText?: string;
	showCustomID?: boolean;
	barcodeType?: string;
	stickers?;
}

export interface BarcodeTypes {
	label: string;
	action: string;
	icon: string;
	textColor: string;
	backgroundColor: string;
}

export interface IDCardResponse {
	results: {
		digital_id_card: IDCardDetails;
	};
}

export interface IDCardDetails {
	backside_text: string;
	barcode_type: 'qr-code' | 'code39';
	color: string; // hex color
	datetime_created: string; // ISO Date String with Timezone "2022-07-26T06:23:52.35771Z"
	enabled: boolean;
	id: number;
	logo_bucket: string;
	logo_file_name: string;
	logo_object_name: string;
	school_id: number;
	show_custom_ids: boolean;
	show_grade_levels: boolean;
	signed_url: string;
	visible_to_who: string;
	stickers?;
}

export const BARCODE_TYPES: BarcodeTypes[] = [
	{
		label: 'Traditional',
		icon: './assets/Barcode (Black).svg',
		textColor: '#7f879d',
		backgroundColor: '#F4F4F4',
		action: 'code39',
	},
	{
		label: 'QR Code',
		icon: './assets/QR Code (Black).svg',
		textColor: '#7f879d',
		backgroundColor: '#F4F4F4',
		action: 'qr-code',
	},
];

@Injectable({
	providedIn: 'root',
})
export class IDCardService {
	stickerroomNameBlur$: Subject<any> = new Subject();

	constructor(private http: HttpService) {}

	// Function to check if a string is a valid color
	isValidColor(color: string): boolean {
		// Regular expression to match hex color code (#RRGGBB or #RGB)
		const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

		// Regular expression to match rgb/rgba color code
		const rgbColorRegex = /^(rgb(a)?\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*(,\s*\d+\s*)?\))$/;

		// Regular expression to match color names (e.g., "red", "blue")
		const namedColorRegex = /^(red|blue|green|yellow|purple|orange|pink|brown|black|white)$/i;

		// Check if the color matches any of the valid formats
		return hexColorRegex.test(color) || rgbColorRegex.test(color) || namedColorRegex.test(color);
	}

	addIDCard(body) {
		return this.http.post('v1/id_card', body);
	}

	enableIDCard() {
		return this.http.patch('v1/id_card/enable');
	}

	disableIDCard() {
		return this.http.patch('v1/id_card/disable');
	}

	getIDCardDetails() {
		return this.http.get('v1/id_card');
	}

	getStudentIDCardDetails(studentID) {
		return this.http.get(`v1/id_card/student/${studentID}`);
	}

	getIDCardDetailsEdit() {
		return this.http.get<IDCardResponse>('v1/id_card/edit');
	}

	updateIDCardField(body) {
		return this.http.patch('v1/id_card', body);
	}

	postStickers(body) {
		return this.http.post('v2/stickers', body, undefined, false);
	}

	patchStickers(body) {
		return this.http.patch('v2/stickers', body);
	}

	deleteSticker(body) {
		return this.http.deleteV2(`v2/stickers`, body);
	}
}
