import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogData, SettingAction } from '../settings/settings.component';

@Injectable({
	providedIn: 'root',
})
export class SideNavService {
	sideNavData$: Subject<DialogData> = new Subject<DialogData>();

	sideNavAction$: Subject<SettingAction | undefined> = new Subject<SettingAction | undefined>();

	sideNavType$: Subject<string> = new Subject<string>();

	toggle$: Subject<boolean> = new Subject<boolean>();

	toggleLeft$: Subject<boolean> = new Subject<boolean>();

	toggleRight$: Subject<boolean> = new Subject<boolean>();

	fadeClick$: Subject<boolean> = new Subject<boolean>();

	openSettingsEvent$: Subject<boolean> = new Subject<boolean>();

	get sideNavData() {
		return this.sideNavData$.asObservable();
	}

	get sideNavAction() {
		return this.sideNavAction$.asObservable();
	}

	get toggle() {
		return this.toggle$.asObservable();
	}

	get toggleLeft() {
		return this.toggleLeft$.asObservable();
	}

	get toggleRight() {
		return this.toggleRight$.asObservable();
	}

	get sideNavType() {
		return this.sideNavType$.asObservable();
	}

	get fadeClick() {
		return this.fadeClick$.asObservable();
	}
}
