import { Component, OnInit, Input, EventEmitter, SimpleChanges } from '@angular/core';
import { ClassDetailsModalData } from '../../services/classes.service';
import { Output } from '@angular/core';
import { School } from '../../models/School';
import { HttpService } from '../../services/http-service';
import { SPClassUser } from '../../services/classes.service';

export type EditType = 'room_name' | 'date_time' | 'teachers' | 'class_name';
@Component({
	selector: 'sp-about-class',
	templateUrl: './about-class.component.html',
	styleUrls: ['./about-class.component.scss'],
})
export class AboutClassComponent implements OnInit {
	@Input() data: ClassDetailsModalData;
	@Input() userCanEdit = false;
	@Output() editType: EventEmitter<EditType> = new EventEmitter<EditType>();

	school: School;
	classIcon: string;
	periodName: string;
	term: string;
	teachers: SPClassUser[];

	constructor(private http: HttpService) {}
	ngOnInit(): void {
		if (!this.data) {
			return;
		}
		this.school = this.http.getSchool();
		this.classIcon = '';
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && changes.data.currentValue) {
			const c = changes.data.currentValue.classDetails;
			this.periodName = c.period_name;
			this.teachers = c.class_users?.teachers;
		}
	}
}
