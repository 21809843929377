import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { bumpIn } from '../../../../animations';
import { EncounterPreventionService, ExclusionGroupWithOverrides } from 'app/services/encounter-prevention.service';
import { FeatureFlagService, FLAGS } from 'app/services/feature-flag.service';

@Component({
	selector: 'app-encounter-group',
	templateUrl: './encounter-group.component.html',
	styleUrls: ['./encounter-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [bumpIn],
})
export class EncounterGroupComponent implements OnInit {
	@Input() group: ExclusionGroupWithOverrides;
	@Input() backgroundColor: string;
	@Input() infoCard = false;
	@Input() studentProfileView = false;

	@Output() clickEvent = new EventEmitter<ExclusionGroupWithOverrides>();

	numOverrides: number;

	constructor(private featureFlagService: FeatureFlagService, private ePService: EncounterPreventionService) {}

	ngOnInit(): void {
		if (this.featureFlagService.isFeatureEnabledV2(FLAGS.EncounterPreventionOverride)) {
			this.numOverrides = this.ePService.countNumOverrides(this.group);
		}
	}
}
