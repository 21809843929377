<div class="tw-flex tw-flex-col tw-box-border tw-flex-grow" #tabbedContentModal>
	<div #header class="tw-box-border tw-flex tw-pb-5 tw-bg-white tw-pt-5 tw-px-5">
		<div class="tw-flex-col tw-mr-5" *ngIf="data.icon"><img [src]="data.icon | resolveAsset" /></div>
		<div class="tw-flex tw-justify-between tw-flex-grow tw-items-center">
			<div class="tw-flex-col tw-flex tw-text-navy-500">
				<div class="tw-font-bold tw-text-xl" style="margin-bottom: 6px" *ngIf="data.title">
					{{ data.title }}
				</div>
				<div class="tw-font-medium" *ngIf="data.subtitle">{{ data.subtitle }}</div>
			</div>
			<div
				class="close-button tw-flex tw-items-center tw-text-center tw-self-start tw-h-11 tw-w-11 tw-rounded-full tw-bg-white tw-cursor-pointer hover:tw-bg-gray-050 tw-transition tw-duration-200"
				*ngIf="data.displayCloseIcon"
				(click)="dialogRef.close()">
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
					<path
						d="M4.17052 3.33361C4.00474 3.33366 3.84274 3.38314 3.70524 3.47573C3.56773 3.56833 3.46096 3.69983 3.39858 3.85342C3.3362 4.00702 3.32104 4.17572 3.35504 4.33798C3.38905 4.50023 3.47067 4.64866 3.58947 4.76428L8.83361 10.0084L3.58947 15.2526C3.50949 15.3293 3.44564 15.4213 3.40165 15.5231C3.35766 15.6249 3.33442 15.7344 3.3333 15.8453C3.33217 15.9561 3.35317 16.0661 3.39508 16.1688C3.43699 16.2714 3.49896 16.3647 3.57735 16.4431C3.65575 16.5215 3.74901 16.5834 3.85165 16.6253C3.9543 16.6672 4.06428 16.6882 4.17515 16.6871C4.28601 16.686 4.39554 16.6628 4.49732 16.6188C4.59909 16.5748 4.69106 16.5109 4.76785 16.4309L10.012 11.1868L15.2561 16.4309C15.3329 16.5109 15.4249 16.5748 15.5267 16.6188C15.6284 16.6628 15.738 16.686 15.8488 16.6871C15.9597 16.6882 16.0697 16.6672 16.1723 16.6253C16.275 16.5834 16.3682 16.5215 16.4466 16.4431C16.525 16.3647 16.587 16.2714 16.6289 16.1688C16.6708 16.0661 16.6918 15.9561 16.6907 15.8453C16.6896 15.7344 16.6663 15.6249 16.6223 15.5231C16.5784 15.4213 16.5145 15.3293 16.4345 15.2526L11.1904 10.0084L16.4345 4.76428C16.5549 4.64723 16.6372 4.49653 16.6705 4.33193C16.7038 4.16733 16.6866 3.99651 16.6211 3.84185C16.5557 3.6872 16.445 3.55592 16.3037 3.46523C16.1624 3.37454 15.9969 3.32866 15.8291 3.33361C15.6126 3.34007 15.4071 3.43055 15.2561 3.58589L10.012 8.83003L4.76785 3.58589C4.69019 3.50606 4.59732 3.44261 4.49473 3.39928C4.39213 3.35595 4.28189 3.33362 4.17052 3.33361Z"
						fill="#7083A0" />
				</svg>
			</div>
		</div>
	</div>

	<div class="tw-flex tw-flex-grow tw-box-border" [ngStyle]="{ height: contentHeight }">
		<mat-tab-group
			class="mat-tab-group tabbed-content-tabs"
			id="tabbed-content-modal-tab-group"
			disableRipple
			[@.disabled]="true"
			(selectedIndexChange)="onSelectTab($event)"
			[selectedIndex]="selectedTabIndex">
			<mat-tab *ngFor="let tab of data.tabs">
				<ng-template mat-tab-label>
					<div class="tab-label" [innerHTML]="tab.title"></div>
				</ng-template>
				<div
					#content
					class="tw-overflow-y-auto tw-box-border"
					[ngClass]="{ 'tw-p-7 tw-pb-11': tab.tabStyle === 'cards', 'tw-w-full tw-h-full tw-bg-white': tab.tabStyle === 'full-width' }">
					<ng-template *ngIf="tab.tabContentTemplate" [ngTemplateOutlet]="tab.tabContentTemplate"></ng-template>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
