import { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'sp-rename',
	templateUrl: './rename.component.html',
	styleUrls: ['./rename.component.scss'],
})
export class RenameComponent implements OnInit {
	@Input() renameForm: FormGroup;
	@Input() name = '';
	@Input() label = 'Class Name';
	@Input() description = 'Use the name of the course. For example, simply “Algebra 2.”';
	@Input() placeholder = 'e.g. Algebra 2';
	@Input() errorMessage = 'Please enter a name for this class.';

	ngOnInit(): void {
		this.renameForm.addControl(
			'name',
			new FormControl(this.name ? this.name : '', [Validators.required, Validators.pattern(/^\s*\S.*\S\s*$|^\s*\S\s*$/)])
		);
	}
}
