import { BaseModel } from './base';

/**
 * BannerType represents the type of banner that has to be displayed **only** for admins.
 * These properties exist on the school object fetched from the server and are set on Hubspot
 */
export enum BannerType {
	Trial = 'free_trial',
	EarlyAccess = 'early_access',
	SubscriptionExpired = 'subscription_expired',
	PaymentOverdue = 'payment_overdue',
	Suspended = 'suspended', // this isn't set on Hubspot, but the front-end locally sets this when the school is suspended
}

export type MonetizationInfo = {
	activeAccount: boolean;
	suspendedAccount: boolean;
	monetization_pipeline: 'free_trial' | 'early_access' | 'customer' | 'payment_overdue' | 'subscription_expired';
	recent_subscription_plan: string;
	account_suspension_date: string;
	cta_link: string;
	integration_type: 'none' | 'google' | 'classlink' | 'clever';
	account_executive_hubspot_id: string;
	customer_success_advocate_hubspot_id: string;
	billing_coordinator_hubspot_id: string;
	using_profile_pictures: boolean;
	subscription_end_date: string;
	customer_since_date: string;
	confirm_renewal_link: string;
	trial_start_date: string;
	// renewal_status: 'expiring' | 'renewed_upgrade_available' | 'renewed';
	daysRemaining: number;
	daysElapsed: number;
	duration: number;
	progressBar: number;
	suspendProgressBar: number;
	suspensionDaysRemaining: number;
	formattedSubscriptionEndDate: string;
	freeTrialEarlyAccessProgressBar: number;
	customerSubscriptionFirstWarning: number;
	customerSubscriptionSecondWarning: number;
};

export class School extends BaseModel {
	constructor(
		public display_card_room: boolean,
		public student_can_use_mobile: boolean,
		public id: number,
		public name: string,
		public my_roles: string[],
		public pass_buffer_time: number,
		public pass_cooldown: number,
		public earliest_pass_time: string,
		public gsuite_hosted_domain: string,
		public latest_pass_time: string,
		public created: string,
		public display_username: boolean,
		public show_active_passes_number: boolean,
		public profile_pictures_completed: boolean,
		public profile_pictures_enabled: boolean,
		public feature_flag_digital_id: boolean,
		public feature_flag_phone: boolean,
		public feature_flag_encounter_detection: boolean,
		public feature_flag_parent_accounts: boolean,
		public feature_flag_wait_in_line: boolean,
		public feature_flag_show_wait_in_line: boolean,
		public feature_flag_new_abbreviation: boolean,
		public feature_flag_referral_program: boolean,
		public feature_flags_v2: [string],
		public trial_quote_link: string,
		public feature_flag_alert_sounds: boolean,
		public trial_end_date: Date,
		public timezone: string,
		public customer_since: Date | null,
		public max_hall_pass_enabled: boolean,
		public no_fly_time_enabled: boolean,
		public teachers_can_add_classes: boolean,
		public teachers_can_edit_classes: boolean,
		public max_hall_pass?: number,
		public hall_pass_access?: string,
		public attendance_access?: string,
		public flex_access?: string,
		public id_card_access?: string,
		public dc_access?: string,
		public customer_success_advocate?: string,
		public onboarding_plan_link?: string,
		public suspended?: boolean,
		public account_suspension_date?: Date,
		public banner_type?: BannerType,
		public actively_using_date?: Date
	) {
		super();
	}

	static fromJSON(JSON): School {
		if (!JSON) {
			return null;
		}

		const id: number = JSON['id'],
			display_card_room: boolean = JSON['display_card_room'],
			student_can_use_mobile: boolean = JSON['student_can_use_mobile'],
			name: string = '' + JSON['name'],
			my_roles: string[] = JSON['my_roles'],
			pass_buffer_time: number = JSON['pass_buffer_time'],
			pass_cooldown: number = JSON['pass_cooldown'],
			earliest_pass_time: string = JSON['earliest_pass_time'],
			gsuite_hosted_domain: string = JSON['gsuite_hosted_domain'],
			latest_pass_time: string = JSON['latest_pass_time'],
			created: string = JSON['created'],
			display_username: boolean = JSON['display_username'],
			show_active_passes_number: boolean = JSON['show_active_passes_number'],
			profile_pictures_completed = !!JSON['profile_pictures_completed'],
			profile_pictures_enabled = !!JSON['profile_pictures_enabled'],
			feature_flag_digital_id = !!JSON['feature_flag_digital_id'],
			feature_flag_phone = !!JSON['feature_flag_phone'],
			feature_flag_encounter_detection = !!JSON['feature_flag_encounter_detection'],
			feature_flag_parent_accounts = !!JSON['feature_flag_parent_accounts'],
			feature_flag_wait_in_line = !!JSON['feature_flag_wait_in_line'],
			feature_flag_show_wait_in_line = !!JSON['feature_flag_show_wait_in_line'],
			feature_flag_new_abbreviation = !!JSON['feature_flag_new_abbreviation'],
			feature_flag_referral_program = !!JSON['feature_flag_referral_program'],
			feature_flags_v2: [string] = JSON['feature_flags_v2'],
			max_hall_pass_enabled: boolean = JSON['max_hall_pass_enabled'],
			no_fly_time_enabled: boolean = JSON['no_fly_time_enabled'],
			teachers_can_add_classes: boolean = JSON['teachers_can_add_classes'],
			teachers_can_edit_classes: boolean = JSON['teachers_can_edit_classes'],
			max_hall_pass: number = JSON['max_hall_pass'],
			hall_pass_access: string = JSON['hall_pass_access'],
			attendance_access: string = JSON['attendance_access'],
			flex_access: string = JSON['flex_access'],
			dc_access: string = JSON['dc_access'],
			customer_since: Date = JSON['customer_since'] ? new Date(JSON['customer_since']) : null,
			timezone: string = JSON['timezone'],
			trial_quote_link: string = JSON['trial_quote_link'],
			feature_flag_alert_sounds = !!JSON['feature_flag_alert_sounds'],
			id_card_access = JSON['id_card_access'],
			customer_success_advocate = JSON['customer_success_advocate'],
			onboarding_plan_link: string = JSON['onboarding_plan_link'],
			suspended = !!JSON['suspended'],
			account_suspension_date: Date = JSON['account_suspension_date'],
			banner_type: BannerType = JSON['banner_type'],
			actively_using_date: Date = JSON['actively_using_date'] ? new Date(JSON['actively_using_date']) : null;
		let trial_end_date: Date;
		if (JSON['trial_end_date']) {
			trial_end_date = new Date(JSON['trial_end_date']);
		}

		return new School(
			display_card_room,
			student_can_use_mobile,
			id,
			name,
			my_roles,
			pass_buffer_time,
			pass_cooldown,
			earliest_pass_time,
			gsuite_hosted_domain,
			latest_pass_time,
			created,
			display_username,
			show_active_passes_number,
			profile_pictures_completed,
			profile_pictures_enabled,
			feature_flag_digital_id,
			feature_flag_phone,
			feature_flag_encounter_detection,
			feature_flag_parent_accounts,
			feature_flag_wait_in_line,
			feature_flag_show_wait_in_line,
			feature_flag_new_abbreviation,
			feature_flag_referral_program,
			feature_flags_v2,
			trial_quote_link,
			feature_flag_alert_sounds,
			trial_end_date,
			timezone,
			customer_since,
			max_hall_pass_enabled,
			no_fly_time_enabled,
			teachers_can_add_classes,
			teachers_can_edit_classes,
			max_hall_pass,
			hall_pass_access,
			attendance_access,
			flex_access,
			id_card_access,
			dc_access,
			customer_success_advocate,
			onboarding_plan_link,
			suspended,
			account_suspension_date,
			banner_type,
			actively_using_date
		);
	}

	idCardView() {
		this.feature_flag_digital_id === true && this.hall_pass_access === 'no_access';
	}
}
