import { Component, Inject, OnInit } from '@angular/core';
import { FlexPeriod } from '../../../../flex-period.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstanceTimes } from '../create-activity.component';

interface SchedulePeriod {
	start: Date;
	end: Date;
	selected: boolean;
}

@Component({
	selector: 'sp-select-days',
	templateUrl: './select-days.component.html',
	styleUrls: ['./select-days.component.scss'],
})
export class SelectDaysComponent implements OnInit {
	closeDialog: (state: 'flex_recurring' | 'scheduled', starts: InstanceTimes[]) => void;
	options: SchedulePeriod[] = [];
	private maxSelect = 6;
	private flexEnabled = false;

	constructor(@Inject(MAT_DIALOG_DATA) public data: { flexPeriod: FlexPeriod }) {}

	ngOnInit(): void {
		this.buildOptions();
	}

	buildOptions(): void {
		if (!this.data.flexPeriod) {
			return;
		}
		const scheduleArray: SchedulePeriod[] = [];
		const startDay = new Date();
		const nextFourWeeks = new Date(startDay.getTime() + 4 * 7 * 24 * 60 * 60 * 1000); // Add four weeks

		// Iterate from tomorrow to next four weeks
		for (let date = startDay; date <= nextFourWeeks; date = this.incrementDate(date)) {
			const dayOfWeek = date.getDay();

			// Find matching schedules for the day of the week
			const matchingSchedules = this.data.flexPeriod.schedules.filter((schedule) => {
				return schedule.days_of_week.includes(dayOfWeek);
			});

			// Generate start and end times for each matching schedule
			matchingSchedules.forEach((schedule) => {
				const startTime = new Date(date);
				startTime.setHours(schedule.start_hour, schedule.start_minute, 0, 0);
				const endTime = new Date(date);
				endTime.setHours(schedule.end_hour, schedule.end_minute, 0, 0);

				// Add the start and end times to the scheduleArray
				scheduleArray.push({ start: startTime, end: endTime, selected: false });
			});
		}

		// Remove today's entry if it's in the past
		if (scheduleArray.length > 0 && scheduleArray[0].start < startDay) {
			scheduleArray.shift();
		}

		this.options = scheduleArray;
	}

	tomorrow(): Date {
		const today = new Date();
		const tomorrow = new Date(today);
		tomorrow.setDate(tomorrow.getDate() + 1);
		return tomorrow;
	}

	incrementDate(date: Date): Date {
		const nextDate = new Date(date);
		nextDate.setDate(nextDate.getDate() + 1);
		return nextDate;
	}

	toggle(start: Date): void {
		if (this.flexEnabled) {
			this.onEnabledToggle(false);
			return;
		}

		const option = this.options.find((schedule) => schedule.start === start);
		const numSelected = this.options.reduce((accumulator, obj) => {
			if (obj.selected === true) {
				return accumulator + 1;
			}
			return accumulator;
		}, 0);

		if (option && (numSelected < this.maxSelect || option.selected)) {
			option.selected = !option.selected;
		}
	}

	handleCancel(): void {
		this.closeDialog('flex_recurring', []);
	}

	handleSubmit(): void {
		const times: InstanceTimes[] = this.options
			.filter((option) => option.selected)
			.map((option) => {
				return { start: option.start, end: option.end };
			});
		if (times.length === 0) {
			this.handleCancel();
		} else {
			this.closeDialog('scheduled', times);
		}
	}

	onEnabledToggle(recurring: boolean): void {
		this.flexEnabled = recurring;
		this.options.forEach((option) => {
			option.selected = recurring;
		});
	}
}
