<label *ngIf="label && !hideLabel" class="tw-text-base tw-text-navy-500 tw-font-semibold tw-mb-3 tw-block input-label">{{ label }}</label>
<ng-container *ngIf="iconDisplay">
	<div
		class="input-container tw-w-full tw-flex tw-items-center tw-gap-2"
		[ngClass]="{ active: isFocused || handleSelection, error: !group.get(control).valid && showError }"
		[style.opacity]="this.group.disabled ? 0.5 : 1"
		(click)="!this.group.disabled && setFocus()"
		[style.height]="height">
		<div *ngIf="icon" class="icon tw-rounded-full tw-flex tw-items-center tw-justify-center" [ngStyle]="{ 'background-color': iconBGColor }">
			<img src="{{ icon }}" alt="icon" />
		</div>
		<div class="tw-flex-grow" [formGroup]="group" *ngIf="group">
			<input
				#formInput
				class="dynamic-input"
				[formControlName]="control"
				[id]="control"
				type="text"
				[autocomplete]="autoComplete ? 'on' : 'off'"
				[placeholder]="placeholder ? placeholder : ''"
				[maxlength]="maxLength"
				[formGroup]="group"
				[disabled]="disabled"
				(focus)="onFocus(true)"
				(blur)="onBlur()"
				(keydown)="keydown.emit()" />
		</div>
	</div>
</ng-container>

<ng-container *ngIf="!iconDisplay">
	<div
		class="input-container tw-w-full no-icon"
		[ngClass]="{ active: isFocused || handleSelection, error: !group.get(control).valid && showError }"
		[style.opacity]="this.group.disabled ? 0.5 : 1"
		(click)="!this.group.disabled && setFocus()"
		[style.height]="height ? height + 'px' : 'auto'"
		[formGroup]="group"
		*ngIf="group">
		<ng-container>
			<div class="selection-container tw-w-full">
				<div class="selected tw-flex tw-flex-wrap">
					<ng-content></ng-content>
					<input
						#formInput
						class="dynamic-input"
						[formControlName]="control"
						[id]="control"
						type="text"
						[autocomplete]="autoComplete ? 'on' : 'off'"
						[placeholder]="placeholderDisplay ? placeholderDisplay : ''"
						[maxlength]="maxLength"
						[formGroup]="group"
						[disabled]="disabled"
						(focus)="onFocus(true)"
						(blur)="onBlur()"
						(keydown)="keydown.emit()" />
				</div>
			</div>
		</ng-container>
	</div>
</ng-container>

<ng-template #showDescription>
	<div *ngIf="description" class="tw-text-gray-500 tw-font-normal tw-text-sm tw-mt-4">{{ description }}</div>
</ng-template>

<div
	*ngIf="errorMessage && !group?.get(control).valid && showError; else showDescription"
	class="tw-text-red-500 tw-font-normal tw-text-sm tw-mt-4 tw-flex tw-items-center tw-gap-1">
	<img src="assets/Alert (Red).svg" alt="Error indicator" />{{ errorMessage }}
</div>
