import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Term } from '../models/Schedule';
import * as moment from 'moment';
import { DYNAMIC_DIALOG_OPTIONS, DynamicDialogAction, DynamicDialogData } from '../dynamic-dialog-modal/dynamic-dialog-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DynamicDialogService } from '../dynamic-dialog.service';
import { DialogFactoryService } from '../dialog-factory.service';
import { Subject } from 'rxjs';
import { ScheduleService } from '../services/schedule.service';
import { CalendarService, DisabledDates, TooltipDateCreationParams } from '../services/calendar.service';
import * as crypto from 'crypto-js';

@Component({
	selector: 'sp-additional-terms',
	templateUrl: './additional-terms.component.html',
	styleUrls: ['./additional-terms.component.scss'],
})
export class AdditionalTermsComponent implements OnChanges, OnDestroy {
	@ViewChild('addSubTermModal', { read: TemplateRef, static: false }) addSubTermModal: TemplateRef<any>;
	@ViewChild('editSubTermModal', { read: TemplateRef, static: false }) editSubTermModal: TemplateRef<any>;
	@ViewChild('deleteSubTermModal', { read: TemplateRef, static: false }) deleteSubTermModal: TemplateRef<any>;

	@Input() scheduleSetupFlow = false;
	@Input() schoolYearStartStr: string;
	@Input() schoolYearEndStr: string;
	@Input() schoolYearName: string;
	@Input() subTerms: Term[] = [];
	@Input() mainTermId: number;
	@Input() disableAddingOrEditing = false;
	@Output() emitSelectedSubterms: EventEmitter<Term[]> = new EventEmitter();
	@Output() emitDeletedSubterms: EventEmitter<Term> = new EventEmitter();

	schoolYearStartDate: moment.Moment | null;
	schoolYearEndDate: moment.Moment | null;
	addSubTermDialogService: DynamicDialogService;
	deleteSubTermDialogService: DynamicDialogService;
	subTermSelectedForDeletion: string | null = null;
	subTermForm: FormGroup;
	subTermForms: FormGroup[] = [];
	selectedSubTermFormIndex: number;
	subTermsToEmit: Term[] = [];
	subTermsToDelete: Term[] = [];
	disableAddTermButton = false;
	private tooltipDateCreationParams: TooltipDateCreationParams;
	private disabledDates: DisabledDates[] = [];

	private destroy$: Subject<void> = new Subject();
	constructor(
		private dialogFactoryService: DialogFactoryService,
		private fb: FormBuilder,
		public scheduleService: ScheduleService,
		private calendarService: CalendarService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.schoolYearStartStr?.currentValue) {
			this.schoolYearStartDate = moment.utc(this.schoolYearStartStr);
		}
		if (changes.schoolYearEndStr?.currentValue) {
			this.schoolYearEndDate = moment.utc(this.schoolYearEndStr);
			this.createTooltipDates();
		}
		if (this.schoolYearStartDate && this.schoolYearEndDate) {
			this.createTooltipDates();
		}
		if (changes.subTerms?.currentValue) {
			this.subTermsToEmit = [...this.subTerms].sort((a, b) => moment(a.start_date, 'YYYY-MM-DD').diff(moment(b.start_date, 'YYYY-MM-DD')));
			this.populateSubtermForms();
			if (this.subTermForms.length) {
				this.populateDisabledDates();
				this.createTooltipDates();
			}
		}
		this.disableAddTermButton = this.shouldDisableAddTermButton();
	}

	private populateSubtermForms(): void {
		this.subTermForms = this.subTermsToEmit.map((subterm) => {
			return this.fb.group({
				exclusion_id: this.generateUniqueId(),
				id: subterm.id,
				term_name: subterm.term_name,
				start_date: moment.utc(subterm.start_date, 'YYYY-MM-DD'),
				end_date: moment.utc(subterm.end_date, 'YYYY-MM-DD'),
			});
		});
	}

	private populateDisabledDates(): void {
		this.disabledDates = [];
		this.subTermForms.forEach((termForm) => {
			this.disabledDates.push({
				start_date: termForm.get('start_date').value,
				end_date: termForm.get('end_date').value,
				exclusionId: termForm.get('exclusion_id').value,
				term_name: termForm.get('term_name').value,
			});
		});
	}

	private shouldDisableAddTermButton(): boolean {
		const currentValue = this.calendarService.tooltipDates$.getValue();
		if (currentValue.length) {
			return currentValue.every((info) => info.disabled);
		}
		return false;
	}

	private getStartAndEndDatesForTermForm(): { start_date: moment.Moment; end_date: moment.Moment } | null {
		// Logic is get the earliest date in the tooltips
		const currentValue = this.calendarService.tooltipDates$.getValue();
		const earliestDate = currentValue.find((info) => !info.disabled);
		if (earliestDate) {
			return { start_date: moment.utc(earliestDate.date, 'YYYY-MM-DD'), end_date: moment.utc(earliestDate.date, 'YYYY-MM-DD') };
		}
		return;
	}

	openAddSubTermModal(): void {
		this.selectedSubTermFormIndex = null;
		const startAndEndDates = this.getStartAndEndDatesForTermForm();
		this.subTermForm = this.fb.group({
			exclusion_id: this.generateUniqueId(),
			term_name: ['', Validators.required],
			start_date: [startAndEndDates.start_date, Validators.required],
			end_date: [startAndEndDates.end_date, Validators.required],
		});
		this.subTermForms.push(this.subTermForm);
		const subTermFormDestroy$ = new Subject();

		const data = this.getSubTermModalData('Add Additional Term', this.addSubTermModal, true);
		this.addSubTermDialogService = this.dialogFactoryService.open(data, { ...DYNAMIC_DIALOG_OPTIONS, disableClose: true });
		this.subTermForm.valueChanges.pipe(takeUntil(subTermFormDestroy$)).subscribe((changes) => {
			if (this.subTermForm.valid) {
				this.addSubTermDialogService.setDialogConfig({ disablePrimaryButton: false });
			} else {
				this.addSubTermDialogService.setDialogConfig({ disablePrimaryButton: true });
			}
		});
		this.addSubTermDialogService.closed$.subscribe({
			next: (selectedOption: DynamicDialogAction) => {
				if (selectedOption === 'primary') {
					if (this.subTermForm.valid) {
						const term_name = this.subTermForm.value.term_name;
						const start_date = moment.utc(this.subTermForm.value.start_date).format('YYYY-MM-DD');
						const end_date = moment.utc(this.subTermForm.value.end_date).format('YYYY-MM-DD');
						this.subTermsToEmit.push({ term_name, start_date, end_date });
						this.handleUpdatingSubTerms();
					}
				} else {
					this.populateSubtermForms();
					subTermFormDestroy$.next();
					this.subTermForm = null;
				}
			},
		});
	}

	private handleUpdatingSubTerms(): void {
		// after saving term, need to update the subterm forms with the new term id
		this.subTermsToEmit.sort((a, b) => moment(a.start_date, 'YYYY-MM-DD').diff(moment(b.start_date, 'YYYY-MM-DD')));
		this.populateSubtermForms();
		this.emitSelectedSubterms.emit(this.subTermsToEmit);
		this.populateDisabledDates();
		this.createTooltipDates();
	}
	openEditSubTermModal(index: number, id?: number): void {
		this.populateSubtermForms(); // need to assign an old values of form in case if they clicked on cancel after making changes to form
		if (this.subTermForms.length) {
			this.populateDisabledDates();
			this.createTooltipDates();
		}
		let subTerm = this.subTermsToEmit[index];
		this.selectedSubTermFormIndex = index;
		if (id) {
			subTerm = this.subTermsToEmit.find((term) => term.id === id);
			this.subTermForm = this.subTermForms.find((form) => form.value.id === subTerm.id);
		} else {
			this.subTermForm = this.subTermForms[index];
		}
		const data = this.getSubTermModalData(`Edit ${subTerm.term_name}`, this.editSubTermModal, !this.scheduleSetupFlow);

		this.addSubTermDialogService = this.dialogFactoryService.open(data, { ...DYNAMIC_DIALOG_OPTIONS, disableClose: true });

		this.subTermForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((changes) => {
			if (this.subTermForm.valid) {
				this.addSubTermDialogService.setDialogConfig({ disablePrimaryButton: false });
			} else {
				this.addSubTermDialogService.setDialogConfig({ disablePrimaryButton: true });
			}
		});
		this.addSubTermDialogService.closed$.subscribe({
			next: (selectedOption: DynamicDialogAction) => {
				if (selectedOption === 'primary') {
					if (this.subTermForm.valid) {
						const id = this.subTermForm.value.id;
						const term_name = this.subTermForm.value.term_name;
						const start_date = moment.utc(this.subTermForm.value.start_date).format('YYYY-MM-DD');
						const end_date = moment.utc(this.subTermForm.value.end_date).format('YYYY-MM-DD');
						this.subTermsToEmit[this.selectedSubTermFormIndex] = {
							id,
							term_name,
							start_date,
							end_date,
							hasClassesAssigned: subTerm.hasClassesAssigned,
						};
						this.handleUpdatingSubTerms();
					}
				} else {
					this.subTermForm = null;
				}
			},
		});
	}

	openDeleteSubTermModal(index: number): void {
		const subTerm = this.subTermsToEmit[index];
		this.subTermForm = this.subTermForms.find((form) => form.value.id === subTerm.id);
		this.subTermSelectedForDeletion = subTerm.term_name;
		const data = this.getSubTermModalData('Delete this term?', this.deleteSubTermModal, false, true);
		this.deleteSubTermDialogService = this.dialogFactoryService.open(data, {
			...DYNAMIC_DIALOG_OPTIONS,
			panelClass: 'dynamic-dialog-modal-min',
			disableClose: true,
		});
		this.deleteSubTermDialogService.closed$.subscribe({
			next: (selectedOption: DynamicDialogAction) => {
				if (selectedOption === 'primary') {
					this.removeSubTerm(index);
				}
				this.subTermSelectedForDeletion = null;
				this.disableAddTermButton = this.shouldDisableAddTermButton();
			},
		});
	}

	private generateUniqueId(): string {
		const randomBytes = crypto.lib.WordArray.random(16);
		const hash = crypto.SHA256(randomBytes);
		return hash.toString(crypto.enc.Hex);
	}

	private removeSubTerm(index: number): void {
		if (this.subTermsToEmit[index].id) {
			this.subTermsToDelete.push(this.subTermsToEmit[index]);
			this.emitDeletedSubterms.emit(this.subTermsToEmit[index]);
		}
		this.subTermsToEmit.splice(index, 1).sort((a, b) => moment(a.start_date, 'YYYY-MM-DD').diff(moment(b.start_date, 'YYYY-MM-DD')));
		this.populateSubtermForms();
		this.emitSelectedSubterms.emit(this.subTermsToEmit);
		this.subTermForm = null;
		this.populateDisabledDates();
		this.createTooltipDates();
	}

	private createTooltipDates(): void {
		this.tooltipDateCreationParams = {
			firstDate: this.schoolYearStartDate.format('YYYY-MM-DD'),
			lastDate: this.schoolYearEndDate.format('YYYY-MM-DD'),
			disabledDates: this.disabledDates,
		};
		this.calendarService.createToolTipDates(this.tooltipDateCreationParams);
		this.disableAddTermButton = this.shouldDisableAddTermButton();
	}

	private getSubTermModalData(headerText: string, modal: TemplateRef<any>, disablePrimaryButton: boolean, isDelete = false): DynamicDialogData {
		return {
			headerText: headerText,
			showCloseIcon: true,
			primaryButtonGradientBackground: isDelete ? '#E32C66,#E32C66' : '#00B476,#00B476',
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			primaryButtonLabel: isDelete ? 'Delete' : 'Save',
			secondaryButtonLabel: 'Cancel',
			modalBody: modal,
			disablePrimaryButton: disablePrimaryButton,
		};
	}

	openAdditionalTermsLearnMoreLink(): void {
		window.open('https://articles.smartpass.app/en/articles/9150643-getting-started-with-schedules#h_786cce6952', '_blank');
	}
	openAddTermLearnMoreLink(): void {
		window.open('https://articles.smartpass.app/en/articles/9150643-getting-started-with-schedules#h_786cce6952', '_blank');
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
