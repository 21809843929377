<div class="toast-container">
	<div class="backdrop" *ngIf="data.hasBackdrop"></div>
	<div
		class="content-wrapper"
		#wrapper
		[@toastSlideInOut]="toggleToast ? 'open' : 'close'"
		[style.top]="!indexPosition ? '15px' : wrapper.clientHeight * indexPosition + 15 + indexPosition * 15 + 'px'"
		(mouseover)="over()"
		(mouseleave)="leave()">
		<div class="content">
			<div class="type-line" [style.background-color]="lineColor"></div>
			<div class="info" style="display: flex; align-items: center" *ngIf="data.encounterPrevention">
				<div class="exclusion-pass" *ngIf="!user?.isStudent() && !isKioskMode">
					<app-pass-tile
						[forStaff]="true"
						[pass]="data.exclusionPass"
						[profileImage]="false"
						[encounterPreventionToast]="true"
						[clickable]="false"></app-pass-tile>
				</div>
				<div class="text" style="margin-left: 20px">
					<div class="text_header">{{ data.title }}</div>
					<div class="text_message tw-flex tw-flex-col tw-gap-y-2.5">
						<div>{{ data.subtitle }}</div>
						<div
							class="tw-underline"
							*ngIf="!user?.isStudent() && !isKioskMode"
							customToolTip
							[contentTemplate]="tooltip"
							[toolTipShowDelay]="25"
							[toolTipHideDelay]="800">
							View Group Details
						</div>
						<ng-template #tooltip>
							<app-encounter-prevention-tooltip
								[user]="user"
								[groups]="data.exclusionGroups"
								[conflictPasses]="data.conflictPasses"
								[conflictStudentIds]="data.conflictStudentIds"></app-encounter-prevention-tooltip>
						</ng-template>
					</div>
				</div>
			</div>

			<div class="info" *ngIf="!data.encounterPrevention">
				<div class="text">
					<div class="text_header">{{ data.title }}</div>
					<div class="text_message" *ngIf="data.subtitle" [innerHTML]="data.subtitle"></div>
					<div class="text_message" *ngIf="data.templateRef">
						<ng-container [ngTemplateOutlet]="data.templateRef"></ng-container>
					</div>
				</div>
				<div class="button">
					<app-gradient-button
						*ngIf="data.showButton"
						[gradient]="'#F4F4F4, #F4F4F4'"
						[leftIcon]="data.icon ? data.icon : null"
						[textColor]="'#1F195E'"
						(buttonClick)="download(data.action)">
						{{ data.buttonText ? data.buttonText : 'Download' }}
					</app-gradient-button>
				</div>
			</div>

			<div class="close-btn" *ngIf="!data.hideCloseButton" (click)="close($event)">
				<img [src]="'./assets/Close.svg' | resolveAsset" alt="Cancel (Blue-Gray).svg" />
			</div>
		</div>
	</div>
</div>
