import {
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	Input,
	TemplateRef,
	ViewChild,
	Pipe,
	PipeTransform,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { ClassesService, GetExternalInfoResp, SPClassWithUsers } from '../../services/classes.service';
import { BellSchedule, Schedule, ScheduleGroupList } from '../../models/Schedule';
import { ScheduleService } from '../../services/schedule.service';
import { User } from '../../models/User';
import { ClassEditRoomFormComponent } from '../class-edit-room-form/class-edit-room-form.component';

@Pipe({
	name: 'andOthers',
	pure: true,
})
export class AndOthersPipe implements PipeTransform {
	deepGet(obj, keys) {
		return keys.reduce((xs, x) => xs?.[x] ?? null, obj);
	}

	deepGetByPath(obj, path) {
		return this.deepGet(
			obj,
			path
				.replace(/\[([^[\]]*)\]/g, '.$1.')
				.split('.')
				.filter((t) => t !== '')
		);
	}

	transform(list: any[], keyPath: string, expandedItems: number): string {
		list = list.map((i) => this.deepGetByPath(i, keyPath)).filter((i) => i);
		let remainingItems = list.length - expandedItems;
		if (remainingItems < 0) {
			remainingItems = 0;
		}

		if (remainingItems == 0) {
			let displayString = list.slice(0, list.length - 1).join(', ');

			if (list.length > 1) {
				displayString += ` and ${list[list.length - 1]}`;
			} else if (list.length == 1) {
				displayString += ` ${list[0]}`;
			}

			return displayString;
		}

		let displayString = list.slice(0, expandedItems).join(', ');

		let suffix: string;
		if (remainingItems <= 3) {
			suffix = 'other';
		} else {
			suffix = 'others';
		}
		displayString += ` and ${remainingItems} ${suffix}.`;
		return displayString;
	}
}

export type AcceptClass = {
	roomId: number;
	class: SPClassWithUsers;
};

export type DeclineClassEvent = {
	classId: number;
};

@Component({
	selector: 'sp-class-setup-card',
	templateUrl: './class-setup-card.component.html',
	styleUrls: ['./class-setup-card.component.scss'],
})
export class ClassSetupCardComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('acceptClassCardModalBody', {
		read: TemplateRef,
		static: false,
	})
	acceptClassCardModalBody: TemplateRef<any>;

	@Input() classesToAccept: SPClassWithUsers[];
	@Input() user: User;
	@Input() classTimes: BellSchedule[];
	@Input() schedules: Schedule[];
	@Input() listGroups: ScheduleGroupList[] | null;
	@Input() teacherCanEdit = true;
	@Output() acceptClassEvent: EventEmitter<AcceptClass> = new EventEmitter();
	@Output() declineClassEvent: EventEmitter<DeclineClassEvent> = new EventEmitter();

	currentClass: ReplaySubject<SPClassWithUsers> = new ReplaySubject<SPClassWithUsers>();
	disableAcceptButton = true;
	selectedPeriodGroupingIds: number[] = [];
	selectedTermIds: number[] = [];

	// edit room variables
	acceptClassRoomForm = new FormGroup({});
	currentPage = 1;

	currentSyncInfo$: Observable<GetExternalInfoResp> = this.currentClass.pipe(
		switchMap((c) => this.classes.getExternalInfo(c.id)),
		shareReplay()
	);

	// get ref to sp-class-edit-room-form
	@ViewChild(ClassEditRoomFormComponent, { static: false }) editRoomForm: ClassEditRoomFormComponent;

	private destroy$: Subject<any> = new Subject<any>();

	constructor(public scheduleService: ScheduleService, private classes: ClassesService) {}

	ngOnInit(): void {
		this.acceptClassRoomForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((acceptClassRoomFormValue) => {
			const isRoomIdPresent = !!acceptClassRoomFormValue.roomId;
			const isRoomPresent = !!acceptClassRoomFormValue.room;

			// Combine conditions to check if both conditions are satisfied
			this.disableAcceptButton = !(isRoomIdPresent && isRoomPresent);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.classesToAccept && this.classesToAccept.length > 0) {
			this.currentClass.next(this.classesToAccept[0]);
		}
	}

	previousClass(): void {
		if (this.currentPage > 1) {
			this.currentPage--;
			this.currentClass.next(this.classesToAccept[this.currentPage - 1]);
			this.editRoomForm.clearSelection();
		}
	}

	nextClass(): void {
		if (this.currentPage < this.classesToAccept.length) {
			this.currentPage++;
			this.currentClass.next(this.classesToAccept[this.currentPage - 1]);
			this.editRoomForm.clearSelection();
		}
	}

	declineClass(spClass: SPClassWithUsers): void {
		this.declineClassEvent.emit({
			classId: spClass.id,
		});
		this.editRoomForm.clearSelection();
	}

	acceptClass(spClass: SPClassWithUsers): void {
		if (this.disableAcceptButton) {
			this.editRoomForm.onFocus(true);
			return;
		}
		this.acceptClassEvent.emit({
			roomId: this.acceptClassRoomForm.get('roomId').value,
			class: spClass,
		});
		this.editRoomForm.clearSelection();
	}

	didSelectPeriodGroupingIds(selectedPeriodGroupingIds: number[]): void {
		this.selectedPeriodGroupingIds = selectedPeriodGroupingIds;
		this.updatePrimaryButtonState();
	}

	didSelectTermIds(selectedTermIds: number[]): void {
		this.selectedTermIds = selectedTermIds;
		this.updatePrimaryButtonState();
	}

	updatePrimaryButtonState(): void {
		const disabled = this.selectedPeriodGroupingIds?.length == 0 || this.selectedTermIds?.length == 0;
		const d = { disablePrimaryButton: disabled };
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
