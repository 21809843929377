import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-textarea',
	templateUrl: './app-textarea.component.html',
	styleUrls: ['./app-textarea.component.scss'],
})
export class AppTextareaComponent implements OnInit {
	@Input() width = '200px';
	@Input() height = '70px';
	@Input() placeholder = '';
	@Input() focused: boolean;
	@Input() control: FormControl;
	@Input() label = '';
	@Input() maxLength = 10000;

	@ViewChild('textArea') textArea: ElementRef;

	hovered: boolean;

	constructor() {}

	ngOnInit(): void {
		if (this.focused) {
			this.textArea.nativeElement.focus();
		}
	}
}
