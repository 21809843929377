import { Injectable } from '@angular/core';
import { HttpService } from './http-service';
import { EncounterOverride, ExclusionGroup, PreventEncounters } from '../models/ExclusionGroup';
import { forkJoin, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../ngrx/app-state/app-state';
import {
	createExclusionGroup,
	getExclusionGroups,
	removeExclusionGroup,
	updateExclusionGroup,
} from '../ngrx/encounters-prevention/excusion-groups/actions';
import {
	getCurrentExclusionGroup,
	getEncounterPreventionLength,
	getExclusionGroupsCollection,
	getExclusionGroupsLength,
	getExclusionGroupsLoaded,
	getExclusionGroupsLoading,
} from '../ngrx/encounters-prevention/excusion-groups/states/exclusion-groups-getters.state';
import { constructUrl } from '../live-data/helpers';
import { map, tap } from 'rxjs/operators';

export interface EncounterPreventionWithOverride extends PreventEncounters {
	override?: EncounterOverride;
}

export interface ExclusionGroupWithOverrides extends ExclusionGroup {
	prevented_encounters?: EncounterPreventionWithOverride[];
}

export type ExclusionGroupQueryParams = Partial<{
	student: number | number[];
}>;
@Injectable({
	providedIn: 'root',
})
export class EncounterPreventionService {
	exclusionGroups$: Observable<ExclusionGroup[]> = this.store.select(getExclusionGroupsCollection);
	exclusionGroupsLoading$: Observable<boolean> = this.store.select(getExclusionGroupsLoading);
	exclusionGroupsLoaded$: Observable<boolean> = this.store.select(getExclusionGroupsLoaded);
	updatedExclusionGroup$: Observable<ExclusionGroup> = this.store.select(getCurrentExclusionGroup);
	exclusionGroupsLength$: Observable<number> = this.store.select(getExclusionGroupsLength);
	encounterPreventionLength$: Observable<number> = this.store.select(getEncounterPreventionLength);

	constructor(private http: HttpService, private store: Store<AppState>) {}

	getExclusionGroupsRequest(queryParams?: ExclusionGroupQueryParams) {
		this.store.dispatch(getExclusionGroups({ queryParams }));
	}
	getExclusionGroups(queryParams: ExclusionGroupQueryParams): Observable<ExclusionGroup[]> {
		return this.http.get(constructUrl('v1/exclusion_groups', queryParams));
	}

	createExclusionGroupRequest(group) {
		this.store.dispatch(createExclusionGroup({ groupData: group }));
	}

	createExclusionGroup(group): Observable<ExclusionGroup> {
		return this.http.post(`v1/exclusion_groups`, group);
	}

	updateExclusionGroupRequest(group, updateFields) {
		this.store.dispatch(updateExclusionGroup({ group, updateFields }));
		return this.updatedExclusionGroup$;
	}

	updateExclusionGroup(group: ExclusionGroup, updateFields) {
		return this.http.patch(`v1/exclusion_groups/${group.id}`, updateFields);
	}

	deleteExclusionGroupRequest(group: ExclusionGroup) {
		this.store.dispatch(removeExclusionGroup({ group }));
	}

	deleteExclusionGroup(groupId) {
		return this.http.delete(`v1/exclusion_groups/${groupId}`);
	}

	getEncounterPreventionOverrides(groupId?: number): Observable<EncounterOverride[]> {
		return this.http.post('v2/exclusion_groups/list_overrides', { group_id: groupId }, undefined, false);
	}

	getExclusionGroupsWithOverrides(studentId?: number): Observable<ExclusionGroupWithOverrides[]> {
		const params = studentId ? { student: studentId } : {};
		return forkJoin([this.getExclusionGroups(params), this.getEncounterPreventionOverrides()]).pipe(
			map(([groups, overrides]) => {
				return groups.map((group) => {
					return {
						...group,
						prevented_encounters: group.prevented_encounters.map((encounter) => {
							return {
								...encounter,
								override: overrides.find((override) => override.id === encounter?.override_id),
							};
						}),
					};
				});
			})
		);
	}

	countNumOverrides(group: ExclusionGroupWithOverrides): number {
		return group.prevented_encounters.reduce((count, encounter) => count + (encounter?.override_id ? 1 : 0), 0);
	}
}
