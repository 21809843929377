import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { BehaviorSubject, Observable, ReplaySubject, Subject, from, of, throwError } from 'rxjs';
import { catchError, concatAll, delay, exhaustMap, filter, last, map, mapTo, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { School } from '../models/School';
import { AppState } from '../ngrx/app-state/app-state';
import { getSchools } from '../ngrx/schools/actions';
import { getCurrentSchool, getLoadedSchools, getSchoolsCollection, getSchoolsLength } from '../ngrx/schools/states';
import { SignedOutToastComponent } from '../signed-out-toast/signed-out-toast.component';
import { AuthObject, LoginService, SessionLogin, isClassLinkLogin, isCleverLogin, isDemoLogin, isGoogleLogin } from './login.service';
import { StorageService } from './storage.service';
import { Icon } from 'app/admin/icon-picker/icon-picker.component';
// uncomment when app uses formatDate and so on
//import {APP_BASE_HREF, registerLocaleData} from '@angular/common';

declare const window: Window;

export interface Config {
	[key: string]: any;
}

export interface AuthProviderResponse {
	provider: string;
	sourceId: string;
	name: string;
}

export enum AuthType {
	Password = 'password',
	Google = 'google',
	Classlink = 'classlink',
	Clever = 'clever',
	GG4L = 'gg4l',
	Empty = '',
}

export enum LoginProvider {
	Password = 'password',
	Classlink = 'classlink',
	Clever = 'clever',
	Google = 'google-access-token',
}

export interface DiscoverServerResponse {
	auth_types: AuthType[];
	auth_providers: AuthProviderResponse[];
}

export interface ServerAuth {
	access_token: string;
	refresh_token?: string;
	token_type: string;
	expires_in: number;
	expires: Date;
	scope: string;
}

function ensureFields<T, K extends keyof T>(obj: T, keys: K[]) {
	for (const key of keys) {
		// eslint-disable-next-line no-prototype-builtins
		if (!obj.hasOwnProperty(key as string)) {
			throw new Error(`${key} not in ${obj}`);
		}
	}
}

function getSchoolInArray(id: string | number, schools: School[]) {
	for (let i = 0; i < schools.length; i++) {
		if (Number(schools[i].id) === Number(id)) {
			return schools[i];
		}
	}
	return null;
}

function isSchoolInArray(id: string | number, schools: School[]) {
	return getSchoolInArray(id, schools) !== null;
}

type ConfigInput = (Config & { responseType?: 'json' }) | undefined;
function makeConfig(config: ConfigInput, school: School, effectiveUserId): Config & { responseType: 'json' } {
	const headers: Config = {
		// these values are set during the webpack build using simple string matching
		'X-Build-Release-Name': [process.env.BUILD_RELEASE_NAME],
		'X-Build-Date': [process.env.BUILD_DATE],
	};

	if (school) {
		headers['X-School-Id'] = '' + school.id;
	}

	if (effectiveUserId) {
		headers['X-Effective-User-Id'] = '' + effectiveUserId;
	}

	// TODO (qarun): this is buggy if we serve through proxy config,
	if (/(proxy)/.test(environment.buildType)) {
		const auth = JSON.parse(localStorage.getItem('auth'));
		const token = auth.auth.access_token;
		headers['Authorization'] = 'Bearer ' + token;
	}

	if (config !== undefined && 'headers' in config) {
		Object.assign(headers, config.headers);
		delete config.headers;
	}

	return Object.assign({}, config || {}, {
		headers: headers,
		responseType: config?.responseType ?? 'json',
	});
}

export function makeUrl(server: LoginServer, endpoint: string) {
	let url: string;

	if (endpoint?.startsWith('http://') || endpoint?.startsWith('https://')) {
		url = endpoint;
	} else {
		if (/(proxy)/.test(environment.buildType)) {
			const proxyPath = new URL(server.api_root).pathname;
			url = proxyPath + endpoint;
		} else if (/(local)/.test(environment.buildType)) {
			url = environment.preferEnvironment.api_root + endpoint;
		} else if (/(default)/.test(environment.buildType)) {
			url = server.api_root.replace('https://smartpass.app', 'http://localhost:4200') + endpoint;
		} else {
			// url = 'https://smartpass.app/api/prod-us-central' + endpoint;
			// url = 'https://smartpass.app/api/staging/' + endpoint;

			url = server.api_root + endpoint;
		}
	}
	return url;
}

export interface LoginServer {
	api_root: string;
	client_id: string;
	client_secret: string;
	domain: string;
	icon_url: string;
	icon_search_url: string;
	name: string;
	ws_url: string;
}

export interface LoginResponse {
	servers: LoginServer[];
	token?: {
		auth_token: string;
		refresh_token?: string;
		access_token?: string;
	};
}

export type LoginResult =
	| {
			ok: true;
			server: LoginServer;
	  }
	| {
			ok: false;
			server: LoginServer;
			error: unknown;
	  };

export interface AuthContext {
	server: LoginServer;
	auth: ServerAuth;
	classlink_token?: string;
	clever_token?: string;
	google_token?: string;
}

export interface SPError {
	header: string;
	message: string | SafeHtml;
}

class LoginServerError extends Error {
	constructor(msg: string) {
		super(msg);
		// required for instanceof to work properly
		Object.setPrototypeOf(this, LoginServerError.prototype);
	}
}

class SilentError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'SPSilentError';
	}
}

const discoveryEndpoint = (userName: string) =>
	/proxy/.test(environment.buildType)
		? `/api/discovery/email_info?email=${encodeURIComponent(userName)}`
		: `https://smartpass.app/api/discovery/email_info?email=${encodeURIComponent(userName)}`;

const discoveryV2Endpoint = /(proxy)/.test(environment.buildType) ? '/api/discovery/v2/find' : 'https://smartpass.app/api/discovery/v2/find';

/**
 * This service is supposed to build off Angular's HttpClient but it ends up
 * also tracking the authentication state, authcontext and dealing with a bunch
 * of other login behaviour
 *
 * This has to be refactored very heavily to remove the login behavior and to track
 * the auth state elsewhere
 */
@Injectable({
	providedIn: 'root',
})
export class HttpService implements OnDestroy {
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	schoolSignInRegisterText$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	private _authContext: AuthContext = null;
	authContext$: BehaviorSubject<AuthContext> = new BehaviorSubject<AuthContext>(null);

	effectiveUserId: BehaviorSubject<number> = new BehaviorSubject(null);
	schoolToggle$: Subject<School> = new Subject<School>();
	schools$: Observable<School[]> = this.loginService.isAuthenticated$.pipe(
		filter((v) => v),
		tap(() => {
			this.getSchoolsRequest();
		}),
		exhaustMap(() =>
			this.schoolsCollection$.pipe(
				filter((s) => !!s.length),
				take(1)
			)
		)
	);
	langToggle$: Subject<string> = new Subject<string>();
	schoolsCollection$: Observable<School[]> = this.store.select(getSchoolsCollection);
	currentUpdateSchool$: Observable<School> = this.store.select(getCurrentSchool);
	schoolsLength$: Observable<number> = this.store.select(getSchoolsLength);

	schoolsLoaded$: Observable<boolean> = this.store.select(getLoadedSchools);

	currentSchoolSubject = new BehaviorSubject<School>(null);
	currentSchool$: Observable<School> = this.currentSchoolSubject.asObservable();
	idCardViewSubject = new BehaviorSubject<boolean>(false);
	currentLangSubject = new BehaviorSubject<string>('en');
	currentLang$: Observable<string> = this.currentLangSubject.asObservable();
	// should come from server
	langs$: Observable<string[]> = of(['en', 'es']);

	globalReload$ = this.currentSchool$.pipe(
		filter((school) => !!school),
		map((school) => (school ? school.id : null)),
		delay(5)
	);

	private hasRequestedToken = false;

	private cannotRefreshGoogle = new Error('cannot refresh google');
	private cannotRefreshClassLink = new Error('cannot refresh classlink');
	private cannotRefreshClever = new Error('cannot refresh clever');

	constructor(
		@Inject(APP_BASE_HREF)
		private baseHref: string,
		private http: HttpClient,
		private loginService: LoginService,
		private storage: StorageService,
		private pwaStorage: LocalStorage,
		private store: Store<AppState>,
		private matDialog: MatDialog,
		private router: Router
	) {
		// the school list is loaded when a user authenticates and we need to choose a current school of the school array.
		// First, if there is a current school loaded, try to use that one.
		// Then, if there is a school id saved in local storage, try to use that.
		// Last, choose a school arbitrarily.
		this.schools$
			.pipe(
				takeUntil(this.destroyed$),
				filter((schools) => !!schools.length)
			)
			.subscribe({
				next: (schools) => {
					// choose the currently loaded school
					const lastSchool = this.currentSchoolSubject.getValue();
					let currentSchool = null;
					if (lastSchool !== null && isSchoolInArray(lastSchool.id, schools)) {
						currentSchool = getSchoolInArray(lastSchool.id, schools);
						this.currentSchoolSubject.next(currentSchool);
						this.idCardViewSubject.next(
							currentSchool ? currentSchool.hall_pass_access === 'no_access' && currentSchool.feature_flag_digital_id === true : false
						);
						return;
					}

					// choose a saved school from local storage
					const savedId = this.storage.getItem('last_school_id');
					if (savedId !== null && isSchoolInArray(savedId, schools)) {
						currentSchool = getSchoolInArray(savedId, schools);
						this.currentSchoolSubject.next(currentSchool);
						this.idCardViewSubject.next(
							currentSchool ? currentSchool.hall_pass_access === 'no_access' && currentSchool.feature_flag_digital_id === true : false
						);
						return;
					}

					// arbitrarily chooses a school
					if (schools.length > 0) {
						// sort schools alphabetically
						const sortedSchools = schools.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
						currentSchool = sortedSchools[0];
						this.currentSchoolSubject.next(currentSchool);
						this.idCardViewSubject.next(
							currentSchool ? currentSchool.hall_pass_access === 'no_access' && currentSchool.feature_flag_digital_id === true : false
						);
						return;
					}

					// No school selected (should we be worried about this?)
					this.currentSchoolSubject.next(null);
				},
			});

		this.langs$
			.pipe(
				takeUntil(this.destroyed$),
				filter((lang) => !!lang)
			)
			.subscribe((langs) => {
				const lang = this.storage.getItem('codelang');
				if (lang) {
					if (langs.includes(lang)) {
						this.currentLangSubject.next(lang);
						return;
					} else {
						this.setLang(null);
						return;
					}
				}
				const chosenLang = this.currentLangSubject.getValue();
				if (chosenLang !== null && langs.includes(chosenLang)) {
					this.currentLangSubject.next(chosenLang);
					return;
				}

				if (langs.length > 0) {
					this.currentLangSubject.next(langs[0]);
					return;
				}
				this.currentLangSubject.next(null);
				return;
			});

		this.setLang('en');
		// HACK!
		this.storage.setItem('ljs-lang', 'en');

		this.loginService
			.getAuthObject()
			.pipe(
				takeUntil(this.destroyed$),
				switchMap((authObj) => this.loginSession(authObj))
			)
			.subscribe((result) => this.handleLoginAttempt(result));

		this.authContext$.pipe(takeUntil(this.destroyed$)).subscribe({
			next: (ctx) => {
				if (ctx === null) {
					console.log('Auth CTX set to null');
				} else {
					console.log('Auth CTX updated');
				}
			},
		});
	}

	ngOnDestroy() {
		console.log('HttpService ngOnDestroy: cleaning up...');
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	/**
	 * Discover which server the user is on
	 * @param userName the username or email of the account
	 */
	discoverServer(userName: string) {
		return this.http.get<DiscoverServerResponse>(discoveryEndpoint(userName)).pipe(
			tap((response) => {
				if (response?.auth_types?.length === 0) {
					throw new Error("Couldn't find that username or email");
				}
			})
		);
	}

	getServerFromStorage(): { server: LoginServer } {
		const server = this.storage.getItem('server');
		if (!server) {
			this.loginService.isAuthenticated$.next(false);
			return null;
		}

		return JSON.parse(server);
	}

	// Used in AccessTokenInterceptor for token refresh and adding access token
	getAuthContext(): AuthContext {
		return this._authContext;
	}

	setAuthContext(ctx: any, forKioskMode = false): void {
		if (ctx && (!this.storage.getItem('server') || forKioskMode)) {
			this.storage.setItem('server', JSON.stringify(ctx));
		}
		this._authContext = ctx;
		this.authContext$.next(ctx);
	}

	// THIS SHOULD BE IN THE LOGIN SERVICE
	// The code is so tangled right now that doing a major refactor may be too time-consuming
	updateAuthFromExternalLogin(url: string, loginCode: string, scope: string) {
		if (url.includes('google_oauth')) {
			this.storage.setItem('authType', AuthType.Google);
			this.loginService.updateAuth({ google_code: loginCode, type: 'google-login' });
		} else if (url.includes('classlink_oauth')) {
			this.storage.setItem('authType', AuthType.Classlink);
			this.loginService.updateAuth({ classlink_code: loginCode, type: 'classlink-login' });
		} else if (scope) {
			this.storage.setItem('authType', AuthType.Clever);
			this.loginService.updateAuth({ clever_code: loginCode, type: 'clever-login' });
		}
	}

	getRedirectUrl(): string {
		return [window.location.protocol, '//', window.location.host, this.baseHref].join('');
	}

	getEncodedRedirectUrl(): string {
		return encodeURIComponent(this.getRedirectUrl());
	}

	loginSession(authObject: AuthObject): Observable<LoginResult> {
		const formData = new FormData();
		let sessionLogin: SessionLogin;

		formData.append('platform_type', 'web');
		if (isDemoLogin(authObject)) {
			sessionLogin = {
				provider: LoginProvider.Password,
				token: authObject.password,
				username: authObject.username,
			};
			formData.append('email', authObject.username);
		} else if (isClassLinkLogin(authObject)) {
			sessionLogin = { provider: LoginProvider.Classlink };
			formData.append('code', authObject.classlink_code);
			formData.append('provider', 'classlink');
		} else if (isCleverLogin(authObject)) {
			sessionLogin = { provider: LoginProvider.Clever };
			formData.append('code', authObject.clever_code);
			formData.append('provider', 'clever');
			formData.append('redirect_uri', this.getRedirectUrl());
		} else if (isGoogleLogin(authObject)) {
			sessionLogin = { provider: LoginProvider.Google };
			formData.append('code', authObject.google_code);
			formData.append('provider', 'google-oauth-code');
			formData.append('redirect_uri', this.getRedirectUrl() + 'google_oauth');
		}

		return this.http.post(discoveryV2Endpoint, formData).pipe(
			switchMap((servers: LoginResponse) => {
				return this.pwaStorage.setItem('servers', servers).pipe(mapTo(servers));
			}),
			switchMap((servers: LoginResponse) => {
				if (!isDemoLogin(authObject)) {
					sessionLogin.token = servers.token.access_token;
				}

				return this.tryLoginServers(servers.servers, sessionLogin);
			})
		);
	}

	/**
	 * Attempt to log in to each server discovered. This will typically only be
	 * one server, except in the case of @smartpass.app emails that might exist
	 * in either staging or prod.
	 */
	private tryLoginServers(servers: LoginServer[], sessionLogin: SessionLogin): Observable<LoginResult> {
		return from(servers).pipe(
			map((server) =>
				this.http
					.post<void>(makeUrl(server, 'sessions'), sessionLogin, {
						withCredentials: true,
						observe: 'response',
					})
					.pipe(
						map((response): LoginResult | null => (!response ? null : { ok: true as const, server })),
						// We expect HTTP errors as a routine part of (failed) login attempts:
						catchError((error: unknown): Observable<LoginResult> => of({ ok: false as const, server, error }))
					)
			),
			concatAll(), // Try the login servers one by one, in order.
			takeWhile((result) => !result.ok, true), // Keep going until we find a successful login (or run out of servers)
			last()
		);
	}

	private handleLoginAttempt(result: LoginResult): void {
		if (result.ok) {
			this.setAuthContext({ server: result.server });
			this.loginService.continueAuthFlow$.next(true);
			this.loginService.isAuthenticated$.next(true);
		} else if (result.ok === false) {
			// Likely an HttpErrorResponse, but maybe not!
			const error: any = result.error;

			if (error.error?.detail) {
				this.loginService.loginErrorMessage$.next(error.error.detail);
			} else if (error.error) {
				this.loginService.loginErrorMessage$.next(error.error);
			} else {
				this.loginService.loginErrorMessage$.next('Unknown error occurred. Please try again.');
			}
		}
	}

	private performRequest<T>(predicate: (ctx: LoginServer) => Observable<T>): Observable<T> {
		const server = this.storage.getItem('server');
		if (!server) {
			this.loginService.isAuthenticated$.next(false);
			return throwError(new LoginServerError('No login server!'));
		}

		const parsedServer: { server: LoginServer } = JSON.parse(server);

		return predicate(parsedServer.server);
	}

	// Used in AccessTokenInterceptor to trigger refresh
	refreshAuthContext(): Observable<any> {
		const signOutCatch = catchError((err) => {
			this.showSignBackIn().subscribe((_) => {
				if (err === this.cannotRefreshGoogle) {
					const url = LoginService.googleOAuthUrl + `&redirect_uri=${this.getRedirectUrl()}google_oauth`;
					this.loginService.clearInternal(true);
					window.location.href = url;
				} else if (err === this.cannotRefreshClever) {
					this.loginService.clearInternal(true);
					const redirect = this.getEncodedRedirectUrl();
					window.location.href = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirect}&client_id=f4260ade643c042482a3`;
				} else {
					console.log('navigating to sign-out');
					this.router.navigate(['sign-out']);
				}
			});
			this.loginService.isAuthenticated$.next(false);
			throw err;
		});

		return of(1);
	}

	showSignBackIn(): Observable<any> {
		const ref = this.matDialog.open(SignedOutToastComponent, {
			panelClass: 'form-dialog-container-white',
			disableClose: true,
			backdropClass: 'white-backdrop',
			data: {},
		});
		return ref.afterClosed();
	}

	clearInternal() {
		this.setAuthContext(null);
		this.hasRequestedToken = false;
	}

	setSchool(school: School) {
		if (!!school && school.id) {
			this.storage.setItem('last_school_id', school.id);
		} else {
			// this.storage.removeItem('last_school_id');
		}
		this.currentSchoolSubject.next(school);
	}

	getSchoolsRequest() {
		this.store.dispatch(getSchools());
	}

	getSchools(): Observable<School[]> {
		return this.get('v1/schools');
	}

	getSchool() {
		return this.currentSchoolSubject.getValue();
	}

	// uncomment when app uses formatDate
	//private esUSRegistered = false;

	setLang(lang: string) {
		if (lang) {
			this.storage.setItem('codelang', lang);
		} else {
			this.storage.removeItem('codelang');
		}
		this.currentLangSubject.next(lang);
		// uncomment when app uses formatDate and so on
		//if (lang === 'es' && !this.esUSRegistered) {
		//  import(
		/* webpackInclude: /es-US\.js$/ */
		//    '@angular/common/locales/es-US'
		//  ).then(lang => {
		//    registerLocaleData(lang.default);
		//    this.esUSRegistered = true;
		//  });
		//}
	}

	getLang() {
		return this.currentLangSubject.getValue();
	}

	// bridge between lang code as it is used in app and ISO locale_id
	// uncomment when app uses formatDate and so on
	/*private localeIDMap = {'en': 'en-US', 'es': 'es-US'};
  get LocaleID() {
    const code = this.getLang();
    return this.localeIDMap[code] ?? 'en-US';
  }*/

	getEffectiveUserId() {
		return this.effectiveUserId.getValue();
	}

	searchIcons(search: string, config?: Config): Observable<Icon[]> {
		return this.performRequest((ctx) => {
			return this.http.get<Icon[]>(`${ctx.icon_search_url}?query=${encodeURIComponent(search)}`);
		});
	}

	get<T>(url, config?: Config, schoolOverride?: School): Observable<T> {
		const school = schoolOverride || this.getSchool();
		const effectiveUserId = this.getEffectiveUserId();
		return this.performRequest<T>((server) => this.http.get<T>(makeUrl(server, url), makeConfig(config, school, effectiveUserId)));
	}

	post<T>(url: string, body?: any, config?: Config, isFormData = true): Observable<T> {
		if (body && !(body instanceof FormData) && isFormData) {
			const formData: FormData = new FormData();
			for (const prop in body) {
				// eslint-disable-next-line no-prototype-builtins
				if (body.hasOwnProperty(prop)) {
					if (body[prop] instanceof Array) {
						for (const sprop of body[prop]) {
							formData.append(prop, sprop);
						}
					} else {
						formData.append(prop, body[prop]);
					}
				}
			}
			body = formData;
		}

		return this.performRequest((server) =>
			this.http.post<T>(makeUrl(server, url), body, makeConfig(config, this.getSchool(), this.getEffectiveUserId()))
		);
	}

	delete<T>(url, config?: Config): Observable<T> {
		return this.performRequest((server) =>
			this.http.delete<T>(makeUrl(server, url), makeConfig(config, this.getSchool(), this.getEffectiveUserId()))
		);
	}

	deleteV2(url: string, body?: any, config?: Config): Observable<boolean> {
		const options: any = makeConfig(config, this.getSchool(), this.getEffectiveUserId());
		options.body = body; // Include the body in the options object

		return this.performRequest<boolean>((server) => this.http.request('delete', makeUrl(server, url), options).pipe(map(() => true)));
	}

	put<T>(url, body?: any, config?: Config): Observable<T> {
		const formData: FormData = new FormData();
		for (const prop in body) {
			// eslint-disable-next-line no-prototype-builtins
			if (body.hasOwnProperty(prop)) {
				if (body[prop] instanceof Array) {
					for (const sprop of body[prop]) {
						formData.append(prop, sprop);
					}
				} else {
					formData.append(prop, body[prop]);
				}
			}
		}
		return this.performRequest((server) =>
			this.http.put<T>(makeUrl(server, url), body, makeConfig(config, this.getSchool(), this.getEffectiveUserId()))
		);
	}

	patch<T>(url, body?: any, config?: Config): Observable<T> {
		const formData: FormData = new FormData();
		for (const prop in body) {
			// eslint-disable-next-line no-prototype-builtins
			if (body.hasOwnProperty(prop)) {
				if (body[prop] instanceof Array) {
					for (const sprop of body[prop]) {
						formData.append(prop, sprop);
					}
				} else {
					formData.append(prop, body[prop]);
				}
			}
		}
		return this.performRequest((server) =>
			this.http.patch<T>(makeUrl(server, url), body, makeConfig(config, this.getSchool(), this.getEffectiveUserId()))
		);
	}
}
