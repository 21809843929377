<div class="card-container">
	<div
		class="pass-card-container"
		[id]="passCreationId"
		*ngIf="request"
		[ngClass]="{
			'for-staff': forStaff,
			'in-form-container': inFormContainer,
			'rescheduling-missed-request': reschedulingMissedRequest,
			'for-future-request': forFuture
		}"
		[style.transform]="'scale(1)'"
		style="translate(100%,-100%)">
		<div
			class="pass-card-wrapper"
			[ngClass]="{ 'pass-border': isModal }"
			[@scalePassCards]="scaleCardTrigger$ | async"
			#cardWrapper
			[style.background-image]="backgroundGradient"
			[style.border-radius]="borderRadius"
			[style.border-color]="request.color_profile.solid_color"
			ondragstart="return false"
			*ngIf="request">
			<sp-pass-modal-header
				*ngIf="originRoomIcon || !forStaff"
				[pass]="request"
				[passStatus]="request.status"
				[forStaff]="forStaff"
				[isMissed]="isMissedRequest"
				[displayBackButton]="displayTeacherPinEntry"
				[invalidDate]="invalidDate"
				[openedFrom]="formState?.openedFrom"
				[inFormContainer]="inFormContainer"
				[originRoomIcon]="originRoomIcon"
				[originRoomTitle]="request.origin.title"
				(displayTeacherPinEntryEmit)="displayTeacherPinEntry = $event"
				(cancelRequestEmit)="cancelRequest($event)"></sp-pass-modal-header>

			<!-- **************** Creating pass in form flow ******************-->
			<ng-container *ngIf="inFormContainer || forKioskModeFormContainer">
				<div class="center-block">
					<div class="center-block-inner">
						<ng-container *ngIf="!forStaff">
							<ng-container *ngTemplateOutlet="studentRequestInFormTemplateCenter"></ng-container>
						</ng-container>

						<ng-container *ngIf="forStaff">
							<ng-container *ngTemplateOutlet="teacherRequestInFormTemplateCenter"></ng-container>
						</ng-container>
					</div>
				</div>
			</ng-container>
			<!-- **************** END creating pass in form flow ******************-->

			<ng-container *ngIf="(isModal || kioskModeRestricted) && !inFormContainer">
				<div class="center-block">
					<div class="center-block-inner" [ngClass]="{ 'with-date': !isMissedRequest }">
						<!-- **************** STUDENT CENTER BLOCK CONTENT ******************-->
						<ng-container *ngIf="!forStaff">
							<!-- pending -->
							<ng-container *ngIf="request.status === 'pending' && !isMissedRequest">
								<ng-container *ngTemplateOutlet="studentPendingTemplateCenter"></ng-container>
							</ng-container>
							<!-- missed -->
							<ng-container *ngIf="request.status === 'pending' && isMissedRequest">
								<ng-container *ngTemplateOutlet="studentMissedRequestTemplateCenter"></ng-container>
							</ng-container>
							<ng-container *ngIf="request.status === 'accepted'">
								<ng-container *ngTemplateOutlet="studentAcceptedTemplateCenter"></ng-container>
							</ng-container>
							<ng-container *ngIf="request.status === 'declined'">
								<ng-container *ngTemplateOutlet="studentDeclinedTemplateCenter"></ng-container>
							</ng-container>
						</ng-container>

						<!-- **************** STAFF VIEW ******************-->
						<ng-container *ngIf="forStaff">
							<ng-container *ngIf="(request.status === 'pending' && !isMissedRequest) || isDecliningRequest">
								<ng-container *ngTemplateOutlet="teacherPendingTemplateCenter"></ng-container>
							</ng-container>

							<ng-container *ngIf="request.status === 'pending' && isMissedRequest">
								<ng-container *ngTemplateOutlet="teacherMissedRequestTemplateCenter"></ng-container>
							</ng-container>
							<ng-container *ngIf="request.status === 'declined' && !isDecliningRequest">
								<ng-container *ngTemplateOutlet="teacherDeclinedRequestTemplateCenter"></ng-container>
							</ng-container>
						</ng-container>

						<div class="pass-card-footer" *ngIf="forStaff && !isModal">
							<div class="student-footer"></div>
							<div class="student-text" notranslate>{{ request.student.display_name }}</div>
						</div>
					</div>
				</div>
			</ng-container>
			<div class="bottom-block {{ !isDecliningRequest && forStaff ? request.status : '' }}">
				<!-- **************** STUDENT BOTTOM BLOCK CONTENT ******************-->
				<ng-container *ngIf="!forStaff && !inFormContainer && !forKioskModeFormContainer">
					<!-- pending -->
					<ng-container *ngIf="request.status === 'pending' && !isMissedRequest">
						<ng-container *ngTemplateOutlet="studentPendingTemplateBottom"></ng-container>
					</ng-container>
					<!-- missed -->
					<ng-container *ngIf="request.status === 'pending' && isMissedRequest">
						<ng-container *ngTemplateOutlet="studentMissedRequestTemplateBottom"></ng-container>
					</ng-container>
					<ng-container *ngIf="request.status === 'accepted'">
						<ng-container *ngTemplateOutlet="studentAcceptedTemplateBottom"></ng-container>
					</ng-container>
					<ng-container *ngIf="request.status === 'declined'">
						<ng-container *ngTemplateOutlet="studentDeclinedTemplateBottom"></ng-container>
					</ng-container>
				</ng-container>

				<!-- **************** STAFF BOTTOM BLOCK CONTENT ******************-->
				<ng-container *ngIf="forStaff && !inFormContainer">
					<ng-container *ngIf="(request.status === 'pending' && !isMissedRequest) || isDecliningRequest">
						<ng-container *ngTemplateOutlet="teacherPendingTemplateBottom"></ng-container>
					</ng-container>

					<ng-container *ngIf="request.status === 'pending' && isMissedRequest">
						<ng-container *ngTemplateOutlet="teacherMissedRequestTemplateBottom"></ng-container>
					</ng-container>

					<ng-container *ngIf="request.status === 'declined' && !isDecliningRequest">
						<ng-container *ngTemplateOutlet="teacherDeclinedRequestTemplateBottom"></ng-container>
					</ng-container>
				</ng-container>

				<!-- **************** IN FORM FLOW BOTTOM BLOCK CONTENT ******************-->
				<ng-container *ngIf="inFormContainer || forKioskModeFormContainer">
					<ng-container *ngIf="!forStaff">
						<ng-container *ngTemplateOutlet="studentRequestInFormTemplateBottom"></ng-container>
					</ng-container>

					<ng-container *ngIf="forStaff">
						<ng-container *ngTemplateOutlet="teacherRequestInFormTemplateBottom"></ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div
			class="student-footer-info"
			*ngIf="
				(forStaff && isModal) ||
				((formState?.kioskMode || data.kioskMode) && request.id && request.status !== 'declined' && !inFormContainer) ||
				forKioskModeFormContainer
			">
			<app-student-passes [pass]="request" [profile]="request.student" [displayEncounterPreventionInfo]="!isKioskMode"></app-student-passes>
		</div>
	</div>
</div>
<ng-template #PassLimitOverride>
	<div class="ds-dialog-normal-text">
		<span>If it's an emergency, you can override the limit.</span>
	</div>
</ng-template>

<!-- **** STUDENT VIEW *** --->
<ng-template #teacherPin>
	<div class="teacher-pin">
		<app-teacher-pin-student
			(pinResult)="handlePinResult($event)"
			[request]="request"
			[requestId]="request.id"
			[isStaff]="forStaff"></app-teacher-pin-student>
	</div>
</ng-template>

<!-- **** STAFF VIEW *** --->
<ng-template #CDate>
	<div class="button-wrapper change-date">
		<div class="button single-button">
			<app-card-button [height]="'81px'" (onClick)="changeDate()" [backgroundGradient]="backgroundGradient">
				<div class="button-content" [style.flex-direction]="'column'">
					<div class="button-content-title">Change Date & Time</div>
					<div class="button-content-subtitle">Resend Pass Request to Student</div>
				</div>
			</app-card-button>
		</div>
	</div>
</ng-template>

<ng-template #teacherPendingTemplateCenter>
	<app-pager [page]="pageNumber" [arrowPosition]="'-87px'" [isStaff]="true" [activeDotColor]="request.color_profile.solid_color">
		<div class="page-1 page-size" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="timer-wrapper">
				<sp-circle-countdown [progress]="0" [ringColor]="'#F0F2F5'" [diameter]="280">
					<div>{{ request.duration / 60 }} min</div>
				</sp-circle-countdown>
			</div>
		</div>
		<div class="page-2 page-size message-page" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="page-2-wrapper">
				<ng-container *ngTemplateOutlet="messagesForTeacher"></ng-container>
			</div>
		</div>
	</app-pager>
</ng-template>
<ng-template #teacherPendingTemplateBottom>
	<div class="button-wrapper multiple-buttons" *ngIf="!invalidDate; else CDate">
		<div class="button">
			<app-card-button
				(onClick)="approveRequest()"
				[disabled]="performingAction"
				[height]="inFormContainer ? '84px' : '144px'"
				[useOverlay]="false"
				[backgroundGradient]="footerBackgroundGradient">
				<div class="button-content">
					<mat-spinner *ngIf="performingAction" [color]="'accent'" [diameter]="35"></mat-spinner>
					<div *ngIf="!performingAction" class="button-content-title">Approve</div>
				</div>
			</app-card-button>
		</div>
		<div class="button">
			<app-card-button
				(onClick)="denyRequest('No message')"
				*ngIf="!invalidDate"
				[disabled]="isDecliningRequest"
				[height]="inFormContainer ? '84px' : '144px'"
				[useOverlay]="false"
				[border]="'1px solid #D4D9E2'"
				[backgroundGradient]="'#fff,#fff'">
				<div class="button-content denying-request">
					<mat-spinner *ngIf="isDecliningRequest" [diameter]="35" [color]="'primary'"></mat-spinner>
					<div *ngIf="!isDecliningRequest" class="button-content-title" [style.color]="request.color_profile.solid_color">Deny</div>
				</div>
			</app-card-button>
		</div>
	</div>
</ng-template>
<ng-template #teacherMissedRequestTemplateCenter>
	<div class="timer-wrapper">
		<sp-circle-countdown [topText]="'Request Missed'" [progress]="0" [ringColor]="'#E2E6EC'" [diameter]="316">
			<div>{{ request.duration / 60 }} min</div>
		</sp-circle-countdown>
	</div>
</ng-template>
<ng-template #teacherDeclinedRequestTemplateCenter>
	<app-pager [arrowPosition]="'-87px'" [isStaff]="false">
		<div class="page-1 page-size" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<app-travel-view [textColor]="'#7083a0'" [iconColor]="'#000000'" [forStaff]="forStaff" [height]="'170px'" [pass]="request"></app-travel-view>
		</div>
		<div class="page-2 page-size message-page" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="page-2-wrapper">
				<ng-container *ngTemplateOutlet="messagesForTeacher"></ng-container>
			</div>
		</div>
	</app-pager>
</ng-template>
<ng-template #teacherMissedRequestTemplateBottom>
	<div class="button-wrapper change-date">
		<div class="button single-button">
			<app-card-button
				[height]="inFormContainer ? '81px' : '144px'"
				(onClick)="changeDate(true)"
				[useOverlay]="false"
				[backgroundGradient]="footerBackgroundGradient">
				<div class="button-content" [style.flex-direction]="'column'">
					<div class="button-content-title">Change Date & Time</div>
					<div class="button-content-subtitle">Resend Pass Request to Student</div>
				</div>
			</app-card-button>
		</div>
	</div>
</ng-template>
<ng-template #teacherDeclinedRequestTemplateBottom>
	<app-pager [pages]="2" [page]="1" [pagerClass]="'pager-top'" [activeDotColor]="'#7083A0'" [inactiveDotColor]="'#F5F6F8'">
		<div class="page-1 teacher-details" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="title">Pass Denied</div>
		</div>
		<div class="page-2 teacher-details" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="title">Pass Created</div>
			<div class="subtitle">By {{ request.student.display_name }} -</div>
		</div>
	</app-pager>
</ng-template>
<!--  <ng-template #forNow>
	<span>{{ isModal ? (request.travel_type == 'one_way' ? 'One-way' : 'Round-trip') : '' }} {{ forStaff ? 'pass for now' : '' }}</span>
</ng-template>-->
<!-- student making request to teacher -->
<ng-template #studentPendingTemplateCenter>
	<app-pager
		*ngIf="!forInput && !displayTeacherPinEntry; else teacherPin"
		[arrowPosition]="'-87px'"
		[isStaff]="false"
		[activeDotColor]="request.color_profile.solid_color">
		<div class="page-1 page-size" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="timer-wrapper">
				<sp-circle-countdown [progress]="0" [ringColor]="'#E2E6EC'" [diameter]="280">
					<div>{{ request.duration / 60 }} min</div>
				</sp-circle-countdown>
			</div>
		</div>
		<div class="page-2 page-size message-page" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="page-2-wrapper">
				<ng-container *ngTemplateOutlet="messagesForStudent"></ng-container>
			</div>
		</div>
	</app-pager>
</ng-template>
<ng-template #studentPendingTemplateBottom>
	<ng-container *ngIf="!displayTeacherPinEntry">
		<div (mouseenter)="onHover(desc, title)" (mouseleave)="onLeave(desc)">
			<div class="button-wrapper enter-teacher-pin">
				<div class="button single-button">
					<app-card-button
						[height]="inFormContainer ? '81px' : '144px'"
						[useOverlay]="false"
						[backgroundGradient]="footerBackgroundGradient"
						(onClick)="goToPin()">
						<div class="button-content">
							<div class="center-content">
								<div class="button-content-title" style="display: flex; align-items: center">
									<img style="margin-right: 10px" width="26" height="30" [src]="'./assets/Lock dots (White).svg' | resolveAsset" alt="Lock" /> Enter
									Teacher Pin
								</div>
								<div class="description-container" #title>
									<div
										class="shadow left-radius"
										[ngStyle]="{ 'box-shadow': '0px 0px 17px 10px ' + solidColorRgba2 }"
										[style.background]="
											request.gradient_color ? request.gradient_color.split(',')[1] : request.color_profile.gradient_color.split(',')[1]
										"
										[style.left]="0"
										*ngIf="leftTextShadow"></div>
									<div class="description-title" #desc [style.margin-left]="filteredTeachers.length > 1 ? '0px' : '15px'">
										Pass Request sent to:
										<span style="width: 5px"></span>
										<span notranslate style="font-weight: normal" *ngFor="let teacher of filteredTeachers; let i = index">
											<span style="margin-right: 3px">{{ teacher.display_name + (i === filteredTeachers.length - 1 ? '' : ', ') }}</span>
										</span>
									</div>
									<div
										class="shadow right-radius"
										[ngStyle]="{ 'box-shadow': '0px 0px 30px 20px ' + solidColorRgba }"
										[style.background]="
											request.gradient_color ? request.gradient_color.split(',')[1] : request.color_profile.gradient_color.split(',')[1]
										"
										*ngIf="!removeShadow"
										[style.right]="0"></div>
								</div>
							</div>
						</div>
					</app-card-button>
				</div>
			</div>
		</div>
	</ng-container>
</ng-template>

<ng-template #studentRequestInFormTemplateCenter>
	<div class="duration-picker">
		<app-duration-picker
			(onChange)="selectedDuration = $event"
			(onSelectTravelType)="selectedTravelType = $event"
			[inFormContainer]="inFormContainer"
			[choices]="request.destination.travel_types"
			[maxDuration]="request.destination.max_allowed_time ? request.destination.max_allowed_time : 15">
		</app-duration-picker>
	</div>
</ng-template>

<ng-template #studentRequestInFormTemplateBottom>
	<div class="button-wrapper">
		<div class="button single-button">
			<app-card-button
				[height]="inFormContainer ? '75px' : '144px'"
				(onClick)="newRequest()"
				[disabled]="performingAction"
				[useOverlay]="!inFormContainer"
				[inFormContainer]="inFormContainer"
				[backgroundGradient]="footerBackgroundGradient">
				<div class="button-content">
					<mat-spinner *ngIf="performingAction" [color]="'accent'" [diameter]="35"></mat-spinner>
					<div *ngIf="!performingAction" class="button-content-title">Request Pass</div>
					<img *ngIf="!performingAction" class="button-icon-right" [src]="'./assets/Chevron Right (White).svg' | resolveAsset" />
				</div>
			</app-card-button>
		</div>
	</div>
</ng-template>

<ng-template #teacherRequestInFormTemplateCenter>
	<div class="duration-picker">
		<app-duration-picker
			(onChange)="selectedDuration = $event"
			(onSelectTravelType)="selectedTravelType = $event"
			[inFormContainer]="inFormContainer"
			[choices]="request.destination.travel_types"
			[maxDuration]="request.destination.max_allowed_time ? request.destination.max_allowed_time : 15"></app-duration-picker>
	</div>
</ng-template>

<ng-template #teacherRequestInFormTemplateBottom>
	<div class="button-wrapper">
		<div class="button single-button">
			<app-card-button
				[height]="inFormContainer ? '75px' : '144px'"
				(onClick)="newRequest()"
				[disabled]="performingAction"
				[inFormContainer]="inFormContainer"
				[backgroundGradient]="backgroundGradient">
				<div class="button-content">
					<mat-spinner *ngIf="performingAction" [color]="'accent'" [diameter]="35"></mat-spinner>
					<div *ngIf="!performingAction" class="button-content-title">Resend Request</div>
					<img *ngIf="!performingAction" class="button-icon-right" [src]="'./assets/Chevron Right (White).svg' | resolveAsset" />
				</div>
			</app-card-button>
		</div>
	</div>

	<div class="student-footer-info" *ngIf="(forStaff && isModal) || (formState?.kioskMode && request.id && request.status !== 'declined')">
		<app-student-passes [pass]="request" [profile]="request.student" [displayEncounterPreventionInfo]="!isKioskMode"></app-student-passes>
	</div>
</ng-template>

<ng-template #PassLimitOverride>
	<div class="ds-dialog-normal-text">
		<span>If it's an emergency, you can override the limit.</span>
	</div>
</ng-template>
<ng-template #forNow>
	<span>{{ forStaff ? 'Pass For Now' : '' }}</span>
</ng-template>

<ng-template #studentMissedRequestTemplateCenter>
	<div class="timer-wrapper">
		<sp-circle-countdown [topText]="'Request Missed'" [progress]="0" [ringColor]="'#E2E6EC'" [diameter]="316">
			<div>{{ request.duration / 60 }} min</div>
		</sp-circle-countdown>
	</div>
</ng-template>

<ng-template #studentMissedRequestTemplateBottom>
	<div class="button-wrapper change-date">
		<div class="button single-button">
			<app-card-button
				[height]="inFormContainer ? '81px' : '144px'"
				(onClick)="changeDate(true)"
				[useOverlay]="false"
				[backgroundGradient]="footerBackgroundGradient">
				<div class="button-content" [style.flex-direction]="'column'">
					<div class="button-content-title">Change Date & Time</div>
					<div class="button-content-subtitle">Resend Pass Request to Teacher</div>
				</div>
			</app-card-button>
		</div>
	</div>
</ng-template>

<ng-template #studentAcceptedTemplateCenter> </ng-template>
<ng-template #studentAcceptedTemplateBottom>
	<ng-container *ngIf="displayTeacherPinEntry">
		<div>
			<div class="request-description">
				<img class="request-description-icon" [src]="'./assets/Arrow (White).svg' | resolveAsset" />
				<div class="request-description-text" ignore>Pass Request accepted by {{ request.teachers[0].last_name }}</div>
			</div>
		</div>
	</ng-container>
</ng-template>

<ng-template #studentDeclinedTemplateCenter>
	<ng-container *ngIf="showCircleCountdownForStudentDeclined">
		<app-pager [arrowPosition]="'-87px'" [isStaff]="false" [activeDotColor]="request.color_profile.solid_color">
			<div class="page-1 page-size" [attr.data-motion-translate]="(frameMotion$ | async).direction">
				<div class="timer-wrapper">
					<sp-circle-countdown [progress]="0" [ringColor]="'#E2E6EC'" [topText]="'Request Denied'" [diameter]="280">
						<div>{{ request.duration / 60 }} min</div>
					</sp-circle-countdown>
				</div>
			</div>
			<div class="page-2 page-size message-page" [attr.data-motion-translate]="(frameMotion$ | async).direction">
				<div class="page-2-wrapper">
					<ng-container *ngTemplateOutlet="messagesForStudent"></ng-container>
				</div>
			</div>
		</app-pager>
	</ng-container>

	<ng-container *ngIf="!showCircleCountdownForStudentDeclined">
		<div class="duration-picker">
			<app-duration-picker
				(onChange)="selectedDuration = $event"
				(onSelectTravelType)="selectedTravelType = $event"
				[inFormContainer]="inFormContainer"
				[choices]="request.destination.travel_types"
				[maxDuration]="request.destination.max_allowed_time ? request.destination.max_allowed_time : 15"></app-duration-picker>
		</div>
	</ng-container>
</ng-template>

<ng-template #studentDeclinedTemplateBottom>
	<div class="button-wrapper">
		<div class="button single-button">
			<app-card-button
				[height]="inFormContainer ? '75px' : '144px'"
				(onClick)="resendRequest()"
				[disabled]="performingAction"
				[useOverlay]="false"
				[backgroundGradient]="footerBackgroundGradient">
				<div class="button-content">
					<mat-spinner *ngIf="performingAction" [color]="'accent'" [diameter]="35"></mat-spinner>
					<div *ngIf="!performingAction && showCircleCountdownForStudentDeclined" class="button-content-title">Edit & Resend</div>
					<div *ngIf="!performingAction && !showCircleCountdownForStudentDeclined" class="button-content-title">Resend Request</div>
				</div>
			</app-card-button>
		</div>
	</div>
</ng-template>

<ng-template #messagesForStudent>
	<div [ngClass]="isIOSTablet ? 'ios-margin-left' : ''" class="message">
		<div>
			<div class="message-label">Your message:</div>
			<div class="message-copy">{{ request.attachment_message === '' ? 'No message' : request.attachment_message }}</div>
		</div>
		<div *ngIf="request.status !== 'pending'">
			<div class="message-label">Message from {{ request.teachers[0].last_name }}:</div>
			<div class="message-copy">{{ request.declined_message === '' || !request.declined_message ? 'No message' : request.declined_message }}</div>
		</div>
	</div>
</ng-template>

<ng-template #messagesForTeacher>
	<div [ngClass]="isIOSTablet ? 'ios-margin-left' : ''" class="message">
		<div *ngIf="request.status === 'declined'">
			<div class="message-label">Message from {{ request.teachers[0].last_name }}:</div>
			<div class="message-copy">{{ request.declined_message === '' || !request.declined_message ? 'No message' : request.declined_message }}</div>
		</div>
		<div>
			<div class="message-label">Message from {{ studentName }}:</div>
			<div class="message-copy">
				{{ request.attachment_message === '' || !request.attachment_message ? 'No message' : request.attachment_message }}
			</div>
		</div>
		<div *ngIf="request.status !== 'pending' && request.status !== 'declined'">
			<div class="message-label">Message from {{ request.teachers[0].last_name }}:</div>
			<div class="message-copy">{{ request.declined_message === '' || !request.declined_message ? 'No message' : request.declined_message }}</div>
		</div>
	</div>
</ng-template>
