<div class="select-days">
	<div class="sd-title">
		<div (click)="handleCancel()">Cancel</div>
		<span>{{ data.flexPeriod.name }}</span>
		<div (click)="handleSubmit()">Save</div>
	</div>
	<div class="sd-list">
		<div *ngFor="let schedule of options" class="sd-option" [ngClass]="schedule.selected ? 'selected' : ''" (click)="toggle(schedule.start)">
			<div>
				<img *ngIf="schedule.selected" [src]="'./assets/Check (Green).svg' | resolveAsset" alt="Checked" />
			</div>
			<span>{{ schedule.start | date : 'EEEE, MMMM d' }}</span>
			<span>{{ schedule.start | date : 'h:mm a' }} - {{ schedule.end | date : 'h:mm a' }}</span>
		</div>
	</div>
	<div class="sd-footer">
		<span>Repeats every {{ data.flexPeriod.name }}</span>
		<app-toggle-input [controlSize]="'small'" (pushOutValue)="onEnabledToggle($event)" [delimiter]="false"></app-toggle-input>
	</div>
</div>
