import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FlexPeriod, FlexPeriodService } from '../flex-period.service';
import { SchoolActivity, SchoolActivityInstance, SchoolActivityService } from '../services/school-activity.service';
import { FormControl, FormGroup } from '@angular/forms';
import { EMPTY, Subject, combineLatest } from 'rxjs';
import { catchError, finalize, retry, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { ToastService } from '../services/toast.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DaysOfWeek } from '../admin/flex-period/create-flex-period/create-flex-period.component';

enum ProcessingState {
	Loading,
	Loaded,
	Error,
}

@Component({
	selector: 'sp-activity-sign-up',
	templateUrl: './activity-sign-up.component.html',
	styleUrls: ['./activity-sign-up.component.scss'],
})
export class ActivitySignUpComponent implements OnInit, OnDestroy {
	flexOptions: { name: string; value: number }[] = [];
	visibleInstances: { instance: SchoolActivityInstance; activity: SchoolActivity }[] = [];
	DaysOfWeek = DaysOfWeek;
	filters: FormGroup;
	ProcessingState = ProcessingState;
	processing = ProcessingState.Loading;

	private userId: number;
	private destroy$: Subject<void> = new Subject<void>();
	private filterForAvailable = false;
	private activities: SchoolActivity[];
	private instances: SchoolActivityInstance[] = [];
	private flexPeriod: FlexPeriod;

	constructor(
		private flexPeriodService: FlexPeriodService,
		private activityService: SchoolActivityService,
		private userService: UserService,
		private toastService: ToastService,
		public dialogRef: MatDialogRef<ActivitySignUpComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			flexPeriodId?: number;
			date?: Date;
			selectedActivityId: number;
		}
	) {}

	ngOnInit(): void {
		const initFP = 0;
		this.filters = new FormGroup({
			flexPeriod: new FormControl({ value: initFP, disabled: true }),
			search: new FormControl(''),
			date: new FormControl({ value: this.data?.date.getDay(), disabled: true }),
			availability: new FormControl(this.filterForAvailable),
		});

		combineLatest([this.activityService.GetActivities(), this.flexPeriodService.GetAllFlexPeriods()])
			.pipe(
				takeUntil(this.destroy$),
				catchError(() => {
					this.setProcessingState(ProcessingState.Error);
					return EMPTY;
				})
			)
			.subscribe(([activities, periods]) => {
				if (periods.length > 0) {
					this.flexPeriod = periods.find((period) => period.id === this.data?.flexPeriodId);
					if (this.flexPeriod) {
						this.flexOptions = [{ value: this.flexPeriod.id, name: this.flexPeriod.name }];
						this.filters.patchValue({ flexPeriod: this.flexPeriod.id });
					}
				} else {
					this.toastService.openToast({
						title: 'Error encountered with getting flex activities. Please refresh the page and try again',
						type: 'error',
					});
					this.setProcessingState(ProcessingState.Error);
					this.dialogRef.close();
					return;
				}

				this.activities = activities.filter(
					(a) => (a.public_event || a.id === this.data.selectedActivityId) && a.state !== 'deleted' && a.flex_period_id === this.flexPeriod?.id
				);

				if (this.activities.length === 0) {
					this.toastService.openToast({ title: `No activities available for sign-up`, type: 'info' });
					this.setProcessingState(ProcessingState.Loaded);
					this.dialogRef.close();
					return;
				}

				// If activity is deleted, it should not be selected
				if (!this.activities.find((activity) => activity.id === this.data.selectedActivityId)) {
					this.data.selectedActivityId = 0;
				}

				this.refreshInstances();
			});

		this.userService.userJSON$.pipe(takeUntil(this.destroy$)).subscribe((user) => (this.userId = user.id));

		this.filters
			.get('availability')
			.valueChanges.pipe(
				tap((value) => {
					this.filterForAvailable = value === 'true';
					this.getVisibleInstances();
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();

		this.filters
			.get('search')
			.valueChanges.pipe(
				tap(() => this.getVisibleInstances()),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	signUp(instance: SchoolActivityInstance): void {
		if (this.processing !== ProcessingState.Loaded) {
			return;
		}
		this.setProcessingState(ProcessingState.Loading);
		if (this.data.selectedActivityId && !instance.selected) {
			// student is switching activities--remove them from currently selected
			// before signing up for activity
			const selectedInstance = this.visibleInstances.find((vi) => vi.instance.selected).instance;
			selectedInstance.selected = false;
			this.visibleInstances = [];

			this.activityService
				.GetAttendeeRecordForStudent(selectedInstance.id, this.userId, new Date(selectedInstance.start_time))
				.pipe(
					switchMap((attendeeRecord) => {
						return this.activityService.RemoveAttendeeFromActivityInstance(attendeeRecord.id);
					}),
					takeUntil(this.destroy$)
				)
				.subscribe(() => {
					this.signUpForActivity(instance);
				});
		} else {
			this.visibleInstances = [];
			this.signUpForActivity(instance);
		}
	}

	private refreshInstances(): void {
		this.setProcessingState(ProcessingState.Loading);
		this.activityService
			.GetActivityInstancesByPeriodAndFillExtra({
				day: this.data?.date,
				activities: this.activities,
				flexPeriod: this.flexPeriod,
				timezone: this.userService.getUserSchool().timezone,
			})
			.pipe(
				takeUntil(this.destroy$),
				catchError(() => {
					this.toastService.openToast({
						title: 'Issue encountered with getting activities. Please refresh the page and try again',
						type: 'error',
					});
					this.setProcessingState(ProcessingState.Error);

					return EMPTY;
				})
			)
			.subscribe((instances) => {
				this.instances = instances;
				this.getVisibleInstances();
				this.setProcessingState(ProcessingState.Loaded);
			});
	}

	private getVisibleInstances(): void {
		this.setProcessingState(ProcessingState.Loading);

		let instances = this.getInstancesWithActivities();

		if (this.filterForAvailable) {
			instances = this.filterAvailableInstances(instances);
		}

		const searchQuery = this.filters.get('search')?.value?.toLowerCase() ?? '';
		if (searchQuery && searchQuery !== 'search') {
			instances = this.filterInstancesBySearch(instances, searchQuery);
		}

		this.visibleInstances = instances;
		this.setProcessingState(ProcessingState.Loaded);
	}

	private getInstancesWithActivities(): { instance: SchoolActivityInstance; activity: SchoolActivity }[] {
		const activityMap = new Map(this.activities.map((activity) => [activity.id, activity]));

		return this.instances
			.map((instance) => {
				const activity = activityMap.get(instance.activity_id) || null;
				const instanceCopy = { ...instance, selected: this.data.selectedActivityId ? this.data.selectedActivityId === activity?.id : false };

				return activity && { instance: instanceCopy, activity };
			})
			.filter((i) => !!i);
	}

	private filterAvailableInstances(
		instances: { instance: SchoolActivityInstance; activity: SchoolActivity | null }[]
	): { instance: SchoolActivityInstance; activity: SchoolActivity }[] {
		return instances
			.filter((ai) => {
				return ai.activity && (ai.activity.max_attendees > ai.instance.current_num_attendees || ai.activity.max_attendees === 0);
			})
			.filter((i) => !!i);
	}

	private filterInstancesBySearch(
		instances: { instance: SchoolActivityInstance; activity: SchoolActivity | null }[],
		searchQuery: string
	): { instance: SchoolActivityInstance; activity: SchoolActivity }[] {
		return instances
			.filter((ai) => {
				return (
					ai.activity &&
					(ai.activity.name.toLowerCase().includes(searchQuery) ||
						ai.activity.teacher_name.toLowerCase().includes(searchQuery) ||
						ai.instance.selected)
				);
			})
			.filter((i) => !!i);
	}

	private signUpForActivity(instance: SchoolActivityInstance): void {
		this.activityService
			.SignUpForActivity(this.userId, instance.activity_id, new Date(instance.start_time), instance.id)
			.pipe(
				takeUntil(this.destroy$),
				retry(1), // Retry once if am error occurs
				catchError(() => {
					this.toastService.openToast({ title: 'Error encountered while signing up for activity.', type: 'error' });

					return EMPTY;
				}),
				finalize(() => {
					this.refreshInstances();
					this.setProcessingState(ProcessingState.Loaded);
				})
			)
			.subscribe({
				next: () => {
					this.toastService.openToast({ title: 'Signed up for activity!', type: 'success' });
					this.dialogRef.close(instance);
				},
			});
	}

	private setProcessingState(state: ProcessingState): void {
		this.processing = state;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
