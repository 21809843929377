import { Pipe, PipeTransform } from '@angular/core';
import { Button } from '../models/Button';

@Pipe({
	name: 'buttonVisibility',
})
export class ButtonVisibilityPipe implements PipeTransform {
	transform(button: Button, roles: string[]): boolean {
		if (button.hidden) {
			return false;
		}
		let access = false;
		for (const role of button.requiredRoles) {
			access = roles.includes(role);
			if (access) {
				break;
			}
		}
		return access;
	}
}
