import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	BellSchedule,
	BellScheduleGroup,
	Day,
	Holiday,
	PeriodTime,
	Schedule,
	ScheduleForDatesResponse,
	ScheduleGroupBellSchedule,
	ScheduleGroupList,
	UpdateBellScheduleForDateReq,
} from '../../models/Schedule';
import { ScheduleService } from '../../services/schedule.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Util } from '../../../Util';
import { OnboardingStatus } from '../../services/onboarding-schedule.service';
import { DatePipe } from '@angular/common';
import { DYNAMIC_DIALOG_OPTIONS, DynamicDialogAction, DynamicDialogData } from '../../dynamic-dialog-modal/dynamic-dialog-modal.component';
import { filter } from 'rxjs/operators';
import { DynamicDialogService } from '../../dynamic-dialog.service';
import { DialogFactoryService } from '../../dialog-factory.service';
import { SelectedHolidayData } from '../../admin/schedules/holidays/holidays.component';
import { AddHolidayBody, HolidaysService } from '../../services/holidays.service';
import { ToastService } from '../../services/toast.service';
import { SelectedDate } from '../../admin/schedules/holidays/add-edit-holidays/add-edit-holidays.component';

@Component({
	selector: 'sp-set-schedule',
	templateUrl: './set-schedule.component.html',
	styleUrls: ['./set-schedule.component.scss'],
})
export class SetScheduleComponent implements OnInit, OnChanges, AfterViewInit {
	@ViewChild('scrollContainer', { read: ElementRef, static: false }) scrollContainer: ElementRef;
	@ViewChild('dialogContent', { static: true }) dialogContent!: ElementRef;
	@ViewChild('addHolidayModalBody', { read: TemplateRef, static: false }) addHolidayModalBody: TemplateRef<any>;

	@Input() schedule: Schedule;
	@Input() allSchedules: ScheduleForDatesResponse;
	@Input() scheduleData: ScheduleGroupList[] = [];
	@Input() scheduleOnboardingStatus: OnboardingStatus;
	@Input() date: string; // should be in format 'YYYY-MM-DD'
	@Input() dateOfSetSchedule: string; // should be in format 'YYYY-MM-DD'
	@Input() holidaysDateObjects: { start_date: string; end_date: string }[] = [];
	@Input() isDeletingHoliday = false;
	@Input() readOnly = false;
	@Input() customClass: string;

	// if this exists we will use it to display the data for this scheduleID,
	// otherwise we'll default to the first schedule in the array
	@Input() selectedScheduleGroupId: number;
	@Input() selectedBellScheduleId: number;
	@Input() selectedTabIndex = 0;

	// this emits to update button state in dynamic dialog modal
	@Output() onChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() selectedData: EventEmitter<UpdateBellScheduleForDateReq> = new EventEmitter<UpdateBellScheduleForDateReq>();
	@Output() selectScheduleGroup: EventEmitter<number> = new EventEmitter<number>();
	@Output() holidayUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
	// this displays in the left pane
	selectedScheduleGroup: ScheduleGroupList;
	// this used to know what BellScheduleGroup is selected
	selectedBellScheduleGroup: BellScheduleGroup;
	// this displays periods groupings in the right pane based on what BellScheduleGroup is selected
	selectedBellSchedule: BellSchedule;
	dataToSave: ScheduleGroupBellSchedule[] = [];
	nextDayTypeInRotation: string;
	nextDateInRotation: string;
	initiallySelectedBellScheduleId: number;
	displayAddHolidayPane = false;
	private scheduleForDate: Day;
	private bellScheduleGroups: BellScheduleGroup[];
	private bellSchedules: BellSchedule[];
	private isRotating = false;
	private initiallySelected: HTMLElement;
	private addHolidayService: DynamicDialogService;
	selectedHolidayData: SelectedHolidayData;
	selectedHolidayDate: SelectedDate;
	isAddingHoliday = false;

	constructor(
		private scheduleService: ScheduleService,
		private datePipe: DatePipe,
		private dialogFactoryService: DialogFactoryService,
		private holidayService: HolidaysService,
		private matDialog: MatDialog,
		private toast: ToastService
	) {}

	ngOnInit(): void {
		if (Object.keys(this.allSchedules).length && this.date && this.scheduleData) {
			this.updateSelectedData();
		}
		// this data will not actually be saved, in these cases it's just populated to display
		// the default day type/bell schedule ID as selected for each schedule group
		if (this.scheduleOnboardingStatus === 'in review' || this.scheduleOnboardingStatus === 'pending') {
			this.dataToSave = this.scheduleData.map((sg) => {
				return {
					id: sg.id,
					bell_schedule_id: sg.bell_schedule_groups[0].bell_schedules[0].id,
				};
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.allSchedules?.currentValue && changes.date?.currentValue && changes.scheduleData?.currentValue) {
			this.initiallySelectedBellScheduleId = cloneDeep(this.selectedBellScheduleId);
			this.updateDisplay(this.selectedBellScheduleId);
			this.setDefaultSelected(this.date);
			this.onSelectDayType(this.selectedBellScheduleId);
			this.isRotating = this.scheduleData[this.selectedTabIndex].rotation_type === 'rotating';
		}
	}
	ngAfterViewInit(): void {
		this.initiallySelected = document.getElementById('initially-selected');
		if (this.initiallySelected) {
			this.initiallySelected.scrollIntoView();
		}
	}

	onSelectSchedule(selectedIndex: number): void {
		this.selectedScheduleGroupId = this.scheduleData[selectedIndex].id;
		this.selectScheduleGroup.emit(this.selectedScheduleGroupId);
		const selectedBellScheduleId = this.dataToSave[selectedIndex].bell_schedule_id;
		this.initiallySelectedBellScheduleId = cloneDeep(selectedBellScheduleId);
		this.updateDisplay(selectedBellScheduleId);
	}

	onSelectDayType(bellScheduleId: number): void {
		this.displayAddHolidayPane = false;
		this.onChanges.emit(this.selectedBellScheduleId == bellScheduleId ? false : true);
		this.updateDisplay(bellScheduleId);
		const alreadyExists = this.dataToSave.find((d) => d.id === this.selectedScheduleGroup.id);
		if (alreadyExists) {
			alreadyExists.bell_schedule_id = this.selectedBellSchedule.id;
		} else {
			const scheduleGroups = {
				id: this.selectedScheduleGroup.id,
				bell_schedule_id: this.selectedBellSchedule.id,
			};
			this.dataToSave.push(scheduleGroups);
		}

		const data: UpdateBellScheduleForDateReq = {
			date: this.date,
			schedule_id: this.schedule.id,
			schedule_groups: this.dataToSave,
		};
		if (this.isRotating) {
			this.nextDayTypeInRotation = this.getNextDayInRotation(this.selectedBellSchedule.id);
			this.nextDateInRotation = Util.findNextAvailableWeekdayAfterHoliday(this.dateOfSetSchedule ?? this.date, this.holidaysDateObjects);

			const days = Object.keys(this.allSchedules.days);
			const lastDayOfWeek = days[days.length - 1];
			data.return_end_date = lastDayOfWeek;
		}

		this.selectedData.emit(data);
	}

	private updateSelectedData(): void {
		const alreadyExists = this.dataToSave.find((d) => d.id === this.selectedScheduleGroup.id);
		if (alreadyExists) {
			alreadyExists.bell_schedule_id = this.selectedBellSchedule.id;
		} else {
			if (this.scheduleOnboardingStatus === 'active') {
				this.dataToSave = this.scheduleData.map((sg) => {
					return {
						id: sg.id,
						bell_schedule_id: sg.bell_schedule_groups[0].bell_schedules[0].id,
					};
				});
			}
		}

		const data: UpdateBellScheduleForDateReq = {
			date: this.date,
			schedule_id: this.schedule.id,
			schedule_groups: this.dataToSave,
		};
		if (this.isRotating) {
			this.nextDayTypeInRotation = this.getNextDayInRotation(this.selectedBellSchedule.id);
			this.nextDateInRotation = Util.findNextAvailableWeekdayAfterHoliday(this.dateOfSetSchedule ?? this.date, this.holidaysDateObjects);

			const days = Object.keys(this.allSchedules.days);
			const lastDayOfWeek = days[days.length - 1];
			data.return_end_date = lastDayOfWeek;
		}

		this.selectedData.emit(data);
	}

	selectHolidayDisplay(): void {
		this.displayAddHolidayPane = true;
		this.onChanges.emit(false);
	}
	onOpenHolidayModal(): void {
		this.isAddingHoliday = true;
		this.selectedHolidayData = {
			type: 'add',
			holiday: {
				name: '',
				start_date: this.date,
				end_date: this.date,
			},
		};
		this.selectedHolidayDate = {
			start: this.date,
			end: this.date,
		};
		const data: DynamicDialogData = {
			headerText: 'Add Holiday',
			showCloseIcon: true,
			primaryButtonLabel: 'Save',
			secondaryButtonLabel: 'Cancel',
			modalBody: this.addHolidayModalBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			disablePrimaryButton: true,
		};
		this.addHolidayService = this.dialogFactoryService.open(data, DYNAMIC_DIALOG_OPTIONS);
		this.addHolidayService.closed$.pipe(filter(Boolean)).subscribe({
			next: (selectedOption: DynamicDialogAction | undefined) => {
				if (selectedOption === 'primary') {
					this.addHoliday();
				} else {
					this.isAddingHoliday = false;
				}
			},
		});
	}

	private addHoliday(): void {
		const addHolidayBody: AddHolidayBody = {
			name: this.selectedHolidayData.holiday.name,
			start_date: new Date(this.selectedHolidayData.holiday.start_date),
			end_date: new Date(this.selectedHolidayData.holiday.end_date),
			return_date: new Date(this.selectedHolidayData.holiday.start_date),
			schedule_id: this.schedule.id,
		};
		this.holidayService.addHoliday(addHolidayBody).subscribe({
			next: (response: ScheduleForDatesResponse) => {
				this.holidayUpdated.emit(true);
				this.matDialog.closeAll();
				const currentlySelectedDate = this.scheduleService.selectedBellScheduleEditDate$.getValue();
				if (!Object.keys(response.days).includes(currentlySelectedDate.format('YYYY-MM-DD'))) {
					this.scheduleService.selectedBellScheduleEditDate$.next(moment.utc(this.selectedHolidayData.holiday.start_date));
				}
				this.scheduleService.schedulesForEditing$.next(response);
				this.toast.openToast({ title: 'Holiday added', type: 'success' });
				this.isAddingHoliday = false;
			},
		});
	}
	private updateDisplay(bellScheduleId?: number): void {
		// if we should display a certain schedule, find it in the data
		if (this.selectedScheduleGroupId) {
			this.selectedScheduleGroup = this.scheduleData.find((sd) => sd.id === this.selectedScheduleGroupId);
		} else {
			// otherwise default to the first in the array
			this.selectedScheduleGroupId = this.scheduleData[0].id;
			this.selectedScheduleGroup = this.scheduleData[0];
		}

		this.bellScheduleGroups = this.selectedScheduleGroup.bell_schedule_groups;
		// select the first group by default
		this.selectedBellScheduleGroup = this.bellScheduleGroups[0];
		// otherwise select the one we have a bellScheduleId for
		if (bellScheduleId) {
			this.selectedBellScheduleGroup = this.bellScheduleGroups.find((bsg) => {
				if (bsg.bell_schedules.find((bs) => bs.id === bellScheduleId)) {
					return bsg;
				}
			});
		}
		this.bellSchedules = this.selectedBellScheduleGroup.bell_schedules;

		// select the first bell_schedule by default
		this.selectedBellSchedule = this.bellSchedules[0];
		// otherwise select the one we have an id for
		if (bellScheduleId) {
			this.selectedBellSchedule = this.bellSchedules.find((bs) => bs.id === bellScheduleId);
		}
	}

	private setDefaultSelected(date: string): void {
		this.scheduleForDate = this.scheduleService.getScheduleForDate(moment(date).toDate(), this.allSchedules);
		if (this.scheduleForDate?.schedule_groups) {
			for (const s of this.scheduleForDate.schedule_groups) {
				const scheduleGroups = {
					id: s.id,
					bell_schedule_id: s.bell_schedule_id,
				};
				this.dataToSave.push(scheduleGroups);
			}
		}
	}

	private getNextDayInRotation(selectedBellScheduleId: number): string | undefined {
		let nextBSId: number;
		for (const bsg of this.bellScheduleGroups) {
			const selected = bsg.bell_schedules.find((bs) => bs.id === selectedBellScheduleId);
			if (selected) {
				nextBSId = selected.next_regular_bs_id;
				break;
			}
		}
		let longName: string;
		for (const bsg of this.bellScheduleGroups) {
			const next = bsg.bell_schedules.find((bs) => bs.id === nextBSId);
			if (next) {
				longName = next.long_name;
				break;
			}
		}
		return longName;
	}

	private getAssignable(periods: PeriodTime[]): PeriodTime[] {
		return periods.filter((p) => p.is_assignable);
	}
}
