import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Router } from '@angular/router';
declare const window;
export type MenuDialogData = {
	items: MenuDialogItem[];
};
export type MenuDialogItem = {
	id: string;
	text: string;
	selected?: boolean;
	default?: boolean;
	iconLeftPath?: string;
	iconLeftPathHighlighted?: string;
	iconRightPath?: string;
	internalUrlPath?: string;
	externalUrlPath?: string;
	intercomAction?: string;
	hasDivider?: boolean;
};
export type MenuDialogOptions = {
	panelClass: string;
	disableClose: boolean;
	autoFocus?: boolean;
	position?: { top: string; left: string };
};
export const MENU_DIALOG_OPTIONS: MenuDialogOptions = {
	panelClass: 'dynamic-dialog-modal',
	disableClose: false,
};
@Component({
	selector: 'sp-menu-dialog',
	templateUrl: './menu-dialog.component.html',
	styleUrls: ['./menu-dialog.component.scss'],
})
export class MenuDialogComponent {
	items: MenuDialogItem[];

	triggerElementRef: HTMLElement;

	constructor(@Inject(MAT_DIALOG_DATA) public data: MenuDialogData, public dialogRef: MatDialogRef<MenuDialogComponent>, private router: Router) {
		this.items = this.data.items;
	}

	onClick(item: MenuDialogItem): void {
		if (item.intercomAction) {
			window.Intercom('showNewMessage');
		} else if (item.externalUrlPath) {
			window.open(item.externalUrlPath, '_blank');
		} else if (item.internalUrlPath) {
			this.router.navigateByUrl(item.internalUrlPath);
		}
		this.dialogRef.close(item);
	}
}
