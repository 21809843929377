import { MatDialogRef } from '@angular/material/dialog';

// Components
import {
	DynamicDialogAction,
	DynamicDialogData,
	DynamicDialogDataWithWizard,
	DynamicDialogModalComponent,
} from './dynamic-dialog-modal/dynamic-dialog-modal.component';

type DialogRef<T> = MatDialogRef<DynamicDialogModalComponent>;

export class DynamicDialogService<T = undefined> {
	opened$ = this.dialogRef.afterOpened();
	closed$ = this.dialogRef.afterClosed();

	constructor(public dialogRef: DialogRef<T>) {}

	close(action: DynamicDialogAction): void {
		this.dialogRef.close(action);
	}

	hasOpenDialog(): boolean {
		return !!this.dialogRef?.componentInstance;
	}

	// Use this to update the display of the modal elements.
	setDialogConfig(data: Partial<DynamicDialogData | DynamicDialogDataWithWizard>): void {
		this.dialogRef.componentInstance.data = {
			...this.dialogRef.componentInstance.data,
			...data,
		};
	}
}
