import { Component, Input } from '@angular/core';
import { PassLike } from '../models';
import { WaitingInLinePass } from 'app/models/WaitInLine';
import { WaitInLineFavicon } from 'app/pass-cards/wait-in-line-card/wait-in-line-card.component';
import { WaitInLineService } from '../services/wait-in-line.service';

@Component({
	selector: 'sp-student-pass-info',
	templateUrl: './student-pass-info.component.html',
	styleUrls: ['./student-pass-info.component.scss'],
})
export class StudentPassInfoComponent {
	@Input() pass: PassLike;
	@Input() passType: string;

	acceptingPassTimeRemaining: number;

	constructor(private wilService: WaitInLineService) {}

	get remainingAttemptSeconds(): number {
		return this.wilService.remainingAttemptSeconds(this.pass as WaitingInLinePass);
	}

	private changeTitle(tabTitle: string): void {
		return this.wilService.setTabTitleForWilp(tabTitle);
	}

	private changeFavicon(assetLink: string): void {
		this.wilService.changeFaviconForWilp(assetLink);
	}

	readyToStartTick(remainingTime: number): void {
		this.acceptingPassTimeRemaining = remainingTime;
		if (remainingTime > 27) {
			this.changeTitle('Time to Start Your Pass');
			this.changeFavicon(WaitInLineFavicon.FrontOfLine);
			setTimeout(() => {
				this.changeFavicon(WaitInLineFavicon.Transparent);
			}, 400);
			return;
		}

		if (remainingTime === 0 && this.pass instanceof WaitingInLinePass && this.pass.missed_start_attempts > 0) {
			this.changeFavicon('./assets/icons/favicon.ico');
			return;
		}

		if (remainingTime % 2 === 0) {
			this.changeTitle(`${remainingTime} sec left...`);
			this.changeFavicon(WaitInLineFavicon.HourglassToggle1);
		} else {
			this.changeTitle(`${remainingTime} sec left...`);
			this.changeFavicon(WaitInLineFavicon.HourglassToggle2);
		}
	}
}
