import { ComponentType } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { DialogFactoryService } from 'app/dialog-factory.service';
import { DynamicDialogData } from 'app/dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DynamicDialogService } from 'app/dynamic-dialog.service';
import { FrequencyType } from 'app/models/HallPassLimits';
import { OverrideEncounterPreventionComponent } from 'app/override-encounter-prevention/override-encounter-prevention.component';
import { ConfirmationTemplates } from 'app/shared/shared-components/confirmation-dialog/confirmation-dialog.component';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject, zip } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { InvitationCardComponent } from '../invitation-card/invitation-card.component';
import { createHallPassSortFns, filterPasses } from '../live-data/filters';
import { constructUrl } from '../live-data/helpers';
import { PassLike } from '../models';
import { PreventEncounters } from '../models/ExclusionGroup';
import { HallPass, HallPassStatus } from '../models/HallPass';
import { Invitation } from '../models/Invitation';
import { Location } from '../models/Location';
import { PassFilters } from '../models/PassFilters';
import { Pinnable } from '../models/Pinnable';
import { Request } from '../models/Request';
import { ROLES, User } from '../models/User';
import { WaitingInLinePass, WaitingInLinePassResponse } from '../models/WaitInLine';
import { AppState } from '../ngrx/app-state/app-state';
import { getPassFilter, updatePassFilter } from '../ngrx/pass-filters/actions';
import { getFiltersData, getFiltersDataLoading } from '../ngrx/pass-filters/states';
import { filterExpiredPasses } from '../ngrx/pass-like-collection/nested-states/expired-passes/actions';
import { getLastAddedExpiredPasses } from '../ngrx/pass-like-collection/nested-states/expired-passes/states';
import { getInvitationsCollection } from '../ngrx/pass-like-collection/nested-states/invitations/states/invitations-getters.states';
import { getPassStats } from '../ngrx/pass-stats/actions';
import { getPassStatsResult } from '../ngrx/pass-stats/state/pass-stats-getters.state';
import {
	changePassesCollectionAction,
	endPassAction,
	getMorePasses,
	getMorePassesLoading,
	getPassesCollection,
	getPassesEntities,
	getPassesLoaded,
	getPassesLoading,
	getPassesNextUrl,
	getPassesTotalCount,
	getSortPassesLoading,
	getSortPassesValue,
	getStartPassLoading,
	getTotalPasses,
	searchPasses,
	sortPasses,
} from '../ngrx/passes';
import { arrangedPinnable, getPinnables, postPinnables, removePinnable, updatePinnable } from '../ngrx/pinnables/actions';
import {
	getArrangedLoading,
	getCurrentPinnable,
	getIsLoadedPinnables,
	getIsLoadingPinnables,
	getPinnableCollection,
	getPinnablesIds,
} from '../ngrx/pinnables/states';
import { getPreviewPasses } from '../ngrx/quick-preview-passes/actions';
import {
	getQuickPreviewPassesCollection,
	getQuickPreviewPassesLoaded,
	getQuickPreviewPassesLoading,
	getQuickPreviewPassesStats,
} from '../ngrx/quick-preview-passes/states';
import { openToastAction } from '../ngrx/toast/actions';
import { PassCardComponent } from '../pass-card/pass-card.component';
import { WaitInLineCardComponent } from '../pass-cards/wait-in-line-card/wait-in-line-card.component';
import { RequestCardComponent } from '../request-card/request-card.component';
import { HallPassResultType } from '../student-info-card/student-info-card.component';
import { EncounterPreventionService, ExclusionGroupWithOverrides } from './encounter-prevention.service';
import { FeatureFlagService, FLAGS } from './feature-flag.service';
import { HttpService } from './http-service';
import { PollingEvent, PollingService } from './polling-service';
import { TimeService } from './time.service';

interface EncounterPreventionToast {
	exclusionPass: PassLike;
	isStaff?: boolean;
	exclusionGroups?: ExclusionGroupWithOverrides[];
	preventedEncounters?: PreventEncounters[];
	isKioskMode?: boolean;
	conflictStudentIds?: number[];
	conflictPasses?: HallPass[];
}

interface HallPassError {
	Code: string;
	Message: string;
}

// error codes that are used locally on the UI
export enum HallPassErrors {
	Encounter = 'ENCOUNTER PREVENTION',
}

export interface BulkHallPassPostResponse {
	passes: HallPass[];
	conflict_student_ids: number[];
	waiting_in_line_passes: WaitingInLinePassResponse[];
	error: HallPassError;
}

export interface StartWaitingInLinePassResponse {
	pass: HallPass;
	conflict_student_ids: number[];
	conflict_passes: HallPass[];
	prevented_encounters: PreventEncounters[];
}

export interface CheckPinnableName {
	title_used: boolean;
}

export interface OverrideEPData {
	conflictPasses?: HallPass[];
	preventedEncounters?: PreventEncounters[];
	passRequest: OverridePassRequest;
	selectedStudents: User[];
	conflictStudentIds: number[];
	createdPasses?: HallPass[];
	teacherLocation: Location;
	waitInLinePassId?: number;
}

export interface OverridePassRequest {
	duration: number;
	origin: number;
	destination: number;
	travel_type: string;
	override_encounter_ids?: number[];
	override?: boolean;
	students?: number[];
}

interface HandleEncounterPreventionParams {
	conflictPasses: HallPass[];
	preventedEncounters: PreventEncounters[];
	conflictStudentIds: string[];
	passes: HallPass[];
	body: OverridePassRequest;
	selectedTravelType: string;
	selectedStudents: User[];
	selectedLocation: Location;
	forStaff: boolean;
	attemptedPass: PassLike;
	currentUser: User;
	onClose?: () => void;
	waitInLinePassId?: number;
}

type ActiveHallPassConfig = {
	sort$?: Observable<string>;
	filter$?: Observable<string>;
};
export const ROOM_DELETION_ERROR = 'Room deletion error:';

// pass modals were designed in figma based off a 16" laptop screen, which is 1117 px high
// these values are used to calculate scale if the window isn't that tall
export const MODAL_HEIGHT = 796;
export const MODAL_MAX_HEIGHT = 988;
export const MODAL_MIN_WIDTH_DESKTOP = 686;
export const WINDOW_HEIGHT_AS_DESIGNED = 1117;
export const PASS_CARD_FOOTER_HEIGHT = 51;
export const METRICS_FOOTER_HEIGHT = 117;
export const STUDENT_INFO_FOOTER_HEIGHT = 109;
export const DEFAULT_ROOM_ICON = 'https://cdn.smartpass.app/icons8/ask-question/FFFFFF';
export const DEFAULT_ROOM_ICON_BG_COLOR = '#E32C66';

@Injectable({
	providedIn: 'root',
})
export class HallPassesService {
	pinnables$: Observable<Pinnable[]> = this.store.select(getPinnableCollection);
	loadedPinnables$: Observable<boolean> = this.store.select(getIsLoadedPinnables);
	isLoadingPinnables$: Observable<boolean> = this.store.select(getIsLoadingPinnables);
	pinnablesCollectionIds$: Observable<number[] | string[]> = this.store.select(getPinnablesIds);
	isLoadingArranged$: Observable<boolean> = this.store.select(getArrangedLoading);

	passesEntities$: Observable<{ [id: number]: HallPass }> = this.store.select(getPassesEntities);
	passesCollection$: Observable<HallPass[]> = this.store.select(getPassesCollection);
	passesLoaded$: Observable<boolean> = this.store.select(getPassesLoaded);
	passesLoading$: Observable<boolean> = this.store.select(getPassesLoading);
	moreLoading$: Observable<boolean> = this.store.select(getMorePassesLoading);
	sortPassesLoading$: Observable<boolean> = this.store.select(getSortPassesLoading);
	sortPassesValue$: Observable<string> = this.store.select(getSortPassesValue);
	currentPassesCount$: Observable<number> = this.store.select(getPassesTotalCount);
	currentCountPassesInPage$: Observable<number> = this.store.select(getTotalPasses);
	startPassLoading$: Observable<boolean> = this.store.select(getStartPassLoading);

	passFilters$: Observable<Record<string, PassFilters>> = this.store.select(getFiltersData);
	passFiltersLoading$: Observable<boolean> = this.store.select(getFiltersDataLoading);

	passesNextUrl$: Observable<string> = this.store.select(getPassesNextUrl);

	expiredPassesNextUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	lastAddedExpiredPasses$: Observable<HallPass[]> = this.store.select(getLastAddedExpiredPasses);

	invitations$: Observable<Invitation[]> = this.store.select(getInvitationsCollection);

	quickPreviewPasses$: Observable<HallPass[]> = this.store.select(getQuickPreviewPassesCollection);
	quickPreviewPassesStats$: Observable<any> = this.store.select(getQuickPreviewPassesStats);
	quickPreviewPassesLoading$: Observable<boolean> = this.store.select(getQuickPreviewPassesLoading);
	quickPreviewPassesLoaded$: Observable<boolean> = this.store.select(getQuickPreviewPassesLoaded);

	currentPinnable$: Observable<Pinnable> = this.store.select(getCurrentPinnable);
	passStats$ = this.store.select(getPassStatsResult);

	isOpenPassModal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	createPassEvent$: Subject<any> = new Subject<any>();
	pinnables: Pinnable[];

	preselectedStudent$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
	private dialogService: DynamicDialogService;

	constructor(
		private http: HttpService,
		private store: Store<AppState>,
		private pollingService: PollingService,
		private timeService: TimeService,
		public dialog: MatDialog,
		private dialogFactoryService: DialogFactoryService,
		public sanitizer: DomSanitizer,
		private featureFlagService: FeatureFlagService,
		private encounterPreventionService: EncounterPreventionService
	) {
		this.pinnables$.subscribe((pinnables) => {
			this.pinnables = pinnables;
		});
	}

	getActivePasses() {
		return this.http.get<HallPass[]>('v1/hall_passes?active=true');
	}

	getActivePassesKioskMode(locId) {
		return this.http.get<HallPass[]>(`v1/hall_passes?active=true&location=${locId}`);
	}

	createPass(data, future = false) {
		return this.http.post(`v1/hall_passes`, data);
	}

	// response is a Partial since depending on when the route is called, the backend can
	// return at least one of the possible keys
	bulkCreatePass(data, future = false): Observable<Partial<BulkHallPassPostResponse>> {
		return this.http.post(`v1/hall_passes`, data);
	}

	hidePasses(data) {
		return this.http.patch('v1/hall_passes/hide', data);
	}

	cancelPass(id, data) {
		return this.http.post(`v1/hall_passes/${id}/cancel`, data);
	}

	getExploJWT() {
		return this.http.post(`v2/explo/generate_explo_jwt`, {}, undefined, false);
	}

	endPassRequest(passId, isDeletingRecurringPass = false) {
		return this.store.dispatch(endPassAction({ passId, isDeletingRecurringPass }));
	}

	endPass(id, isDeletingRecurringPass = false) {
		return this.http.post(`v1/hall_passes/${id}/ended?is_deleting_recurring_pass=${isDeletingRecurringPass}`);
	}

	endPassWithCheckIn(id, data) {
		return this.http.post(`v1/hall_passes/${id}/ended`, data);
	}

	getPassStatsRequest() {
		this.store.dispatch(getPassStats());
		return this.passStats$;
	}

	getPassStats() {
		return this.http.get('v1/hall_passes/stats');
	}

	getExpiredPasses(limit: number, end_time_after: string, end_time_before: string, student: string | number): Observable<HallPassResultType> {
		return this.http.get(
			`v1/hall_passes?limit=${limit}&active=past&student=${student}&model_filter=past-passes&end_time_after=${end_time_after}&end_time_before=${end_time_before}`
		);
	}

	getExpiredPassesWithNext(queryString: string): Observable<HallPassResultType> {
		return this.http.get(`v1/hall_passes?${queryString}`);
	}

	getPinnables(): Observable<Pinnable[]> {
		return this.http.get('v1/pinnables/arranged');
	}

	getPinnablesRequestV2(): void {
		this.store.dispatch(getPinnables());
	}

	getPinnablesRequest() {
		this.store.dispatch(getPinnables());
		return this.pinnables$;
	}

	getPinnable(location: Location): Observable<Pinnable> {
		return this.pinnables$.pipe(
			map(
				(pins) => this.findPin(pins, location),
				filter((v) => !!v)
			)
		);
	}

	postPinnableRequest(data) {
		this.store.dispatch(postPinnables({ data }));
		return this.store.select(getCurrentPinnable).pipe(
			filter((p) => {
				if (!p) {
					return true;
				}
				return data.title === p.title;
			})
		);
	}

	createPinnable(data) {
		return this.http.post('v1/pinnables', data);
	}

	updatePinnableRequest(id, pinnable): Observable<Pinnable[]> {
		this.store.dispatch(updatePinnable({ id, pinnable }));
		return this.pinnables$;
	}

	updatePinnable(id, data) {
		return this.http.patch(`v1/pinnables/${id}`, data);
	}

	deletePinnableRequest(id, add_to_folder = false, success_message = 'Room deleted'): void {
		this.store.dispatch(removePinnable({ id, add_to_folder, success_message } as any));
	}

	deletePinnable(id, add_to_folder = false) {
		return this.http.delete(`v1/pinnables/${id}?add_to_folder=${add_to_folder}`, {
			headers: { 'X-Ignore-Errors': 'pass-through' }, // this allows the error to be handled by ngrx effects rather than the progress interceptor
		});
	}

	checkPinnableName(value): Observable<CheckPinnableName> {
		return this.http.get(`v1/pinnables/check_fields?title=${value}`);
	}

	getArrangedPinnables() {
		return this.http.get('v1/pinnables?arranged=true');
	}

	createArrangedPinnableRequest(order) {
		this.store.dispatch(arrangedPinnable({ order }));
		return of(null);
	}

	createArrangedPinnable(body) {
		return this.http.post(`v1/pinnables/arranged`, body);
	}

	searchPassesRequest(url: string) {
		this.store.dispatch(searchPasses({ url }));
	}

	searchPasses(url) {
		return this.http.get(url);
	}

	getMorePasses() {
		this.store.dispatch(getMorePasses());
	}

	sortHallPassesRequest(queryParams) {
		this.store.dispatch(sortPasses({ queryParams }));
	}

	sortHallPasses(queryParams) {
		return this.http.get(constructUrl('v1/hall_passes', queryParams));
	}

	startPushNotification() {
		return this.http.post('v1/users/@me/test_push_message', new Date());
	}

	watchMessageAlert() {
		return this.pollingService.listenOnCurrentSchool('message.alert');
	}

	watchAllEndingPasses() {
		return this.pollingService.listenOnCurrentSchool('hall_pass.end');
	}

	watchAllEndedPassesForLocations(locationIds: number[]): Observable<HallPass> {
		return this.filterHallPassEventForLocationIds(locationIds, this.pollingService.listen('hall_pass.end'));
	}

	// watchStartPass listens for hall pass start events for a specific hall pass with the given |id|.
	watchStartPass(id: number): Observable<HallPass> {
		return this.filterHallPassEvent(id, this.pollingService.listenOnCurrentSchool('hall_pass.start'));
	}

	// watchEndPass listens for hall pass end events for a specific hall pass with the given |id|.
	watchEndPass(id: number) {
		return this.filterHallPassEvent(id, this.pollingService.listenOnCurrentSchool('hall_pass.end'));
	}

	// watchCancelPass listens for hall pass start events for a specific hall pass with the given |id|.
	watchCancelPass(id: number) {
		return this.filterHallPassEvent(id, this.pollingService.listenOnCurrentSchool('hall_pass.cancel'));
	}

	watchPassBecomingActiveForStudent(studentId: number): Observable<HallPass | HallPass[]> {
		return this.pollingService.isConnected$.pipe(
			filter(Boolean),
			distinctUntilChanged(),
			switchMap(() => {
				return this.pollingService.listen('hall_pass.start').pipe(
					filter((pe) => !!pe),
					map((pe) => pe.data),
					map((data) => {
						if (Array.isArray(data)) {
							return data.map((hp) => HallPass.fromJSON(hp));
						} else {
							return HallPass.fromJSON(data);
						}
					}),
					filter((hp) => {
						if (Array.isArray(hp)) {
							return hp.filter((p) => p.student.id === studentId && !p.activity_instance_id).length > 0;
						} else {
							return hp.student.id === studentId && !hp.activity_instance_id;
						}
					}),
					catchError((e, originalObs) => {
						console.log('Error in watchPassBecomingActiveForStudent', e);
						return originalObs;
					})
				);
			})
		);
	}

	filterHallPassEvent(id: number, events: Observable<PollingEvent>): Observable<HallPass> {
		return events.pipe(
			map((e) => HallPass.fromJSON(e.data)),
			filter((p) => p.id == id)
		);
	}

	private filterHallPassEventForLocationIds(locationIds: number[], events: Observable<PollingEvent>): Observable<HallPass> {
		return events.pipe(
			map((e) => HallPass.fromJSON(e.data)),
			filter((p) => locationIds.includes(p.destination.id) || locationIds.includes(p.origin.id))
		);
	}

	getFiltersRequest(model: string) {
		this.store.dispatch(getPassFilter({ model }));
	}

	getFilters(model: string) {
		return this.http.get(`v1/filters/${model}`);
	}

	updateFilterRequest(model, value) {
		this.store.dispatch(updatePassFilter({ model, value }));
	}

	updateFilter(model: string, value: string) {
		return this.http.patch(`v1/filters/${model}`, { default_time_filter: value });
	}

	filterExpiredPassesRequest(user, timeFilter) {
		this.store.dispatch(filterExpiredPasses({ user, timeFilter }));
	}

	getQuickPreviewPassesRequest(userId, pastPasses) {
		this.store.dispatch(getPreviewPasses({ userId, pastPasses }));
	}

	getQuickPreviewPasses(userId, pastPasses) {
		return this.http.get(`v1/users/${userId}/hall_pass_stats?recent_past_passes=${pastPasses}&limit=50`);
	}

	changePassesCollection(passIds: number[]) {
		this.store.dispatch(changePassesCollectionAction({ passIds }));
	}

	handlePassLimitError({
		studentIds,
		frequency,
		studentName,
		confirmDialog,
		errors,
	}: {
		studentIds: number[];
		frequency?: FrequencyType;
		studentName: string;
		confirmDialog: TemplateRef<HTMLElement>;
		errors: Observable<HttpErrorResponse>;
	}): Observable<{ override: boolean; students: number[] }> {
		return errors.pipe(
			tap((errorResponse: HttpErrorResponse) => {
				if (errorResponse.error?.message !== 'one or more pass limits reached!') {
					throw errorResponse;
				}
			}),
			concatMap((errorResponse) => {
				const students = errorResponse.error.students as { displayName: string; id: number; passLimit: number }[];
				const numPasses = studentIds?.length || 1;
				let headerText: string;
				let buttons: ConfirmationTemplates['buttons'];
				if (numPasses > 1) {
					headerText = `Creating these ${numPasses} passes will exceed the Pass Limits for the following students:`;
					buttons = {
						confirmText: 'Override Limits',
						denyText: 'Skip These Students',
					};
				} else if (numPasses === 1) {
					const { passLimit } = students[0];
					const frequencyText = frequency === 'day' ? 'today' : 'this ' + frequency;
					headerText = `Student's Pass limit reached: ${studentName} has had ${passLimit}/${passLimit} passes ${frequencyText}`;
					buttons = {
						confirmText: 'Override Limit',
						denyText: 'Cancel',
					};
				}

				const data: DynamicDialogData = {
					headerText: headerText,
					showCloseIcon: true,
					primaryButtonLabel: buttons.confirmText,
					secondaryButtonLabel: buttons.denyText,
					modalBody: confirmDialog,
					secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
					secondaryButtonTextColor: '#7083A0',
					primaryButtonGradientBackground: '#E32C66',
					primaryButtonTextColor: 'white',
					classes: 'tw-min-h-0',
					templateData: {
						totalStudents: numPasses,
						limitReachedStudents: students,
					},
					icon: {
						name: 'Pass Limit (White).svg',
						background: '#6651F1',
						spacing: '16px',
					},
				};

				this.dialogService = this.dialogFactoryService.open(data, {
					panelClass: 'dynamic-dialog-modal-min',
					disableClose: false,
				});
				return this.dialogService.closed$.pipe(
					map((override) => {
						return { override: override === 'primary', students: errorResponse.error.students.map((s) => s.id) };
					})
				);
			})
		);
	}

	handleEncounterPrevention({
		conflictPasses,
		preventedEncounters,
		conflictStudentIds,
		passes,
		body,
		selectedTravelType,
		selectedStudents,
		selectedLocation,
		forStaff,
		attemptedPass,
		currentUser,
		onClose,
		waitInLinePassId = null,
	}: HandleEncounterPreventionParams): Observable<unknown> {
		conflictPasses = conflictPasses?.map((cp) => HallPass.fromJSON(cp));
		passes = passes?.map((p) => HallPass.fromJSON(p));
		attemptedPass = HallPass.fromJSON(attemptedPass);
		selectedStudents = selectedStudents.map((s) => User.fromJSON(s));
		currentUser = User.fromJSON(currentUser);
		selectedLocation = Location.fromJSON(selectedLocation);

		// Student notification
		if (!forStaff) {
			const hallPass = {
				...attemptedPass,
				travel_type: selectedTravelType,
			};
			this.showEncounterPreventionToast({
				exclusionPass: HallPass.fromJSON(hallPass),
				isStaff: forStaff,
			});
			if (onClose) {
				onClose();
			}
			return of(null);
		}
		// Override Encounter Modal
		else if (
			this.featureFlagService.isFeatureEnabledV2(FLAGS.EncounterPreventionOverride) &&
			currentUser.roles.includes(ROLES.OverriderEncounter) &&
			((conflictPasses && preventedEncounters) || conflictStudentIds?.length + (passes?.length ?? 0) === selectedStudents?.length)
		) {
			const dialogData: OverrideEPData = {
				conflictPasses: conflictPasses,
				preventedEncounters: preventedEncounters,
				passRequest: body,
				createdPasses: passes,
				selectedStudents: selectedStudents,
				conflictStudentIds: conflictStudentIds.map((id: string) => +id),
				teacherLocation: selectedLocation,
				waitInLinePassId,
			};
			this.dialog.open(OverrideEncounterPreventionComponent, {
				panelClass: 'consent-dialog-container',
				backdropClass: 'custom-bd',
				data: dialogData,
			});

			return of(null);
		}
		// Encounter Prevention Toast
		else {
			return zip(
				...conflictStudentIds.map((id) => {
					const epGroups$ = this.featureFlagService.isFeatureEnabledV2(FLAGS.EncounterPreventionOverride)
						? this.encounterPreventionService.getExclusionGroupsWithOverrides(+id)
						: this.encounterPreventionService.getExclusionGroups({ student: +id });
					return epGroups$.pipe(
						filter((groups) => groups.length > 0),
						take(1),
						tap((groups: ExclusionGroupWithOverrides[]) => {
							const enabledGroups = groups
								.filter((g) => g.enabled)
								.filter((g) => g.users.some((u: User) => conflictStudentIds.includes(u.id.toString())));
							if (enabledGroups.length > 0) {
								const hallPass = {
									...attemptedPass,
									travel_type: selectedTravelType,
									student: selectedStudents.find((user) => +user.id === +id),
								};
								this.showEncounterPreventionToast({
									conflictStudentIds: conflictStudentIds.map((id) => +id),
									preventedEncounters: preventedEncounters,
									exclusionPass: HallPass.fromJSON(hallPass),
									isStaff: forStaff,
									exclusionGroups: enabledGroups,
									conflictPasses: conflictPasses,
								});
							}
						})
					);
				})
			);
		}
	}

	showEncounterPreventionToast({
		exclusionPass,
		isStaff,
		exclusionGroups,
		conflictStudentIds,
		conflictPasses,
		isKioskMode = false,
	}: EncounterPreventionToast): void {
		let title: string;
		let subtitle: string;

		if (isKioskMode) {
			title = `${exclusionPass.student.display_name}'s pass couldn't start and was deleted`;
			subtitle = 'Please try again later';
		} else {
			title = isStaff ? 'Sorry, you can’t start this pass right now.' : "Sorry, you can't start your pass right now.";
			subtitle = isStaff ? "These students can't have a pass at the same time." : 'Please try again later.';
		}
		exclusionPass.student = User.fromJSON(exclusionPass.student);

		this.store.dispatch(
			openToastAction({
				data: {
					title,
					subtitle,
					type: 'error',
					encounterPrevention: true,
					exclusionPass,
					exclusionGroups,
					conflictStudentIds,
					conflictPasses,
				},
			})
		);
	}

	getPassStatus(
		startTime: Date,
		endTime: Date,
		expirationTime: Date,
		waitInLine = false,
		inFormContainer = false,
		isKioskMode = false
	): HallPassStatus {
		const now = new Date(this.timeService.now() + 250);
		if (now >= startTime && now < endTime && now < expirationTime) {
			return 'active';
		} else if (now < startTime || waitInLine || inFormContainer || isKioskMode) {
			return 'upcoming';
		} else if (now >= endTime) {
			return 'ended';
		} else if (now > expirationTime) {
			return 'overtime';
		}
	}

	calculatePassStatus(pass: HallPass): { isActive: boolean; fromPast: boolean; forFuture: boolean } {
		const now = this.timeService.now();
		const startTime = pass.start_time.getTime();
		const endTime = pass.end_time.getTime();
		return {
			isActive: Math.abs(startTime - now) <= 3000 && now < endTime,
			fromPast: now > endTime,
			forFuture: now < startTime,
		};
	}

	setPassOverlayColor(isStudent: boolean, pass: PassLike) {
		if (isStudent && pass instanceof HallPass) {
			const status = this.getPassStatus(pass.start_time, pass.end_time, pass.expiration_time);
			if (status === 'overtime') {
				return 'linear-gradient(139.13deg, #E32C66CC 1.57%, #CB1B53CC 100%)';
			}
			if (status === 'active') {
				const gradient: string[] = pass.color_profile.gradient_color.split(',');
				return `radial-gradient(circle at 73% 71%, ${gradient[0]}, ${gradient[1]})`;
			}
		}

		return 'rgba(16, 20, 24, 0.4)';
	}

	getPinnableBackground(pin: Pinnable): SafeStyle {
		const gradient: string[] = pin?.color_profile?.gradient_color.split(',');
		return this.sanitizer.bypassSecurityTrustStyle('radial-gradient(circle at 73% 71%, ' + gradient[0] + ', ' + gradient[1] + ')');
	}

	getPinnableFromLocation(location: Location): Pinnable {
		return this.pinnables.find((p) => p?.location?.id === location?.id) || this.pinnables.find((p) => p?.category === location?.category);
	}

	// getting the card component for opening a dialog
	getPassCardComponent(pass: PassLike): ComponentType<PassCardComponent | InvitationCardComponent | RequestCardComponent | WaitInLineCardComponent> {
		if (!pass) {
			throw new Error('Cannot open dialog with undefined pass data');
		}

		if (pass instanceof HallPass) {
			return PassCardComponent;
		}

		if (pass instanceof Invitation) {
			return InvitationCardComponent;
		}

		// noinspection SuspiciousInstanceOfGuard
		if (pass instanceof Request) {
			return RequestCardComponent;
		}

		if (pass instanceof WaitingInLinePass) {
			return WaitInLineCardComponent;
		}

		return null;
	}

	// This is used to scale pass modals based on the window height. Note that no top margin or position adjustment
	// is needed as long as the modal is opened with an accurate height. In other words, if a modal isn't
	// centered vertically it needs to be given an accurate height when it is opened - nothing should be
	// necessary to adjust its position in this method.
	scaleMatDialog(dialogRef: MatDialogRef<any>, dialogContainer: HTMLElement, footerHeight: number): void {
		// find the parent div with a class of 'cdk-overlay-pane'
		// so we can scale it if needed
		let parent = dialogContainer.parentElement;
		while (!parent.classList.contains('cdk-overlay-pane')) {
			parent = parent.parentElement;
		}
		const transformScale = this.calculateScale();
		if (transformScale !== 0) {
			parent.style.transform = `scale(${transformScale})`;
		}
	}

	calculateScale(): number {
		const windowHeight = window.innerHeight;
		// calculate height/scale adjustment
		if (windowHeight < 1000) {
			const percentageSmaller = (windowHeight * 100) / WINDOW_HEIGHT_AS_DESIGNED;
			return percentageSmaller / 100;
		}
	}

	// It is important that the pass card modals are given a correct height, without it they will
	// not be vertically centered properly. The main pass card area should always be 796 (MODAL_HEIGHT).
	// The footer can vary, and the height should include the top margin that it has.
	getModalHeight(pass: PassLike, isStaff: boolean, isKioskMode: boolean, isHallMonitor = false, isStudentProfile = false): string {
		let footerBarHeight = 0;
		if (pass instanceof HallPass) {
			const passStatus = this.getPassStatus(pass.start_time, pass.end_time, pass.expiration_time);
			if (!isStaff && !isKioskMode && passStatus === 'active') {
				footerBarHeight = 197;
			}
			if (isStaff && !isKioskMode && passStatus === 'active') {
				footerBarHeight = 121;
			}
			if (isStaff && (isKioskMode || isHallMonitor) && (passStatus === 'active' || passStatus === 'overtime')) {
				footerBarHeight = 121;
			}
			if (isStaff && (passStatus === 'ended' || passStatus === 'upcoming') && !isStudentProfile) {
				footerBarHeight = 121;
			}
			if (isKioskMode) {
				footerBarHeight = 121;
			}
		} else if (pass instanceof Request) {
			if (isStaff || (isKioskMode && pass.status !== 'declined')) {
				footerBarHeight = 121;
			}
		} else if (pass instanceof Invitation) {
			if (isStaff) {
				footerBarHeight = 121;
			}
		} else if (pass instanceof WaitingInLinePass) {
			if (isStaff || isKioskMode) {
				footerBarHeight = 121;
			}
		} else if (isKioskMode) {
			footerBarHeight = 121;
		}
		return `${MODAL_HEIGHT + footerBarHeight}px`;
	}

	getModalWidth(isSmartphone: boolean): string {
		if (isSmartphone) {
			return '90%';
		}
		return '686px';
	}

	getModalMinWidth(isSmartphone: boolean): string {
		if (isSmartphone) {
			return null;
		}
		return `${MODAL_MIN_WIDTH_DESKTOP}px`;
	}

	getCreatePassButtonTooltipText(isEmergencyActivated: boolean, hasActivePass: boolean, hasActivePassRequest: boolean, hasActiveWILPass: boolean) {
		if (isEmergencyActivated) {
			return 'An emergency is active.';
		} else if (hasActivePass) {
			return 'You have an active pass. End your pass to create a new one.';
		} else if (hasActivePassRequest) {
			return 'You have a pass request sent for now. Delete your pass request to create a new one.';
		} else if (hasActiveWILPass) {
			return 'You have a pass waiting in line. Delete your pass to create a new one.';
		} else {
			return '';
		}
	}

	private listenForNewActivePasses() {
		return this.pollingService.listenOnCurrentSchool('hall_pass.start').pipe(
			map((wsEvent) => wsEvent.data as HallPass),
			map(HallPass.fromJSON)
		);
	}

	private listenForEndedHallPasses() {
		return this.pollingService.listenOnCurrentSchool('hall_pass.end').pipe(
			map((wsEvent) => wsEvent.data as HallPass),
			map(HallPass.fromJSON)
		);
	}

	listenForActivePasses({ sort$, filter$ }: Partial<ActiveHallPassConfig>) {
		const sortFns = createHallPassSortFns();

		if (!sort$) {
			sort$ = of('');
		}
		if (!filter$) {
			filter$ = of('');
		}

		const activePassListener = this.getActivePasses().pipe(
			this.pollingService.restartOnConnected(),
			catchError(() => of<HallPass[]>([])),
			map((hps) => hps.map(HallPass.fromJSON)),
			switchMap((passes) => {
				const passList$ = new BehaviorSubject<HallPass[]>(passes);

				return merge(
					// listen for incoming passes, add them to list
					this.listenForNewActivePasses().pipe(
						tap((hp) => {
							passList$.next(passList$.value.filter(({ id }) => id !== hp.id).concat(hp));
						})
					),
					// listen for deleted passes, remove them from list
					this.listenForEndedHallPasses().pipe(
						tap((hp) => {
							passList$.next(passList$.value.filter((currHp) => currHp.id !== hp.id));
						})
					),
					sort$,
					filter$
				).pipe(
					switchMap(() => combineLatest([passList$.asObservable(), sort$, filter$])),
					map(([passes, sortKey, filterString]) => {
						if (filterString) {
							passes = filterPasses(passes, filterString);
						}

						if (sortKey) {
							const sortFn = sortFns[sortKey];
							passes = passes.sort(sortFn);
						}

						return passes;
					})
				);
			})
		);

		return activePassListener;
	}

	findPin(pins: Pinnable[], location: Location): Pinnable | undefined {
		return pins.find((p) => (!!p.category && p.category == location.category) || p.location?.id == location.id);
	}
}
