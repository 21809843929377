import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, combineLatest, of, throwError, zip } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, retryWhen, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Location } from '../../../models/Location';
import { FlexPeriod, FlexPeriodService, FlexSchedule } from '../../../flex-period.service';
import { CreateSchoolActivityReq, SchoolActivityService } from '../../../services/school-activity.service';
import { ToastService } from '../../../services/toast.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectDaysComponent } from './select-days/select-days.component';
import { ROLES, User } from '../../../models/User';
import { animate, style, transition, trigger } from '@angular/animations';
import { HallPassesService } from '../../../services/hall-passes.service';
import { HallPass } from '../../../models/HallPass';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationDialogComponent, ConfirmationTemplates, RecommendedDialogConfig } from '../confirmation-dialog/confirmation-dialog.component';
import { remove } from 'lodash';
import { DaysOfWeek } from '../../../admin/flex-period/create-flex-period/create-flex-period.component';
import { StudentList } from '../../../models/StudentList';
import { UserService } from '../../../services/user.service';
import { RequestsService } from '../../../services/requests.service';
import { EncounterPreventionService } from '../../../services/encounter-prevention.service';
import { SchedulePassActivityService } from '../../../services/schedule-pass-activity.service';
import { HttpService } from '../../../services/http-service';
import { School } from '../../../models/School';
import { Router } from '@angular/router';
import { ImportStudentListComponent } from '../../../admin/overlay-container/visibility-room/import-student-list/import-student-list.component';
import { RoundInputComponent } from '../../../admin/round-input/round-input.component';
import * as moment from 'moment';
import { ElementRef } from '@angular/core';
import { TimeService } from '../../../services/time.service';
import { HostListener } from '@angular/core';
import _refiner from 'refiner-js';
import { FeatureFlagService, FLAGS } from '../../../services/feature-flag.service';
import { PassLimitService } from 'app/services/pass-limit.service';
import { HallPassLimit } from 'app/models/HallPassLimits';
import { DynamicDialogData } from 'app/dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DialogFactoryService } from 'app/dialog-factory.service';
import { DynamicDialogService } from 'app/dynamic-dialog.service';

export interface InstanceTimes {
	start: Date;
	end: Date;
}

enum Month {
	January = 0,
	February = 1,
	March = 2,
	April = 3,
	May = 4,
	June = 5,
	July = 6,
	August = 7,
	September = 8,
	October = 9,
	November = 10,
	December = 11,
}

const animationDuration = '300ms';

@Component({
	selector: 'sp-create-activity',
	templateUrl: './create-activity.component.html',
	styleUrls: ['./create-activity.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [style({ opacity: 0 }), animate(animationDuration, style({ opacity: 1 }))]),
			transition(':leave', [animate(animationDuration, style({ opacity: 0 }))]),
		]),
	],
})
export class CreateActivityComponent implements OnInit {
	activityForm: FormGroup;
	originLocation: Location[] = [];
	selectedLocations: Location[] = [];
	flexPeriods: FlexPeriod[];
	flexMode = false;
	filteredStudents: User[] = [];
	allGroups: StudentList[] = [];
	filteredGroups: StudentList[] = [];
	dayOfWeekSelected = '';
	dayTxt = '';
	loading = true;
	selectedFlexPeriod: FlexPeriod;
	searchStudentGroup = '';
	selectedStudents: User[] = [];
	inputValue$: Subject<string> = new Subject<string>();
	processing = false;
	isFlexAccessible = false;
	duration = 0;
	selectedDate: moment.Moment = moment(this.timeService.nowDate());
	startDate: moment.Moment = moment(this.timeService.nowDate());
	timeControl = new FormControl(null, this.validateTimeRange.bind(this));
	openCalendar = false;
	private destroy$: Subject<void> = new Subject<void>();
	private instanceTimes: InstanceTimes[] = [];
	maxHeightValue = 'calc(100vh - 293px)';
	originZIndex = 0;
	destinationZIndex = 0;
	isInputFocused = false;
	isSelectFocused = true;
	roomTimeError = false;
	private selectedGroupsAndStudents: (StudentList | User)[] = this.visibilityService.getInitialStudent();
	schedulesFeatureFlag = false;
	chipHovered = false;
	iconHovered = false;
	passLimit: HallPassLimit;
	private dialogService: DynamicDialogService;

	schools$: Observable<School[]>;
	importStudentListDialogRef: MatDialogRef<ImportStudentListComponent>;

	searchInput: RoundInputComponent;
	@ViewChild('searchInput', { static: false }) set content(content: RoundInputComponent) {
		if (content) {
			this.searchInput = content;
		}
	}

	@ViewChild('confirmDialogBodyVisibility') confirmDialogVisibility: TemplateRef<HTMLElement>;
	@ViewChild('confirmDialogBody') confirmDialog: TemplateRef<HTMLElement>;

	@ViewChild('calendar', { static: false, read: ElementRef }) calendar: ElementRef;
	@HostListener('document:mousedown', ['$event'])
	handleClick(event: Event) {
		this.onDocumentClick(event);
	}

	constructor(
		private flexPeriodService: FlexPeriodService,
		private activityService: SchoolActivityService,
		private userService: UserService,
		private hallPassService: HallPassesService,
		private toastService: ToastService,
		private dialog: MatDialog,
		private requestService: RequestsService,
		private encounterService: EncounterPreventionService,
		private http: HttpService,
		private visibilityService: SchedulePassActivityService,
		private router: Router,
		private timeService: TimeService,
		private featureFlagService: FeatureFlagService,
		private passLimitService: PassLimitService,
		private dialogFactoryService: DialogFactoryService
	) {}

	ngOnInit(): void {
		this.schools$ = this.http.schoolsCollection$;
		this.http.currentSchool$
			.pipe(
				takeUntil(this.destroy$),
				filter((school) => !!school)
			)
			.subscribe((school) => {
				this.isFlexAccessible = school.flex_access == 'yes';
			});
		this.activityForm = new FormGroup({
			name: new FormControl('', Validators.nullValidator),
			flex_period_id: new FormControl({ value: 0, disabled: !this.isFlexAccessible }, Validators.required),
			public: new FormControl(true, Validators.nullValidator),
			day: new FormControl(null, Validators.nullValidator),
			start_time: new FormControl(null, Validators.nullValidator),
			end_time: new FormControl(null, Validators.nullValidator),
			capacity: new FormControl(0, Validators.min(0)),
			repeats: new FormControl('never', Validators.required),
			issuer_message: new FormControl('', Validators.nullValidator),
			declinable: new FormControl(false, Validators.nullValidator),
		});
		// Subscribe to timeControl value changes
		this.timeControl.valueChanges.subscribe((newTimeValues) => {
			// Update the corresponding fields in activityForm
			this.activityForm.patchValue({
				start_time: newTimeValues.start,
				end_time: newTimeValues.end,
			});
		});

		this.flexPeriodService
			.GetAllFlexPeriods()
			.pipe(
				takeUntil(this.destroy$),
				finalize(() => (this.loading = false))
			)
			.subscribe((res) => {
				this.flexPeriods = res;
			});
		this.userService.getStudentGroupsRequest();
		this.userService.studentGroups$.subscribe((groups) => {
			this.allGroups = groups;
			this.filteredGroups = groups;
		});

		this.setSelectedStudents();

		this.activityForm
			.get('flex_period_id')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				const flexId = parseInt(value);
				if (flexId > 0) {
					this.flexMode = true;
					this.activityForm.patchValue({
						repeats: 'flex_recurring',
						day: null,
						start_time: null,
						end_time: null,
					});
					this.activityForm.get('day').disable();
					this.activityForm.get('start_time').disable();
					this.activityForm.get('end_time').disable();
					this.selectedFlexPeriod = this.flexPeriods.find((period) => period.id === flexId);

					const uniqueDaysSet = new Set<number>();
					if (this.selectedFlexPeriod.schedules) {
						for (const schedule of this.selectedFlexPeriod.schedules) {
							schedule.days_of_week.forEach((day) => uniqueDaysSet.add(day));
						}
					}
					this.timeControl.disable();
					const uniqueDaysArray = Array.from(uniqueDaysSet);
					const sortedUniqueDays = uniqueDaysArray.sort((a, b) => a - b);
					const formattedDays = sortedUniqueDays.map((day) => DaysOfWeek[day]).join(', ');
					this.dayTxt = formattedDays;
				} else {
					this.flexMode = false;
					this.timeControl.enable();
					this.activityForm.patchValue({ repeats: 'never' });
					this.activityForm.get('day').enable();
					this.activityForm.get('start_time').enable();
					this.activityForm.get('end_time').enable();
					this.instanceTimes = [];
					this.selectedFlexPeriod = null;
					this.dayTxt = '';
				}
			});
		this.activityForm
			.get('repeats')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((selected: string) => {
				if (selected === 'scheduled') {
					if (this.selectedFlexPeriod) {
						const selectDialog = this.dialog.open(SelectDaysComponent, {
							panelClass: 'select-days-flex',
							width: '433px',
							data: {
								flexPeriod: this.selectedFlexPeriod,
							},
						});
						selectDialog.componentInstance.closeDialog = (state: string, times: InstanceTimes[]) => {
							if (state !== 'scheduled') {
								this.activityForm.patchValue({ repeats: state });
							}
							this.instanceTimes = times;

							if (state === 'scheduled') {
								const days = this.instanceTimes.map((instance) => {
									return new Date(instance.start);
								});
								this.dayTxt = this.formatDateSet(days);
							}
							selectDialog.close();
						};
					} else {
						this.activityForm.patchValue({ repeats: 'flex_recurring' });
					}
				}
			});
		this.activityForm
			.get('day')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((value: string) => {
				const day = new Date(value + 'T12:00');
				this.dayOfWeekSelected = DaysOfWeek[day.getDay()];
			});
		this.activityForm
			.get('declinable')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((declinable) => {
				if (declinable) {
					this.activityForm.patchValue({ repeats: 'never' });
					this.originLocation = []; // reset the field
				}
			});
		combineLatest([this.activityForm.get('start_time').valueChanges, this.activityForm.get('end_time').valueChanges])
			.pipe(takeUntil(this.destroy$))
			.subscribe((times: [string, string]) => {
				if (times[0] && times[1]) {
					this.duration = this.getSecondsBetweenTimes(times[0], times[1]);
				}
			});

		this.schools$.subscribe((schoolsArray) => {
			this.maxHeightValue = schoolsArray.length > 1 ? `calc(100vh - 393px)` : `calc(100vh - 336px)`;
		});

		this.activityForm.patchValue({
			day: this.selectedDate.format('YYYY-MM-DD'),
		});
		this.setSchedulesFeatureFlag();
		this.passLimitService.getPassLimit().subscribe((pl) => (this.passLimit = pl.pass_limit));
	}

	getTimeControl(schedule: FlexSchedule): FormControl {
		const flexTimeControl = new FormControl();
		flexTimeControl.setValue({
			start: `${schedule.start_hour.toString().padStart(2, '0')}:${schedule.start_minute.toString().padStart(2, '0')}`,
			end: `${schedule.end_hour.toString().padStart(2, '0')}:${schedule.end_minute.toString().padStart(2, '0')}`,
		});
		flexTimeControl.disable();
		return flexTimeControl;
	}

	private validateTimeRange(control: FormControl): { [key: string]: string } | null {
		const { start, end } = control.value || {};
		const today = moment().startOf('day');
		this.roomTimeError = false;
		// Check if the selected date is today
		if (this.selectedDate.isSame(today, 'day')) {
			const currentTime = moment(); // Use moment() to get the current time

			// Compare start time with current time
			if (start && moment(start, 'hh:mm A').isBefore(currentTime, 'minutes')) {
				return { start: `Pick a time after the current time (${currentTime.format('h:mm A')})` };
			}
		}

		let duration: number;
		if (start && end) {
			duration = this.getSecondsBetweenTimes(start, end);
		}

		if (
			duration > 0 &&
			this.selectedLocations.length > 0 &&
			duration > this.selectedLocations[0]?.max_allowed_time * 60 &&
			!control.errors?.start &&
			!control.errors?.both
		) {
			this.roomTimeError = true;
			const errorStr = `${this.selectedLocations[0].title}'s time limit is ${this.selectedLocations[0].max_allowed_time} minutes.`;
			return { end: errorStr };
		}

		return null;
	}

	fixTimeError(): void {
		const newEnd = this.addMinutesToTimeString(this.timeControl.value.start, this.selectedLocations[0].max_allowed_time);
		this.timeControl.setValue({ start: this.timeControl.value.start, end: newEnd });
	}

	private addMinutesToTimeString(timeString: string, minutesToAdd: number): string {
		// Parse the time string
		const [hours, minutes] = timeString.split(':').map(Number);
		const time = new Date();
		time.setHours(hours, minutes + minutesToAdd, 0, 0);

		// Format back to "hh:mm"
		const formattedHours = time.getHours().toString().padStart(2, '0');
		const formattedMinutes = time.getMinutes().toString().padStart(2, '0');
		return `${formattedHours}:${formattedMinutes}`;
	}

	onDocumentClick(event: Event) {
		if (this.openCalendar && !this.calendar?.nativeElement?.contains(event.target)) {
			this.toggleCalendar();
		}
	}

	toggleCalendar() {
		this.openCalendar = !this.openCalendar;
	}

	calendarResult(selectedDate: moment.Moment[]) {
		const newDate: moment.Moment = selectedDate[0];
		this.selectedDate = newDate;
		this.activityForm.patchValue({
			day: this.selectedDate.format('YYYY-MM-DD'),
		});
		// Trigger time validation when date changes
		this.timeControl.updateValueAndValidity();
		this.openCalendar = false;
		const url = this.router
			.createUrlTree(['main', 'passes-calendar'], {
				queryParams: {
					start_date: this.getFirstWeekdayOfWeekFormatted(new Date(this.activityForm.value.day)),
					day_view_date: this.activityForm.value.day,
				},
			})
			.toString();
		this.router.navigateByUrl(url);
	}

	onSearch(search: string) {
		this.searchStudentGroup = search;
		if (search !== '') {
			this.filteredGroups = this.allGroups.filter((group) => {
				const titleMatches = group.title.toLowerCase().includes(search.toLowerCase());
				const alreadyAdded = this.selectedGroupsAndStudents.includes(group);
				return titleMatches && !alreadyAdded;
			});

			this.userService
				.searchProfile(ROLES.Student, 100, search)
				?.pipe(
					map((responses) => responses.results.map((r) => User.fromJSON(r))),
					map((students) => {
						return students.filter((student) => !this.selectedGroupsAndStudents.includes(student));
					})
				)
				.subscribe((filteredStudents) => (this.filteredStudents = filteredStudents));
		} else {
			this.filteredGroups = this.allGroups.filter((group) => !this.selectedGroupsAndStudents.includes(group));
			this.filteredStudents = [];
		}
	}

	onInputFocus(event: boolean): void {
		this.isInputFocused = event;
		if (!event && !this.searchInput.value) {
			this.filteredGroups = this.allGroups.filter((group) => !this.selectedGroupsAndStudents.includes(group));
			this.filteredStudents = [];
		}
	}

	setOriginLocation(val: Location[]) {
		if (val.length > 0) {
			this.originLocation = [val.slice(-1)[0]];
		}
		// Now, if you want to set the same value as the current value of timeControl
		const currentValue = this.timeControl.value;
		this.timeControl.setValue(currentValue);
		// Trigger time validation when origin change
		// this.timeControl.updateValueAndValidity();
	}

	handleLocationSelection(val: Location[]) {
		if (val.length > 0) {
			this.selectedLocations = [val.slice(-1)[0]];
		}
		const currentValue = this.timeControl.value;
		this.timeControl.setValue(currentValue);
		// Trigger time validation when destination change
		// this.timeControl.updateValueAndValidity();
	}

	formatDateSet(dates: Date[]): string {
		// Group dates by month
		const datesByMonth: Record<number, number[]> = {};
		dates.forEach((date) => {
			const month = date.getMonth();
			const day = date.getDate();
			if (!datesByMonth[month]) {
				datesByMonth[month] = [];
			}
			datesByMonth[month].push(day);
		});

		// Format each month's dates
		const formattedDates: string[] = [];
		for (const month in datesByMonth) {
			const monthName = Month[month]; // Get the month name from the enum
			const dayList = datesByMonth[month].join(', ');
			formattedDates.push(`${monthName} ${dayList}`);
		}

		// Combine formatted dates with semicolon separator
		const result = formattedDates.join('; ');

		return result;
	}

	removeFromSelected(id: number): void {
		this.selectedGroupsAndStudents = this.selectedGroupsAndStudents.filter((entity) => entity.id !== id);
		this.setSelectedStudents();
	}

	// TO-DO move this and group selection logic to a seprate service since it's repeated in pass creation.
	setSelectedStudents(): void {
		this.selectedStudents = [];
		this.selectedGroupsAndStudents.forEach((groupOrStudent) => {
			if ('users' in groupOrStudent) {
				(groupOrStudent as StudentList).users.forEach((student) => {
					if (!this.isInSelectStudents(student)) {
						this.selectedStudents.push(student);
					}
				});
			} else if (!this.isInSelectStudents(groupOrStudent)) {
				this.selectedStudents.push(groupOrStudent as User);
			}
		});
	}

	isInSelectStudents(user: User): boolean {
		return !!this.selectedStudents.find((student) => student.id === user.id);
	}

	handleStudentGroupAdd(item): void {
		if (this.filteredGroups.includes(item)) {
			this.filteredGroups = this.filteredGroups.filter((group) => group !== item);
		} else {
			this.filteredStudents = this.filteredStudents.filter((student) => student !== item);
		}
		this.selectedGroupsAndStudents.push(item);
		this.setSelectedStudents();
		this.searchStudentGroup = '';
		this.inputValue$.next('');
		this.isSelectFocused = false;
	}

	close(): void {
		this.visibilityService.SetInitialStudent(null);
		this.visibilityService.HideForm();
	}

	get isFormValid(): boolean {
		if (
			(!this.flexMode &&
				(this.selectedStudents.length === 0 ||
					!this.activityForm.value.day ||
					this.activityForm.value.start_time >= this.activityForm.value.end_time ||
					new Date(this.activityForm.value.day + 'T' + this.activityForm.value.start_time) < new Date() ||
					!this.activityForm.value.end_time ||
					this.duration === 0 ||
					(!this.activityForm.value.declinable &&
						(this.selectedLocations.length === 0 ||
							this.originLocation.length === 0 ||
							this.selectedLocations[0]?.id === this.originLocation[0]?.id)) ||
					(this.selectedLocations.length > 0 &&
						this.selectedLocations[0]?.max_allowed_time &&
						this.duration > this.selectedLocations[0].max_allowed_time * 60))) ||
			this.selectedLocations.length === 0 ||
			(this.flexMode && !this.activityForm.value.name?.length)
		) {
			return false;
		}

		return this.activityForm.valid;
	}

	onSubmit(): void {
		this.loading = true;
		if (!this.isFormValid) {
			this.toastService.openToast({ title: `Invalid data provided, try again with different selections`, type: 'error' });
			return;
		}
		if (this.flexMode) {
			this.newActivity();
		} else {
			this.newPass();
		}
	}

	handleTimeRangeFocus(event): void {
		const { startInputFocused, endInputFocused } = event;
		const { start, end } = this.timeControl.value || {};

		if (startInputFocused || endInputFocused) {
			this.roomTimeError = false;
		}

		let duration: number;
		if (start && end) {
			duration = this.getSecondsBetweenTimes(start, end);
		}

		if (
			!this.timeControl.errors?.start &&
			!this.timeControl.errors?.both &&
			this.timeControl.errors?.end &&
			!startInputFocused &&
			!endInputFocused &&
			duration > 0 &&
			this.selectedLocations.length > 0 &&
			duration > this.selectedLocations[0]?.max_allowed_time * 60
		) {
			this.roomTimeError = true;
		}
	}

	newActivity() {
		const activity: CreateSchoolActivityReq = {
			name: this.activityForm.value.name,
			icon: '',
			description: '',
			location_id: this.selectedLocations[0].id,
			flex_period_id: parseInt(this.activityForm.value.flex_period_id),
			max_attendees: this.activityForm.value.capacity > 0 ? parseInt(this.activityForm.value.capacity) : 0,
			state: this.activityForm.value.repeats,
			public_event: this.activityForm.value.public,
		};

		this.activityService
			.CreateActivity(activity)
			.pipe(finalize(() => (this.loading = false)))
			.subscribe({
				next: (resp) => {
					// create instances
					if (resp.state === 'scheduled') {
						const instance$ = this.instanceTimes.map((time) => this.activityService.CreateActivityInstance(time.start, time.end, resp.id));
						zip(...instance$).subscribe();
					}
					this.toastService.openToast({ title: `Activity successfully created!`, type: 'success' });
					this.close();
				},
				error: (err) => {
					console.error(err);
					this.toastService.openToast({ title: `Error while creating activity, try again.`, type: 'error' });
				},
			});
	}

	getTimeInSeconds(timeStr: string): number {
		const timeParts = timeStr.split(':').map(Number);
		const [hours, minutes] = timeParts;
		return hours * 3600 + minutes * 60;
	}

	getSecondsBetweenTimes(timeStr1: string, timeStr2: string): number {
		const timeInSeconds1 = this.getTimeInSeconds(timeStr1);
		const timeInSeconds2 = this.getTimeInSeconds(timeStr2);
		if (isNaN(timeInSeconds1) || isNaN(timeInSeconds2)) {
			// Invalid time format
			return NaN;
		}
		return Math.abs(timeInSeconds2 - timeInSeconds1);
	}

	formatTime(hour: number, minute: number): string {
		const ampm = hour >= 12 ? 'pm' : 'am';
		const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
		const formattedMinute = minute.toString().padStart(2, '0');
		return `${formattedHour}:${formattedMinute} ${ampm}`;
	}

	newPass() {
		const room = this.selectedLocations[0];
		const body = {
			duration: this.duration,
			destination: room.id,
			travel_type: 'one_way',
			issuer_message: this.activityForm.value.issuer_message,
			start_time: new Date(this.activityForm.value.day + 'T' + this.activityForm.value.start_time).toISOString(),
		};

		if (this.selectedStudents.length > 1) {
			body['students'] = this.selectedStudents.map((user) => user.id);
		} else {
			body['student'] = this.selectedStudents[0].id;
		}
		body['override_visibility'] = undefined;

		// Pass Request
		if (this.activityForm.value.declinable) {
			const invitation = {
				default_origin: room.id,
				destination: room.id,
				date_choices: body.start_time,
				duration: body.duration,
				travel_type: body.travel_type,
				students: this.selectedStudents.map((student) => student.id),
			};

			if (this.activityForm.value.issuer_message) {
				invitation['issuer_message'] = this.activityForm.value.issuer_message;
			}
			this.requestService
				.createInvitation(invitation)
				.pipe(
					takeUntil(this.destroy$),
					catchError((error) => {
						this.toastService.openToast({
							title: 'Error encountered',
							subtitle: error,
							type: 'error',
						});
						return of(error);
					}),
					finalize(() => {
						this.loading = false;
						this.close();
					})
				)
				.subscribe();
			return;
		}

		// if the schedule config is present and is repeating, then set the `recurring_scheduled_config` key, otherwise omit the key
		// from the body. The backend will ignore if the key is not present
		if (this.activityForm.value.repeats === 'daily') {
			body['recurring_scheduled_config'] = 1;
		} else if (this.activityForm.value.repeats === 'weekly') {
			body['recurring_scheduled_config'] = 2;
		}
		if (this.originLocation.length === 0) {
			// dev-level error, scheduled button will be disabled if originLocation isn't set
			throw new Error('Origin Location is required to start a pass!');
		}

		body['origin'] = this.originLocation[0].id;

		// Hall Pass
		of(body)
			.pipe(
				concatMap((b) => (this.selectedStudents.length > 1 ? this.hallPassService.bulkCreatePass(b) : this.hallPassService.createPass(b))),
				takeUntil(this.destroy$),
				switchMap(({ conflict_student_ids, _, error }) => {
					if (error) {
						const code = error.code;
						switch (code) {
							case 'max_pass_limit_reached':
								this.toastService.openToast({
									title: 'Maximum Hall Pass Limit Reached',
									subtitle: 'The maximum hall pass limit for the school has been reached. Please try again later.',
									type: 'error',
								});
								break;
							case 'pass_cooldown':
								this.toastService.openToast({
									title: error.message,
									type: 'error',
								});
								break;
							case 'no_fly_time_active':
								this.toastService.openToast({
									title: 'No Fly Time Active',
									subtitle: error.message,
									type: 'error',
								});
								break;
							default:
								this.toastService.openToast({
									title: 'Unknown error code',
									type: 'error',
								});
								break;
						}
						return of(null);
					}

					if (conflict_student_ids) {
						return zip(
							...conflict_student_ids.map((id) => {
								return this.encounterService.getExclusionGroups({ student: id }).pipe(
									filter((groups) => groups.length > 0),
									take(1),
									tap((groups) => {
										const enabledGroups = groups.filter((g) => g.enabled);
										if (enabledGroups.length > 0) {
											this.hallPassService.showEncounterPreventionToast({
												exclusionPass: {
													...({
														origin: room,
														destination: room,
														color_profile: {
															// TO-DO use the real room color
															gradient_color: room?.gradient ? room.gradient : '#5A5A5A,#D3D3D3',
															solid_color: '#FFFFFF',
														},
													} as HallPass),
													travel_type: body.travel_type,
													student: this.selectedStudents.find((user) => +user.id === +id),
												} as HallPass,
												isStaff: true,
												exclusionGroups: enabledGroups,
											});
										}
									})
								);
							})
						);
					}

					return of(null);
				}),
				retryWhen((errors: Observable<HttpErrorResponse>) => {
					const getOverrideFromDialog = (error) => {
						const afterDialogClosed$ = this.dialog
							.open(ConfirmationDialogComponent, {
								panelClass: 'overlay-dialog',
								backdropClass: 'custom-backdrop',
								closeOnNavigation: true,
								data: {
									headerText: '',
									body: this.confirmDialogVisibility,
									buttons: {
										confirmText: 'Override',
										denyText: 'Skip These Students',
									},
									templateData: { alerts: this.prepareTemplateDataVisibility(error, body['origin'], body.destination) },
									icon: {
										name: 'Eye (Green-White).svg',
										background: '',
										spacing: '5px',
									},
								} as ConfirmationTemplates,
							})
							.afterClosed();

						return afterDialogClosed$.pipe(map((override) => ({ override, students: error.students.map((s) => s.User.id) })));
					};

					return errors.pipe(
						tap((errorResponse) => {
							const isVisibilityError = 'visibility_alerts' in errorResponse.error;
							// not our error case? dispatch it to the next retryWhen
							if (!isVisibilityError) {
								throw errorResponse;
							}
							// a student has been checked server side and had no room visibility
							const isRoomsClosed = 'rooms_closed' in errorResponse.error;
							// both teachers and students must see as a room is closed only by an admin
							if (isRoomsClosed) {
								const roomNames = errorResponse.error.visibility_alerts.filter((r) => !r.enable).map((r) => r?.location_title ?? '');
								this.toastService.openToast({
									title: 'Room status',
									subtitle: `Closed: ${roomNames.join(',')}`,
									type: 'error',
								});

								this.loading = false;
								throw 'room has been closed';
							}
						}),
						concatMap(({ error }) => getOverrideFromDialog(error)),
						concatMap(({ override, students }: { override: boolean; students: number[] }) => {
							if (override === undefined) {
								this.close();
								throw 'confirmation closed';
							}
							if (override === true) {
								body['override_visibility'] = true;
								return of(null);
							}

							if (override === false) {
								remove(body['students'] as number[], (elem) => students.includes(elem));
								// removal left us with no students
								// this is as canceling the process
								if (body['students'].length === 0) {
									this.loading = false;
									throw 'no students after skiping';
								}
								return of(null);
							}
						})
					);
				}),
				retryWhen((errors: Observable<HttpErrorResponse>) => {
					return errors.pipe(
						tap((errorResponse: HttpErrorResponse) => {
							if (errorResponse.error?.message !== 'one or more pass limits reached!') {
								throw errorResponse;
							}
						}),
						concatMap((errorResponse) => {
							const students = errorResponse.error.students as { displayName: string; id: number; passLimit: number }[];
							const numPasses = body['students']?.length || 1;
							const frequencyText = this.passLimit?.frequency === 'day' ? 'today' : 'this ' + this.passLimit?.frequency;
							let headerText: string;
							let buttons: ConfirmationTemplates['buttons'];
							if (numPasses > 1) {
								headerText = `Creating these ${numPasses} passes will exceed the Pass Limits for the following students:`;
								buttons = {
									confirmText: 'Override Limits',
									denyText: 'Skip These Students',
								};
							} else if (numPasses === 1) {
								const { passLimit } = students[0];
								headerText = `Student's Pass limit reached: ${this.selectedStudents[0].display_name} has had ${passLimit}/${passLimit} passes ${frequencyText}`;
								buttons = {
									confirmText: 'Override Limit',
									denyText: 'Cancel',
								};
							}
							const data: DynamicDialogData = {
								headerText: headerText,
								showCloseIcon: true,
								primaryButtonLabel: buttons.confirmText,
								secondaryButtonLabel: buttons.denyText,
								secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
								secondaryButtonTextColor: '#7083A0',
								primaryButtonGradientBackground: '#E32C66',
								primaryButtonTextColor: 'white',
								classes: 'tw-min-h-0',
								modalBody: this.confirmDialog,
								icon: {
									name: 'Pass Limit (White).svg',
									background: '#6651F1',
									spacing: '16px',
								},
							};

							this.dialogService = this.dialogFactoryService.open(data, { panelClass: 'dynamic-dialog-modal-min', disableClose: false });
							return this.dialogService.closed$.pipe(
								map((override) => {
									return { override: override === 'primary', students: errorResponse.error.students.map((s) => s.id) };
								})
							);
						}),
						concatMap(({ override, students }: { override: boolean; students: number[] }) => {
							if (override === undefined) {
								this.close();
								throw new Error('confirmation closed, no options selected');
							}
							if (override === true) {
								body['override'] = true;
								return of(null);
							}

							if (override === false) {
								remove(body['students'] as number[], (elem) => students.includes(elem));
								// server side "no students" case is seen as bad request
								if (body['students'].length === 0) {
									this.close();
									throw new Error('No students to create passes for');
								}
								return of(null);
							}
						})
					);
				}),
				catchError((error: HttpErrorResponse) => {
					this.toastService.openToast({
						title: 'Something went wrong!',
						subtitle: 'Could not create pass, contact support for more info',
						type: 'error',
					});
					return throwError(error);
				}),
				finalize(() => {
					this.close();
					this.loading = false;
				})
			)
			.subscribe({
				next: () => {
					this.hallPassService.createPassEvent$.next(true);
				},
				error: (err) => {
					console.log(err);
				},
			});
	}

	showImportStudentList(): void {
		const dialogRef = (this.importStudentListDialogRef = this.dialog.open(ImportStudentListComponent, {
			closeOnNavigation: true,
			panelClass: 'main-form-dialog-container',
			backdropClass: 'custom-backdrop',
			maxWidth: '100vw',
			data: {
				forInput: true,
			},
		}));

		dialogRef.afterClosed().subscribe((students: User[]) => {
			if (students && students.length > 0) {
				this.selectedGroupsAndStudents = this.selectedGroupsAndStudents.concat(students);
				//filter out duplicate imports by user id
				const idMap = new Map(this.selectedGroupsAndStudents.map((user) => [user.id, user]));
				this.selectedGroupsAndStudents = Array.from(idMap.values());
				this.setSelectedStudents();
			}
		});
	}

	private prepareTemplateDataVisibility({ visibility_alerts, students }, origin, destination) {
		const out = [];
		for (const a of visibility_alerts) {
			const ss = a['room_students'].map((sid) => {
				const found = students.find((s) => s.User.id === sid);
				if (!found) return '<unknown name>';
				return found.User['first_name'] + ' ' + found.User['last_name'];
			});
			let joint = 'for';
			if (a['location_id'] == origin) {
				joint = 'to come from';
			} else if (a['location_id'] == destination) {
				joint = 'to go to';
			}
			const phrase = `These students do not have permission ${joint} this room`;
			out.push({ phrase, students: ss.join(', ') });
		}
		return out;
	}

	private getFirstWeekdayOfWeekFormatted(date: Date): string {
		const day = date.getDay(); // Get current day of the week (0-6, where Sunday is 0 and Monday is 1)
		const firstDay = new Date(date);

		// Calculate the difference to Monday
		// If it's Sunday (0), set to -6; otherwise, subtract the day number by 1
		const difference = day === 0 ? -6 : 1 - day;

		firstDay.setDate(date.getDate() + difference); // Adjust to Monday of that week

		// Format the date
		const dd = String(firstDay.getDate()).padStart(2, '0');
		const mm = String(firstDay.getMonth() + 1).padStart(2, '0'); //January is 0!
		const yyyy = firstDay.getFullYear();

		return `${yyyy}-${mm}-${dd}`;
	}

	onRefinerClick(): void {
		_refiner('showForm', 'c991d4c0-d108-11ee-a93b-594476e9c525', true);
	}

	setSchedulesFeatureFlag(): void {
		this.schedulesFeatureFlag = this.featureFlagService.isFeatureEnabledV2(FLAGS.Schedules);
	}

	setChipHovered(state: boolean) {
		this.chipHovered = state;
	}

	setIconHovered(state: boolean) {
		this.iconHovered = state;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
