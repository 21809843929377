import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TagComponent } from './tag/tag.component';

@NgModule({
	declarations: [TooltipComponent, TagComponent],
	imports: [CommonModule],
	exports: [TooltipComponent, TagComponent],
})
export class BackpackModule {}
