<div
	class="wrapper"
	[ngClass]="{ 'info-card': infoCard }"
	#wrapper
	[style.padding]="group.name ? '6px 0' : 0"
	(mouseenter)="wrapper.hovered = true"
	(mouseleave)="wrapper.hovered = false; wrapper.pressed = false"
	(mousedown)="wrapper.pressed = true"
	(click)="wrapper.pressed = false; clickEvent.emit($event)"
	[@pressState]="wrapper.pressed ? 'down' : 'up'"
	[style.background-color]="backgroundColor ? backgroundColor : 'transparent'">
	<div class="group-name tw-flex tw-items-center">
		<span *ngIf="group.name" class="tw-text-base">{{ group.name }}</span>
		<span *ngIf="numOverrides && !studentProfileView" class="tw-text-gray-400 tw-font-normal tw-text-sm" [ngClass]="{ 'tw-ml-1.5': group.name }"
			>{{ numOverrides }} Overrides</span
		>
		<div class="chip tw-ml-auto" [ngClass]="{ 'tw-mr-11': studentProfileView }" *ngIf="!group.enabled">Disabled</div>
	</div>
	<div class="students">
		<div class="student" *ngFor="let student of group.users">
			<div class="student-picture">
				<div
					*ngIf="student.profile_picture; else d"
					[ngStyle]="{ background: 'url(' + student.profile_picture + ') no-repeat left center/cover', width: '23px', height: '23px' }"></div>
				<ng-template #d>
					<img width="23" height="23" [src]="'./assets/Avatar Default.svg' | resolveAsset" alt="" />
				</ng-template>
			</div>
			<div class="name" notranslate>{{ student.display_name }}</div>
		</div>
	</div>
	<ng-template #name>
		<div class="group-name">
			{{ group.name }}
		</div>
	</ng-template>

	<div class="octagon" [style.top]="group.name ? '15px' : '10px'">
		<app-octagon *ngIf="!wrapper.hovered; else go" [size]="28" [backgroundColor]="'transparent'" [borderColor]="'#E32C66'" [borderWidth]="5">
			<div class="value" style="color: #e32c66">{{ group.prevented_encounters.length }}</div>
		</app-octagon>
		<ng-template #go>
			<app-icon-button [srcString]="'Chevron Right'" [size]="15" (clickEvent)="clickEvent.emit($event)"></app-icon-button>
		</ng-template>
	</div>
</div>
