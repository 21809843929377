<form [formGroup]="editTeachersForm" *ngIf="editTeachersForm" autocomplete="off" class="editTeachersForm">
	<sp-input
		#spInputComponent
		[isFocused]="isInputFocus"
		[group]="editTeachersForm"
		[control]="'teachers'"
		[label]="'Teachers'"
		[height]="isAutoHeight ? null : 121"
		[description]="'Teachers that can view and manage this class.'"
		[placeholder]="'Add teachers'"
		[displayPlaceholder]="teachers?.length === 0"
		(blur)="onSearchBlur()">
		<ng-container *ngIf="teachers?.length">
			<div *ngFor="let u of teachers">
				<app-selection
					[selection]="u.user"
					[isUser]="true"
					(removeSelectionEvent)="removeSelection($event)"
					[locked]="u.external_source_name !== ''"
					[tooltipContent]="teacherTooltip">
				</app-selection>
				<ng-template #teacherTooltip>
					<div
						class="ds-tooltip"
						[ngClass]="{
							clever: u.external_source_name === 'clever',
							classlink: u.external_source_name === 'classlink',
							google: u.external_source_name === 'google-classroom'
						}">
						<div class="tw-mb-3"><img src="../../../../../assets/sync-icon.svg" alt="Sync Icon" /></div>
						<ng-container *ngIf="u.external_source_name === 'clever'">
							<div class="tw-font-bold tw-mb-1 tw-flex tw-gap-1 tw-items-center tw-justify-center">
								<div style="margin-top: -1px">Synced from</div>
								<div><img src="../../../../../assets/clever-logo.svg" alt="Clever" /></div>
							</div>
							<div class="tw-font-sm">To remove this teacher from this class, remove them from Clever.</div>
						</ng-container>
						<ng-container *ngIf="u.external_source_name === 'classlink'">
							<div class="tw-font-bold tw-mb-1 tw-flex tw-gap-1 tw-items-center tw-justify-center">
								<div style="margin-top: -2px">Synced from</div>
								<div><img src="../../../../../assets/classlink-logo.svg" alt="Classlink" /></div>
							</div>
							<div class="tw-font-sm">To remove this teacher from this class, remove them from ClassLink.</div>
						</ng-container>
						<ng-container *ngIf="u.external_source_name === 'google-classroom'">
							<div class="tw-font-bold tw-mb-1 tw-flex tw-gap-1 tw-items-center tw-justify-center">
								<div style="margin-top: -1px">Synced from</div>
								<div><img src="../../../../../assets/google-classroom-logo.svg" alt="Google Classroom" /></div>
							</div>
							<div class="tw-font-sm">To remove this teacher from this class, remove them from Google Classroom.</div>
						</ng-container>
					</div>
				</ng-template>
			</div>
		</ng-container>
	</sp-input>
	<div class="tw-relative" *ngIf="teacherList.length">
		<mat-list [style.padding-top]="'0px'" id="teacher-list">
			<div *ngFor="let user of teacherList" [ngClass]="{ 'user-disabled tw-opacity-50': user | userInArray : teachers }">
				<mat-list-item spHover="#F5F6F8,#FFFFFF" appCrossPointerEventTarget (pointerClickEvent)="onSelectTeacher(user)" #_item>
					<div class="teacher tw-flex tw-w-full tw-h-full tw-items-center tw-gap-2">
						<div>
							<img
								[src]="user.profile_picture ? user.profile_picture : ('./assets/Avatar Default.svg' | resolveAsset)"
								class="user-profile-icon"
								alt="user image" />
						</div>
						<div class="tw-text-navy-500 tw-text-sm tw-font-medium tw-flex-grow">{{ user.display_name }}</div>
						<div class="already-in-class tw-justify-self-end tw-text-xs tw-text-gray-500" *ngIf="!(user | userInArray : newSelectedTeachers)">
							<img [src]="'./assets/checkmark.svg' | resolveAsset" alt="checkmark" /> Already in this class
						</div>
						<div class="already-in-class tw-justify-self-end tw-text-xs tw-text-gray-500" *ngIf="user | userInArray : newSelectedTeachers">
							<img [src]="'./assets/checkmark.svg' | resolveAsset" alt="checkmark" /> Already selected
						</div>
					</div>
				</mat-list-item>
			</div>
		</mat-list>
	</div>
</form>
