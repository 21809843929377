<div *ngIf="student" class="student-item">
	<div class="student-tag">
		<span *ngIf="ordered" class="order-indicator">
			{{ index + 1 | position }}
		</span>
		<div
			*ngIf="student.profile_picture; else d"
			[ngStyle]="{ background: 'url(' + student.profile_picture + ') no-repeat left center/cover' }"
			class="profile-picture"></div>
		<ng-template #d>
			<img class="profile-picture" src="../../../../../assets/Avatar Default.svg" alt="Avatar" />
		</ng-template>
		<div
			(mouseenter)="isHovered = true"
			(mouseleave)="isHovered = false; isPressed = false"
			(mousedown)="isPressed = true; homePageService.goToStudentPage(student.id)"
			(mouseup)="isPressed = false"
			[style.cursor]="isHovered ? 'pointer' : ''">
			<span class="name">{{ student.display_name }}</span>
		</div>
		<img
			*ngIf="groups?.length > 0 && !kioskMode"
			customToolTip
			[contentTemplate]="tooltip"
			[toolTipHideDelay]="300"
			class="tw-animate-fadein-300 tw-w-5 tw-h-5 tw-ml-2"
			src="../../../../../assets/Encounter Prevention Stop Sign.svg"
			alt="Encounter Prevention" />
	</div>
	<div class="student-item-right-side" data-cy="sp-student-list-item-right">
		<ng-content select="[sp-student-list-item-right]"></ng-content>
	</div>
</div>
<ng-template #tooltip>
	<app-encounter-prevention-tooltip [user]="user" [groups]="groups"></app-encounter-prevention-tooltip>
</ng-template>
