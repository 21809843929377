<div class="display-options" #displayOptionsElement>
	<div class="option-list">
		<div class="option" [mat-dialog-close]="'kiosk'">
			<div><img src="assets/icon-kiosk-mode.svg" alt="kiosk mode icon" /></div>
			<div>Kiosk Mode</div>
		</div>
		<div class="option" [mat-dialog-close]="'room_code'">
			<div><img src="assets/icon-room-code.svg" alt="room code icon" /></div>
			<div>Room Code</div>
		</div>
	</div>
	<div class="line-break"></div>
	<div class="show-toggle">
		Show Ended Passes
		<app-toggle-input
			style="width: auto"
			controlSize="smaller"
			[form]="toggleForm"
			[controlName]="'toggle'"
			[delimiter]="false"
			(pushOutValue)="onShowEndedPassesToggle($event)"></app-toggle-input>
	</div>
	<div *ngIf="showSwitchRooms && data.roomOptions && data.roomOptions.length > 1" class="line-break"></div>
	<div *ngIf="showSwitchRooms && data.roomOptions && data.roomOptions.length > 1">
		<div
			class="switch-rooms"
			appCrossPointerEventTarget
			(mouseover)="optionsHovered ? null : setOptionsHoveredTrue(true)"
			(mouseleave)="setOptionsHoveredFalse(false)"
			cdkOverlayOrigin
			#overlayTrigger="cdkOverlayOrigin"
			#target>
			<div>Switch Room</div>
			<img src="assets/Arrow Right.svg" alt="room code icon" />
		</div>
		<ng-template
			cdkConnectedOverlay
			[cdkConnectedOverlayOrigin]="overlayTrigger"
			[cdkConnectedOverlayOpen]="dropDownHovered || optionsHovered"
			[cdkConnectedOverlayPositions]="roomOptionsPosition"
			#dropdown>
			<app-dropdown
				[heading]="'CHANGE ROOM'"
				[locations]="data.roomOptions"
				[selectedLocation]="data.selectedLocation"
				[@TooltipFadeInOut]="'visible'"
				(selectedLocationEmit)="emitSelectedLocation($event)"
				(mouseleave)="setDropDownHoveredFalse(false)"
				(mouseover)="dropDownHovered ? null : setDropDownHoveredTrue(true)">
			</app-dropdown>
		</ng-template>
	</div>
</div>
