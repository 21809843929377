import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DialogFactoryService } from '../dialog-factory.service';
import { Term } from '../models/Schedule';
import { DynamicDialogService } from '../dynamic-dialog.service';
import { DYNAMIC_DIALOG_OPTIONS, DynamicDialogAction, DynamicDialogData } from '../dynamic-dialog-modal/dynamic-dialog-modal.component';
import { filter } from 'rxjs/operators';
import { ScheduleService } from '../services/schedule.service';

export type ModalState = 'open' | 'closed';
@Component({
	selector: 'sp-shorten-or-delete-terms',
	templateUrl: './shorten-or-delete-terms.component.html',
	styleUrls: ['./shorten-or-delete-terms.component.scss'],
})
export class ShortenOrDeleteTermsComponent implements OnChanges {
	@ViewChild('modalTemplate', { read: TemplateRef, static: false }) modalTemplate: TemplateRef<any>;
	@Input() modalState: ModalState = 'closed';
	@Input() termBeingShortened: Term;
	@Input() termsBeingDeleted: Term[] = [];
	@Input() newStartDate: string;
	@Input() newEndDate: string;
	@Input() forStartDate: boolean;
	@Input() forEndDate: boolean;
	@Output()
	result: EventEmitter<DynamicDialogAction> = new EventEmitter<DynamicDialogAction>();

	startDate: string;
	endDate: string;
	private shortenYearDatesDialogService: DynamicDialogService;
	private modalHeaderText: string;

	constructor(private dialogFactoryService: DialogFactoryService, private scheduleService: ScheduleService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.modalState?.currentValue === 'open') {
			if (this.termBeingShortened && this.termsBeingDeleted.length > 0) {
				this.modalHeaderText = `Remove & Shorten Terms?`;
			} else if (this.termBeingShortened) {
				this.modalHeaderText = `Shorten ${this.termBeingShortened.term_name}?`;
			} else if (this.termsBeingDeleted.length > 0) {
				this.modalHeaderText = `Delete ${this.termsBeingDeleted[0].term_name}?`;
			}

			this.getStartAndEndDates();

			const data: DynamicDialogData = {
				headerText: this.modalHeaderText,
				showCloseIcon: true,
				primaryButtonLabel: 'Confirm',
				secondaryButtonLabel: 'Cancel',
				modalBody: this.modalTemplate,
				secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
				secondaryButtonTextColor: '#7083A0',
				disablePrimaryButton: false,
			};
			this.shortenYearDatesDialogService = this.dialogFactoryService.open(data, DYNAMIC_DIALOG_OPTIONS);
			this.shortenYearDatesDialogService.closed$.pipe(filter(Boolean)).subscribe((selectedOption: DynamicDialogAction) => {
				this.scheduleService.displayShortenOrDeleteTerms$.next('closed');
				this.result.emit(selectedOption);
			});
		}
		if (changes.modalState?.currentValue === 'closed') {
			this.shortenYearDatesDialogService?.close('secondary');
			// this.result.emit('secondary');
		}
	}

	private getStartAndEndDates(): void {
		if (this.termBeingShortened) {
			this.startDate = this.forStartDate ? this.newStartDate : this.termBeingShortened.start_date;
			this.endDate = this.forStartDate ? this.termBeingShortened.end_date : this.newEndDate;
		} else if (this.termsBeingDeleted.length > 0) {
			const lastTerm = this.termsBeingDeleted[this.termsBeingDeleted.length - 1];
			this.startDate = lastTerm.start_date;
			this.endDate = lastTerm.end_date;
		}
	}
}
