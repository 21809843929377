import { Component, Directive, Input, OnInit } from '@angular/core';
import { User } from '../models/User';
import { EncounterPreventionService, ExclusionGroupWithOverrides } from 'app/services/encounter-prevention.service';
import { FeatureFlagService, FLAGS } from 'app/services/feature-flag.service';
import { HomepageService } from 'app/services/homepage.service';

@Directive({
	selector: '[sp-student-list-item-right]',
})
export class StudentListItemRightContent {}

@Component({
	selector: 'sp-student-list-item',
	templateUrl: './student-list-item.component.html',
	styleUrls: ['./student-list-item.component.scss'],
})
export class StudentListItemComponent implements OnInit {
	@Input() student: User;
	@Input() user: User;
	@Input() ordered: boolean;
	@Input() index: number;
	kioskMode: boolean;

	groups: ExclusionGroupWithOverrides[] = [];

	constructor(
		private ePService: EncounterPreventionService,
		private featureFlagService: FeatureFlagService,
		public homePageService: HomepageService
	) {}

	ngOnInit(): void {
		this.kioskMode = this.homePageService.isKioskMode;
		const epGroups$ = this.featureFlagService.isFeatureEnabledV2(FLAGS.EncounterPreventionOverride)
			? this.ePService.getExclusionGroupsWithOverrides(this.student.id)
			: this.ePService.getExclusionGroups({ student: this.student.id });
		epGroups$.subscribe((groups) => (this.groups = groups.filter((g) => g.enabled)));
	}
}
