/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

export type ToolTipDataType = {
	text: string;
	link: string;
	linkText: string;
	dataDetails: SafeHtml;
};

@Component({
	selector: 'sp-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
	@Input() text: string;
	@Input() link: string;
	@Input() linkText: string;
	@Input() contentTemplate: TemplateRef<any>; // use this if you need to style the content of the tooltip
	constructor() {}

	ngOnInit(): void {}
}
