import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeRangeInputComponent } from './time-range-input/time-range-input.component';
import { TimeInputComponent } from './time-input/time-input.component';
import { CoreModule } from '../../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [TimeRangeInputComponent, TimeInputComponent],
	exports: [TimeRangeInputComponent],
	imports: [CommonModule, CoreModule, FormsModule, ReactiveFormsModule],
})
export class TimeRangeInputModule {}
