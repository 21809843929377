import { ElementRef, Injectable } from '@angular/core';
export interface ElementProperties {
	height: number;
	width: number;
	offsetTop: number;
	boundingClientRect: DOMRect;
}

@Injectable({
	providedIn: 'root',
})
export class DynamicStylingService {
	private elements = new Map<string, ElementProperties>();

	registerElement(key: string, ref: ElementRef): void {
		const properties: ElementProperties = {
			height: ref.nativeElement.offsetHeight,
			width: ref.nativeElement.offsetWidth,
			offsetTop: ref.nativeElement.offsetTop,
			boundingClientRect: ref.nativeElement.getBoundingClientRect(),
		};
		this.elements.set(key, properties);
	}

	getElementProperties(key: string): ElementProperties | undefined {
		return this.elements.get(key);
	}

	listElementRefs(): string[] {
		return Array.from(this.elements.keys());
	}

	getWindowInnerHeight(): number {
		return window.innerHeight;
	}
}
