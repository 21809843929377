import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WaitingInLinePass } from 'app/models';

export type PassLimitOverride = {
	roomPassLimit: number;
	studentCount: number;
	currentCount: number;
	isStaff: boolean;
	isKiosk: boolean;
	isWaitInLine?: boolean;
	customBodyHtml?: string;
	wilp?: WaitingInLinePass;
};

export type PassLimitOverrideResponse = {
	cancelPassCreationDialog: boolean;
	override: boolean;
};

@Component({
	selector: 'admin-pass-limits-dialog',
	templateUrl: './pass-limit-dialog.component.html',
	styleUrls: ['./pass-limit-dialog.component.scss'],
})
export class PassLimitDialogComponent {
	header: string;
	mainText: string;
	isStudent: boolean;
	cancelButtonResponse: Partial<PassLimitOverrideResponse> = {};
	confirmButtonResponse: Partial<PassLimitOverrideResponse> = {};

	// PassLimitDialogComponent is intended to always return Partial<PassLimitOverrideResponse> when
	// the dialog is closed. The return value is never intended to be null or undefined.
	constructor(@Inject(MAT_DIALOG_DATA) public data: PassLimitOverride) {
		if (!data) {
			return;
		}

		const { roomPassLimit, studentCount, currentCount, isStaff, isKiosk, isWaitInLine } = this.data;

		if (isWaitInLine) {
			this.header = 'Override Max Capacity?';
			this.mainText = `
        ${currentCount}/${roomPassLimit} students have passes to this room. If it is an emergency, you can
        override the max capacity and start this pass.
      `;
			this.confirmButtonResponse = {
				override: true,
			};
			if (this.data.customBodyHtml) {
				this.mainText = this.data.customBodyHtml;
			}
			return;
		}

		this.isStudent = !isStaff || isKiosk;
		if (!this.isStudent) {
			if (studentCount === 1) {
				this.header = `Limit reached: ${currentCount}/${roomPassLimit} students have passes to this room`;
				this.mainText = 'If this is an emergency, you can override the limit.';
			} else {
				this.header = `Creating these ${studentCount} passes will exceed the room's ${roomPassLimit}-pass limit`;
				this.mainText = 'Are you sure you want to override the limit?';
			}
			this.confirmButtonResponse = {
				override: true,
			};
		} else {
			this.header = 'Room Limit Reached';
			this.mainText = 'Please wait for a spot to open.';
			this.cancelButtonResponse = {
				cancelPassCreationDialog: true,
			};
		}
	}
}
