<ng-container *ngIf="classesToAccept?.length">
	<div class="pager-wrapper tw-rounded-xl">
		<div class="page-content" *ngIf="currentClass | async as class">
			<div class="tw-rounded-xl">
				<div class="tw-border tw-rounded-xl tw-border-gray-300 tw-border-solid card-setup-wrapper">
					<div
						class="tw-flex tw-items-center tw-pl-8 tw-py-7 tw-bg-gray-50 tw-border-b tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-border-gray-300 tw-border-solid">
						<div class="tw-pr-5">
							<img class="tw-w-13" [src]="'./assets/chalkboard.svg' | resolveAsset" alt="Chalkboard icon" />
						</div>
						<div class="tw-text-left tw-pr-5">
							<p class="tw-text-xl tw-font-bold tw-text-navy-500 tw-my-1">{{ class.synced_name }}</p>
							<p class="tw-text-base tw-font-normal tw-text-gray-500 tw-my-1">
								{{ class.class_users.students | andOthers : 'user.display_name' : 3 }}
								<span class="tw-underline" customToolTip [contentTemplate]="moreDetailsTooltip">More Details</span>
							</p>
							<ng-template #moreDetailsTooltip>
								<sp-class-sync-info-tooltip [syncData]="currentSyncInfo$ | async"></sp-class-sync-info-tooltip>
							</ng-template>
						</div>
					</div>
					<div class="tw-px-8 tw-pt-6 tw-pb-7 tw-bg-white tw-rounded-b-xl">
						<div style="min-height: 121.23px">
							<sp-class-edit-room-form
								[user]="user"
								[editRoomForm]="acceptClassRoomForm"
								[disabled]="!teacherCanEdit"
								[initialRoom]="class.room"
								[showRoomList]="false">
							</sp-class-edit-room-form>
						</div>
						<div class="tw-flex tw-align-center tw-justify-between tw-pt-8">
							<app-gradient-button
								[textColor]="'#7083A0'"
								leftIcon="./assets/Close.svg"
								[fontSize]="'16px'"
								[fontWeight]="'normal'"
								[leftImageHeight]="'12px'"
								leftImageWidth="12px"
								[width]="'201px'"
								[minHeight]="'50px'"
								[gradient]="'#F0F2F5, #F0F2F5'"
								[size]="'editable'"
								[cursor]="'pointer'"
								[disabled]="!teacherCanEdit"
								customToolTip
								[contentTemplate]="!teacherCanEdit ? onlyAdminEditTooltip : declineClassTooltip"
								(buttonClick)="declineClass(class)"
								>Decline
							</app-gradient-button>
							<app-gradient-button
								[disabled]="!teacherCanEdit"
								[textColor]="'#FFFFFF'"
								leftIcon="./assets/Checkmark (White).svg"
								[fontSize]="'16px'"
								[fontWeight]="'normal'"
								[leftImageHeight]="'16px'"
								[leftImageWidth]="'16px'"
								[width]="'201px'"
								[minHeight]="'50px'"
								[gradient]="'#00B476, #00B476'"
								[size]="'editable'"
								[cursor]="'pointer'"
								customToolTip
								[showToolTip]="!teacherCanEdit || disableAcceptButton"
								[contentTemplate]="disableAcceptButton ? selectRoomFirst : onlyAdminEditTooltip"
								[customShadow]="'0px 2px 10px 0px #10141833'"
								(buttonClick)="acceptClass(class)"
								>Accept
							</app-gradient-button>
							<ng-template #onlyAdminEditTooltip>
								<div class="ds-tooltip" style="max-width: 321px; text-align: left; padding: 12px">
									<p class="tw-w-max tw-my-0 tw-bg-purple-100 tw-py-1 tw-px-1.5 tw-text-purple-500 tw-text-xs tw-font-bold tw-uppercase tw-rounded">
										Admin Only
									</p>
									<p class="tw-text-sm tw-font-medium tw-mb-0 tw-mt-1.5">Only admins can edit classes.</p>
								</div>
							</ng-template>
							<ng-template #selectRoomFirst>
								<div class="ds-tooltip">
									<p class="tw-m-0">Select a room to accept this class</p>
								</div>
							</ng-template>
							<ng-template #declineClassTooltip>
								<div class="ds-tooltip" style="max-width: 174px; padding: 12px">
									<p class="tw-text-sm tw-font-medium tw-mb-0 tw-mt-1.5">
										You can undo this by going to
										<span [routerLink]="['/admin/classes']" [queryParams]="{ filter: 'archived' }" class="tw-underline tw-cursor-pointer"
											>Archived Classes.</span
										>
									</p>
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="tw-flex tw-justify-center">
		<img
			class="tw-cursor-pointer"
			[ngClass]="{ 'tw-cursor-default tw-opacity-50': currentPage == 1 }"
			(click)="previousClass()"
			[src]="'./assets/arrow-left (Gray).svg' | resolveAsset"
			alt="" />
		<p class="tw-px-4 tw-text-gray-500 tw-text-base tw-font-normal">{{ currentPage }} of {{ classesToAccept.length }}</p>
		<img
			class="tw-cursor-pointer"
			[ngClass]="{ 'tw-cursor-default tw-opacity-50': currentPage == classesToAccept.length }"
			(click)="nextClass()"
			[src]="'./assets/arrow-right (Gray).svg' | resolveAsset"
			alt="" />
	</div>
</ng-container>

<ng-template #acceptClassCardModalBody>
	<div>
		<sp-class-term-and-period-form
			[schedules]="schedules"
			[listGroups]="listGroups"
			[initialSelectedTermIds]="[]"
			[initialSelectedPeriodGroupingIds]="[]"
			(selectedPeriodGroupingIds)="didSelectPeriodGroupingIds($event)"
			(selectedTermIds)="didSelectTermIds($event)">
		</sp-class-term-and-period-form>
	</div>
</ng-template>
