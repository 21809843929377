import { Component, Input } from '@angular/core';
import { PassLimit, Location } from '../../models';
import { LocationsService } from '../../services/locations.service';

const greyHandIcon = './assets/Hand (Blue-Gray).svg';
const whiteHandIcon = './assets/Hand (White).svg';

@Component({
	selector: 'sp-pass-counter-chip',
	templateUrl: './pass-counter-chip.component.html',
	styleUrls: ['./pass-counter-chip.component.scss'],
})
export class PassCounterChipComponent {
	@Input() location: Location;

	@Input() set passLimit(pl: PassLimit) {
		if (!this.location || !pl) {
			return;
		}

		this.countFrom = pl.from_count;
		this.countFromLimit = this.location.max_passes_from;
		this.roomFull = pl.from_count >= this.location.max_passes_from;
		this.icon = this.roomFull ? whiteHandIcon : greyHandIcon;
		this.tooltipDescription = this.locationService.tooltipDescription('none', pl, 'fromRoom');
	}

	countFrom: number;
	countFromLimit: number;
	roomFull: boolean;
	icon: string;
	tooltipDescription: string;

	constructor(private locationService: LocationsService) {}
}
