import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { RenameComponent } from '../rename-class/rename.component';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import {
	ClassesService,
	ClassStatus,
	ClassUserType,
	CreateSPClassReq,
	GetExternalInfoResp,
	SPClassUser,
	SPClassWithUsers,
} from '../services/classes.service';
import { DynamicDialogService } from '../dynamic-dialog.service';
import { Location, Pinnable, School, User } from '../models';
import { FormGroup } from '@angular/forms';
import { filter, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ClassDetailsModalData } from '../services/classes.service';
import { DialogFactoryService } from '../dialog-factory.service';
import { HttpService } from '../services/http-service';
import { UserService } from '../services/user.service';
import { ToastService } from '../services/toast.service';
import { ScheduleService } from '../services/schedule.service';
import { cloneDeep, isEqual } from 'lodash';
import { DYNAMIC_DIALOG_OPTIONS, DynamicDialogAction, DynamicDialogData } from '../dynamic-dialog-modal/dynamic-dialog-modal.component';
import { EditType } from './about-class/about-class.component';
import { Toast } from '../models/Toast';

@Component({
	selector: 'sp-class-details',
	templateUrl: './class-details.component.html',
	styleUrls: ['./class-details.component.scss'],
})
export class ClassDetailsComponent implements OnInit {
	@ViewChild('renameClassModalBody', { read: TemplateRef, static: false }) renameClassModalBody: TemplateRef<any>;
	@ViewChild('editRoomModalBody', { read: TemplateRef, static: false }) editRoomModalBody: TemplateRef<any>;
	@ViewChild('editClassDateTimeModalBody', {
		read: TemplateRef,
		static: false,
	})
	editClassDateTimeModalBody: TemplateRef<any>;
	@ViewChild('addTeachersModalBody', { read: TemplateRef, static: false }) addTeachersModalBody: TemplateRef<any>;
	@ViewChild('archiveClassModalBody', { read: TemplateRef, static: false }) archiveClassModalBody: TemplateRef<any>;
	@ViewChild('renameClassComponent', { static: false }) renameClassComponent: RenameComponent;
	@ViewChild('archivedClassTemplate', { read: TemplateRef, static: false }) archivedClassTemplate: TemplateRef<any>;

	@ViewChild('editClassSubHeaderModalBody', { read: TemplateRef, static: false }) editClassSubHeaderModalBody: TemplateRef<any>;

	@Input() data: ClassDetailsModalData;
	@Output() closeModal = new EventEmitter<void>();

	classId: number;
	currentClass: ReplaySubject<SPClassWithUsers> = new ReplaySubject<SPClassWithUsers>();

	periodName: string;
	teachers: SPClassUser[];
	hasSchedulesFF = false;
	private archiveClassDialogService: DynamicDialogService;
	private destroy$: Subject<void> = new Subject<void>();

	students: SPClassUser[] = [];
	selectedTabIndex = 0;
	initiateAddStudent = false;
	autoSetFocusOnSearch = true;
	dialogSecondaryBtnLbl = 'Cancel';

	school: School;
	user: User;
	userCanEdit = false;

	// rename class variables
	private renameClassService: DynamicDialogService;
	renameClassForm = new FormGroup({});

	// edit room variables
	editRoomForm = new FormGroup({});
	private editRoomDialogService: DynamicDialogService;
	folders: Pinnable[];
	room: Location;

	// edit dates and times variables
	private classDetailsDialogService: DynamicDialogService;
	private selectedPeriodGroupingIds: number[];
	private initiallySelectedPeriodGroupingIds: number[];
	private selectedTermIds: number[];
	private initiallySelectedTermIds: number[];
	currentSyncInfo$: Observable<GetExternalInfoResp> = this.currentClass.pipe(
		switchMap((c) => this.classesService.getExternalInfo(c.id)),
		shareReplay()
	);

	// edit teachers variables
	editTeachersForm = new FormGroup({});
	private editTeachersDialogService: DynamicDialogService;
	private teacherUserIds: number[];
	syncData$: Observable<GetExternalInfoResp>;

	constructor(
		private dialogFactoryService: DialogFactoryService,
		private classesService: ClassesService,
		private http: HttpService,
		private userService: UserService,
		private toast: ToastService,
		private cdr: ChangeDetectorRef,
		public scheduleService: ScheduleService
	) {}

	ngOnInit(): void {
		this.teachers = cloneDeep(this.data.classDetails?.class_users?.teachers);

		this.userService.effectiveUser$
			.pipe(
				takeUntil(this.destroy$),
				filter((u) => !!u),
				tap((u) => {
					this.school = this.http.getSchool();
					this.user = User.fromJSON(u);
					this.userCanEdit = this.user.isAdmin() || this.school.teachers_can_edit_classes;
				})
			)
			.subscribe();
		this.updateFieldsFromClassDetails();
		this.currentClass.next(this.data.classDetails);

		this.selectedTabIndex = this.data.selectedTabIndex ?? 0;
		this.initiateAddStudent = this.data.initiateAddStudent ?? false;
		this.autoSetFocusOnSearch = this.data.autoSetFocusOnSearch ?? true;
		this.dialogSecondaryBtnLbl = this.data.dialogSecondaryBtnLbl ?? 'Cancel';

		this.syncData$ = this.classesService.getExternalInfo(this.classId);

		// begin edit class name functionality  ************************ //
		this.renameClassForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
			if (res && res.className !== this.data.classDetails.display_name) {
				this.renameClassService.setDialogConfig({ disablePrimaryButton: false });
			}
		});
		// end edit class name functionality  ************************ //

		// begin edit room functionality  ************************ //

		// listening to edit room form changes
		this.editRoomForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
			if (res && res.roomId && res.roomId !== this.data.classDetails.room.id) {
				this.editRoomDialogService.setDialogConfig({ disablePrimaryButton: false });
			}
		});
		// end edit room functionality  ************************ //

		// begin edit teachers functionality  ************************ //
		this.editTeachersForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe();
		// end edit teachers functionality  ************************ //

		this.classesService
			.getTermsAndPeriodGroupings(this.classId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((resp) => {
				this.selectedTermIds = resp.terms?.map((t) => t.id) || [];
				this.initiallySelectedTermIds = cloneDeep(this.selectedTermIds);
				this.selectedPeriodGroupingIds = resp.period_groupings?.map((pg) => pg.id) || [];
				this.initiallySelectedPeriodGroupingIds = cloneDeep(this.selectedPeriodGroupingIds);
			});
	}

	private updateFieldsFromClassDetails(): void {
		this.room = this.data.classDetails?.room;
		this.classId = this.data.classDetails?.id;
		this.periodName = this.data.classDetails?.period_name;
		this.teachers = cloneDeep(this.data.classDetails?.class_users?.teachers);
		this.students = this.data.classDetails?.class_users?.students;
	}

	// begin methods used for edit teachers  ************************ //
	openAddTeachersModal(): void {
		const data: DynamicDialogData = {
			headerText: 'Edit Teachers',
			showCloseIcon: true,
			primaryButtonLabel: 'Save',
			secondaryButtonLabel: 'Cancel',
			disablePrimaryButton: true,
			modalBody: this.addTeachersModalBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
		};
		this.editTeachersDialogService = this.dialogFactoryService.open(data, DYNAMIC_DIALOG_OPTIONS);
		this.editTeachersDialogService.closed$.pipe(filter(Boolean)).subscribe({
			next: (selectedOption: DynamicDialogAction) => {
				// do something based on which button was clicked
				if (selectedOption === 'primary') {
					this.updateClassUsers(this.teacherUserIds, 'teacher');
				} else if (selectedOption === 'secondary') {
					this.teacherUserIds = [];
					this.teachers = cloneDeep(this.data.classDetails.class_users.teachers);
					this.cdr.detectChanges();
				}
			},
		});
	}

	// end methods used for edit teachers  ************************ //

	// begin methods used for edit room  ************************ //
	// filters rooms based on room title and room number

	openEditRoomModal(): void {
		const data: DynamicDialogData = {
			headerText: 'Edit Room',
			showCloseIcon: true,
			primaryButtonLabel: 'Save',
			secondaryButtonLabel: 'Cancel',
			modalBody: this.editRoomModalBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			disablePrimaryButton: true,
		};
		this.editRoomDialogService = this.dialogFactoryService.open(data, { ...DYNAMIC_DIALOG_OPTIONS, autoFocus: false });
		this.editRoomDialogService.closed$.pipe(filter(Boolean)).subscribe({
			next: (selectedOption: DynamicDialogAction) => {
				if (selectedOption === 'secondary') {
					this.editRoomForm = new FormGroup({});
				} else if (selectedOption === 'primary') {
					const data: CreateSPClassReq = {
						id: this.data.classDetails.id,
						display_name: this.data.classDetails.display_name,
						room_id: this.editRoomForm.get('roomId').value,
						school_id: this.school.id,
						status: this.data.classDetails.status,
					};
					this.updateClass(data);
				}
			},
		});
	}

	handleRoomChange(hasChanges: boolean) {
		this.editRoomDialogService.setDialogConfig({ disablePrimaryButton: !hasChanges });
	}

	// end methods used for edit room  ************************ //

	// begin methods used for rename class  ************************ //
	openRenameClassModal(): void {
		const data: DynamicDialogData = {
			headerText: 'Rename This Class',
			showCloseIcon: true,
			primaryButtonLabel: 'Save',
			secondaryButtonLabel: 'Cancel',
			modalBody: this.renameClassModalBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			disablePrimaryButton: true,
		};
		this.renameClassService = this.dialogFactoryService.open(data, DYNAMIC_DIALOG_OPTIONS);
		this.renameClassService.closed$.pipe(filter(Boolean)).subscribe({
			next: (selectedOption: DynamicDialogAction) => {
				if (selectedOption === 'secondary') {
					this.renameClassForm.setValue({});
				} else if (selectedOption === 'primary') {
					const data: CreateSPClassReq = {
						id: this.data.classDetails.id,
						display_name: this.renameClassForm.get('name').value,
						room_id: this.data.classDetails.room.id,
						school_id: this.school.id,
						status: this.data.classDetails.status,
					};
					this.updateClass(data);
				}
			},
		});
	}

	// end methods used for rename class  ************************ //

	// begin methods used for edit dates and times  ************************ //
	openEditClassesDateTimeModal(): void {
		const data: DynamicDialogData = {
			headerText: 'Edit Dates & Periods',
			subHeaderTemplate: this.editClassSubHeaderModalBody,
			showCloseIcon: true,
			primaryButtonLabel: 'Save',
			secondaryButtonLabel: 'Cancel',
			modalBody: this.editClassDateTimeModalBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			disablePrimaryButton: false,
		};
		this.classDetailsDialogService = this.dialogFactoryService.open(data, {
			panelClass: ['dynamic-dialog-modal', 'fit-content-width'],
			disableClose: false,
		});
		this.classDetailsDialogService.closed$.pipe(filter(Boolean)).subscribe({
			next: (selectedOption: DynamicDialogAction) => {
				if (selectedOption === 'primary') {
					this.classesService
						.updateTermsAndPeriodGroupings(this.classId, this.selectedTermIds, this.selectedPeriodGroupingIds)
						.pipe(switchMap(() => this.classesService.getClassById(this.classId)))
						.subscribe((updatedClass) => {
							this.initiallySelectedTermIds = cloneDeep(this.selectedTermIds);
							this.initiallySelectedPeriodGroupingIds = cloneDeep(this.selectedPeriodGroupingIds);
							this.data = {
								...this.data,
								classDetails: updatedClass,
							};
							this.updateFieldsFromClassDetails();
							this.classesService.allClassesDataSource$.next([updatedClass]);
						});
				} else {
					this.selectedTermIds = [];
					this.selectedPeriodGroupingIds = [];
				}
			},
		});
	}

	// these methods are used by this component  ************************ //
	editDetails(editType: EditType): void {
		switch (editType) {
			case 'class_name':
				this.openRenameClassModal();
				break;
			case 'date_time':
				this.openEditClassesDateTimeModal();
				break;
			case 'room_name':
				this.openEditRoomModal();
				break;
			case 'teachers':
				this.openAddTeachersModal();
				break;
		}
	}

	archiveClass(): void {
		if (this.data.classDetails.status === 'archived') {
			this.updateClassArchivedStatus('active');
			return;
		}

		const data: DynamicDialogData = {
			headerText: 'Archive This Class?',
			showCloseIcon: true,
			primaryButtonLabel: 'Archive Class',
			secondaryButtonLabel: 'Cancel',
			modalBody: this.archiveClassModalBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			primaryButtonGradientBackground: '#E32C66,#E32C66',
			primaryButtonTextColor: '#FFFFFF',
		};
		this.archiveClassDialogService = this.dialogFactoryService.open(data, DYNAMIC_DIALOG_OPTIONS);
		this.archiveClassDialogService.closed$.pipe(filter(Boolean)).subscribe({
			next: (selectedOption: DynamicDialogAction) => {
				if (selectedOption === 'primary') {
					this.updateClassArchivedStatus('archived');
				}
			},
		});
	}

	private updateClassArchivedStatus(status: ClassStatus): void {
		const data: CreateSPClassReq = {
			id: this.data.classDetails.id,
			display_name: this.data.classDetails.display_name,
			room_id: this.data.classDetails.room?.id,
			school_id: this.school.id,
			status: status,
		};
		if (status === 'archived') {
			const toastData: Toast = {
				title: 'Class Archived',
				type: 'error',
				templateRef: this.archivedClassTemplate,
			};
			this.updateClass(data, toastData);
			return;
		}
		this.updateClass(data);
	}

	private updateClass(data: CreateSPClassReq, toastData?: Toast): void {
		this.classesService
			.createOrUpdateClass(data)
			.pipe(takeUntil(this.destroy$))
			.subscribe((res) => {
				this.closeModal.emit();
				this.data = {
					...this.data,
					classDetails: res,
				};
				this.updateFieldsFromClassDetails();
				this.classesService.allClassesDataSource$.next([res]);
				if (toastData) {
					this.toast.openToast({
						title: toastData.title,
						type: toastData.type,
						templateRef: toastData.templateRef,
					});
				}
			});
	}

	editTeachers(event: User[]): void {
		this.editTeachersDialogService.setDialogConfig({ disablePrimaryButton: false });
		this.teacherUserIds = event.map((u) => u.id);
	}

	updateClassUsers(userIds: number[], type: ClassUserType): void {
		this.classesService
			.updateClassUsers(userIds, this.classId, type)
			.pipe(takeUntil(this.destroy$))
			.subscribe((res) => {
				const c = this.data.classDetails;
				if (type === 'student') {
					this.students = res || [];
					c.class_users.students = this.students;
				}
				if (type === 'teacher') {
					this.teachers = res || [];
					c.class_users.teachers = this.teachers;
				}
				this.data = {
					...this.data,
					classDetails: c,
				};

				this.updateFieldsFromClassDetails();
				this.classesService.allClassesDataSource$.next([this.data.classDetails]);
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	didSelectPeriodGroupingIds(selectedPeriodGroupingIds: number[]): void {
		this.selectedPeriodGroupingIds = selectedPeriodGroupingIds;
		this.updatePrimaryButtonState();
	}

	didSelectTermIds(selectedTermIds: number[]): void {
		this.selectedTermIds = selectedTermIds;
		this.updatePrimaryButtonState();
	}
	updatePrimaryButtonState(): void {
		const sortedInitialPGIDS = this.initiallySelectedPeriodGroupingIds?.slice().sort((a, b) => a - b);
		const sortedPGIDS = this.selectedPeriodGroupingIds?.slice().sort((a, b) => a - b);
		const sortedInitialTIDS = this.initiallySelectedTermIds?.slice().sort((a, b) => a - b);
		const sortedTIDS = this.selectedTermIds?.slice().sort((a, b) => a - b);

		const arePGIDSEqual = isEqual(sortedInitialPGIDS, sortedPGIDS);
		const areTIDSEqual = isEqual(sortedInitialTIDS, sortedTIDS);

		const disabled = sortedPGIDS?.length === 0 || sortedTIDS?.length === 0 || (arePGIDSEqual && areTIDSEqual);

		const d = { disablePrimaryButton: disabled };
		this.classDetailsDialogService.setDialogConfig(d);
	}
}
